/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react'
import { Modal, Tabs } from 'flowbite-react'
import { TbReportAnalytics } from 'react-icons/tb'
import { PiBinoculars } from 'react-icons/pi'

import { IconMapper } from 'utilities/IconMapper'
import { transceiver } from 'global'
import { commands } from 'commands'
import OverviewTab from './OverviewTab'
import ReportTab from './ReportTab'
import { FlowbiteTabTheme } from 'styles/FlowbiteTheme/FlowbiteTabTheme'
import { selectedItem } from '../ArchiveAppraisal'

interface props {
    open: boolean
    onClose: () => void
    selectedItem: selectedItem
}

const DetailsModal: React.FC<props> = ({ open, onClose, selectedItem }) => {
    const [appraisal, setAppraisal] = useState<any>()
    const { id, status } = selectedItem

    useEffect(() => {
        if (id!==undefined && id.length > 0) {
            let obj = {
                AppraisalId: id
            }
            transceiver.requestCommand(commands.SimwaveAppraisalLoad, obj, (msg:any)=>{setAppraisal(msg)})
        }
    }, [id])

    const tabsArr = [
        {
            icon: () => <PiBinoculars className='w-4 h-4 aspect-square mr-2' />,
            title: 'Overview',
            content: <OverviewTab appraisal={appraisal} status={status} />
        },
        {
            icon: () => <TbReportAnalytics className='w-4 h-4 aspect-square mr-2' />,
            title: 'Report',
            content: <ReportTab id={id} />
        }
    ]
    
    return (
        <Modal show={open} onClose={onClose} size={"7xl"}>
            <Modal.Header>
                <div className='flex items-center'>
                    {IconMapper(appraisal?.icon ?? '', 'w-6 h-6')}
                    <h4 className='font-bold'>{appraisal?.Title}</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Tabs.Group theme={FlowbiteTabTheme}>
                        {
                            tabsArr.map((tab, i) => (
                                <Tabs.Item key={`details-modal-${i}`} title={tab.title} icon={tab.icon} >
                                    {tab.content}
                                </Tabs.Item>
                            ))
                        }
                    </Tabs.Group>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DetailsModal