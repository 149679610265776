import React from 'react'
import { Avatar, Badge, Progress } from 'flowbite-react'
import { MdInfo, MdLockOutline, MdLockOpen } from 'react-icons/md'
import { useNavigate } from 'react-router'
import moment from 'moment';

import { TaskType } from '../../common/models/TaskType';
import { generateBadgeColor, generateProggressColor } from '../../utilities';
import { HoverableAvatar } from '../../components/common';

interface props {
    item: TaskType
    infoModalHandler: () => void
}

const TaskListItem = ({ item, infoModalHandler }: props) => {
    const navigate = useNavigate()

    const handleInfoModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        infoModalHandler()
    }

    return (
        <li
            onClick={() => { (item.ExpiredFlag || item.LockFlag) ? navigate(``) : navigate(`/task-viewer/${item.OperationId}/${item.StepName}`) }}
            className={`px-2 h-[100px] font-bold transition-all duration-200 rounded py-1 flex items-center hover:${(item.ExpiredFlag || item.LockFlag) ? "cursor-not-allowed" : "bg-gray-100 cursor-pointer"}`}
        >
            <div className="flex items-center space-x-4 flex-1">
                <div>
                    {(item.ExpiredFlag || item.LockFlag)
                        ?
                        <MdLockOutline className="text-red-700 fill-red-500" size={24} />
                        :
                        <MdLockOpen className="text-simwave-brand fill-green-500" size={24} />
                    }
                </div>
                <div className="min-w-0 flex-1">
                    <div className="font-normal text-black/60 text-sm">{item.CDT}</div>
                    {(item.LockFlag)
                        ?
                        <p className=" text-sm text-gray-900 dark:text-white">
                            {item.OperationTitle ?? item.Title} - Locked
                        </p>
                        :
                        <p className=" text-sm text-gray-900 dark:text-white">
                            {item.OperationTitle ?? item.Title}
                        </p>
                    }
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                        {item?.OperationDescription?.substring(0, 75)}{item?.OperationDescription?.length > 75 && '...'}
                    </p>
                </div>
                <div className='flex items-center gap-x-2'>
                </div>

                <Avatar.Group>
                    {item?.Interactors?.map((item) => <HoverableAvatar item={item} key={item.Id} />)}
                </Avatar.Group>
                {(item.Interactors !== undefined && item.Interactors !== null)
                    ?
                    <span className="text-black/30 mb-1">|</span>
                    :
                    null
                }
                {
                    item.Deadline &&
                    (
                        <div className='text-sm flex'>
                            {(item.ExpiredFlag) ?
                                <div className="text-gray-900 dark:text-white w-[50px] flex flex-col items-center justify-center px-2">
                                    <p className="mb-1 text-[14px] text-red-500">Expired</p>
                                </div>
                                :
                                <div className="text-gray-900 dark:text-white w-[90px] flex flex-col items-center justify-center px-2">
                                    <p className="text-[12px] text-simwave-brand">Deadline:</p>
                                    <p className="text-[12px]">{moment(item.Deadline).format('ll')}</p>
                                    <p className="text-[12px]">{moment(item.Deadline).format('LT')}</p>
                                </div>
                            }
                        </div>
                    )
                }
                {(item.Deadline !== undefined && item.Deadline !== null)
                    ?
                    <span className="text-black/30 mb-1">|</span>
                    :
                    null
                }
                <div className="text-gray-900 dark:text-white w-[120px] flex justify-center px-2 flex-col">
                    <Badge color="" className='py-1 w-full text-center flex flex-col items-center justify-center' style={{ backgroundColor: generateBadgeColor(item.Progress) }}>
                        <p className="mb-1 text-[10px]">Progress: {item.Progress}%</p>
                        <Progress
                            size={"sm"}
                            progress={item.Progress}
                            color={generateProggressColor(item.Progress)}
                        />
                    </Badge>
                    <Badge color="info" className='mt-1 text-center px-2 flex justify-center'>
                        {item.Title}
                    </Badge>
                </div>
                <button type='button' onClick={handleInfoModal}>
                    <MdInfo className='fill-choice-info scale-125' />
                </button>
            </div>
        </li >
    )
}

export default TaskListItem