import { useRef, useState } from 'react';
import { Card } from 'flowbite-react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { ConfirmModal } from 'components/common';
import Button from 'components/button/Button';
import { HideLoader, ShowLoader } from 'utilities/utils';
import { commands } from 'commands';
import { transceiver } from 'global';
import Grid from 'features/data-display/Grid';

export type TableType = {
  questions?: string,
  [key: string]: any | any[]
}


interface AppraisalGridProps {
  AppraisalId: string,
  IsArchive: boolean
}


const AppraisalGrid: React.FC<AppraisalGridProps> = (props: any) => {
  const AppraisalId = props?.Data?.PortfolioId || props.AppraisalId;
  const isArchive = props.IsArchive || false;
  const navigate = useNavigate();
  let { operationId } = useParams();

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [buttonsDisable, setButtonsDisable] = useState<boolean>(true);

  const [commnetPrefix, setCommnetPrefix] = useState<string>()

  const textRef = useRef<any>();

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'bg-orange-500 text-white hover:bg-orange-700 focus:ring-orange-300 active:bg-orange-800 disabled:bg-orange-300 shadow-sm rounded-md p-2 px-10 ml-3',
      cancelButton: 'bg-white !text-indigo-900 rounded-md !border-indigo-900 p-2 px-10 border hover:bg-gray-100 mr-3'
    },
    buttonsStyling: false
  })

  const submitPrespective = (obj: any) => {
    swalWithBootstrapButtons.fire({
      text: "Are you sure to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        obj['AppraisalId'] = AppraisalId
        transceiver.requestCommand(commands.SimwaveAppraisalAddFeedback, obj, (result: any, state: any) => { setinstanceData() })
      }
    })
  }

  const setinstanceData = () => {
    ShowLoader();
    let obj = {
      ContainerId: operationId,
      StepName: props.StepFullName,
      Name: "Next"
    };
    transceiver.requestCommand(commands.Transit, obj, (result: any) => { transitResult(result) }, null);
  }

  const transitResult = (msg: any) => {
    HideLoader();
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Your appraisal has been completed',
      showConfirmButton: false,
      timer: 2500
    })
    navigate("/task-list");
  }

  const setComment = (item: any) => {
    setButtonsDisable(false);
    setCommnetPrefix(item);
  }

  const textareaChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value !== "") {
      setButtonsDisable(false);
    } else {
      setButtonsDisable(true);
    }
  }

  return (
    <Card className="bg-white/60 mt-4 block !justify-start">
      <div className="h-full flex flex-col justify-between">
        <div>
          <div className="container px-0 py-5 mx-auto flex flex-wrap">
            <div className="flex flex-wrap -m-4">
              <Grid AppraisalId={AppraisalId} />
            </div>
          </div>
          {
            isArchive ? null : <>
              <div>
                <input onClick={(e) => setComment(e.currentTarget.value)} type="radio" name="haveComment" id="no" value="I am fine with the report" />
                <label className='ml-2' htmlFor="no">I am fine with the report</label>
              </div>
              <div>
                <input onClick={(e) => setComment(e.currentTarget.value)} type="radio" name="haveComment" id="yes" value="I have a comment to add" />
                <label className='ml-2' htmlFor="yes">I have a comment to add</label>
              </div>
              <textarea
                id='comment-text'
                onChange={(e) => textareaChangeHandler(e)}
                rows={7}
                ref={textRef}
                placeholder={"Comment ..."}
                className='mx-auto w-full rounded-lg my-2 outline-none'
              />
              <div className='flex justify-between mt-10'>
                <Button
                  onClick={() => submitPrespective?.({
                    Rejected: true,
                    Comment: `${commnetPrefix} - ${textRef.current.value}`
                  })}
                  className='rounded-lg px-2'
                  variant='white'
                  disabled={buttonsDisable}
                >
                  Reject
                </Button>
                <Button
                  onClick={() => submitPrespective?.({
                    Approved: true,
                    Comment: `${commnetPrefix} -, ${textRef.current.value}`
                  })}
                  disabled={buttonsDisable}
                  className='rounded-lg px-2'
                  variant='primary'
                >
                  Approve
                </Button>
              </div>
            </>
          }
        </div>
        <ConfirmModal
          withTextBox
          open={openConfirmModal}
          placeHolder='Comment ...'
          header='Submit Perspective'
          textMsg='Do you hold a similar perspective?'
          onClose={() => setOpenConfirmModal(false)}
          onApproved={submitPrespective}
          onReject={submitPrespective}
        />
      </div>
    </Card>
  );
};

export default AppraisalGrid;
