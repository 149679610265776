
interface ExtractTextprops {
    fSymbol: string,
    secSymbol: string
}

export const extractText = (text: string, options: ExtractTextprops) => {
    const { fSymbol, secSymbol } = options

    const splittedTxt = text.split(fSymbol) ?? ''

    const itemName = splittedTxt[0] ?? ''
    const textInsideParentheses = splittedTxt[1]?.split(secSymbol)[0] ?? ''

    return {
        itemName, textInsideParentheses
    }

}