import React from 'react'
import { OptionProps } from 'react-select'
import { BiSolidChevronRight } from 'react-icons/bi'

import { extractText } from 'utilities'

const CusMenuItem: React.FC<OptionProps<any>> = ({ data, innerProps, isSelected, options }) => {

    const isLast = data.value === options[options.length - 1].value
    const { itemName, textInsideParentheses } = extractText(data.label, { fSymbol: '(', secSymbol: ')' })

    return (
        <div>
            <div {...innerProps} className='menu-items my-0 flex items-center justify-between'>
                <div className='flex items-center gap-x-1'>
                    <BiSolidChevronRight className={`scale-150 stroke-1 stroke-slate-500 ${isSelected ? "fill-slate-500" : "fill-none"}`} />
                    <p>{itemName}</p>
                </div>
                <span className='text-slate-500'>{textInsideParentheses}</span>
            </div>
            {!isLast && <hr />}
        </div>
    )
}

export default CusMenuItem