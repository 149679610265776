import { configureStore } from '@reduxjs/toolkit';

import breadcrumbReducer from 'components/breadcrumb/state/BreadcrumbSlice';
import TaskStepsSlice from 'components/task-steps/state/TaskStepsSlice';
import authReducer from 'features/auth/authSlice';
import { apiSlice } from 'api/apiSlice';
import orgReducer from 'components/sideMenu/SetBinModal/orgSlice'
import toastReducer from 'features/toast/toastSlice';
import sideMenuReducer from 'components/sideMenu/SetBinModal/sideMenuSlice'

export const store = configureStore({
  reducer: {
    breadcrumb: breadcrumbReducer,
    auth: authReducer,
    taskStep: TaskStepsSlice,
    toast: toastReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    organization: orgReducer,
    sideMenu: sideMenuReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware,
    ),
  devTools: true,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
