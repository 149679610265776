import { Card, Label, Textarea } from 'flowbite-react';
import {
  MdOutlineAssessment,
  MdOutlineDescription,
  MdOutlineQuestionAnswer,
} from 'react-icons/md';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select, { GroupBase, StylesConfig } from 'react-select';
import DatePicker from 'react-datepicker';
import {
  PiCalendarPlusDuotone,
  PiSubtitles,
  PiUserFill,
  PiUserListFill,
  PiUserPlus,
} from 'react-icons/pi';
import { FaUsersSlash } from 'react-icons/fa';

import { HideLoader, ShowLoader } from 'utilities/utils';
import { SelectInputModel } from 'common/models/SelectInputModel';
import { VmProccessViewerSteps } from 'common/models/TaskStepModel';
import { setSteps } from 'components/task-steps/state/TaskStepsSlice';
import { SwitchComponent } from 'components/task-steps/SwitchComponent';
import { Questioner } from 'components/questioner/Questioner';
import {
  CustomMenuUserItem,
  CustomMultiValue,
} from 'components/select';
import CusCalendarContainer from 'components/DatePicker/CusCalendarContainer';
import { commands } from 'commands';
import { transceiver } from 'global';
import { breadcrumb } from './breadcrumb';
import { SET_BREADCRUMB } from 'utilities/SET_BREADCRUMB';
import Button from 'components/button/Button';
import { QuestionnaireModel } from 'common/models/QuestionnaireModel';
import { EmptyList, InputWithLabel } from 'components/common';
import { Template } from 'components/template/Template';
import { IconMapper } from 'utilities/IconMapper';
import Swal from 'sweetalert2';

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

export interface VmQuestionModel {
  Questionnaires: QuestionnaireModel[];
}

export interface VmTitleInput {
  value: string;
  label: string;
}

export interface VMAppraiserQuestionnaires {
  Id: string;
}

export const CreateAppraisal = (props: any) => {
  SET_BREADCRUMB(breadcrumb);

  const dispatch = useDispatch();

  let { operationId } = useParams();

  const setDefaultDeadline = (): any => {
    var today = new Date();
    return today.setMonth(today.getMonth() + 1);
  };

  const [availableTitles, setAvailableTitles] = useState<VmTitleInput[]>([]);
  const [employees, setEmployees] = useState<SelectInputModel[]>([]);
  const [mangers, setMangers] = useState<SelectInputModel[]>([]);
  const [questionnaires, setQuestionnaires] = useState<VmQuestionModel>();
  const [dateError, setDateError] = useState<string | null>(null);
  const [appraiserQuestionnaires, setAppraiserQuestionnaires] = useState<
    VMAppraiserQuestionnaires[]
  >([]);
  let [leadEvaluator, setLeadEvaluator] = useState<SelectInputModel[]>([]);

  const [deadline, setDeadline] = useState(
    props?.dedline ?? setDefaultDeadline(),
  );
  const [title, setTitle] = useState(props?.title ?? '');
  const [desc, setDesc] = useState(props?.desc ?? '');
  const [template, setTemplate] = useState<any>();
  let [appraiseeId, setAppraiseeId] = useState<SelectInputModel>();
  const [selectedEvaluators, setSelectedEvaluators] = useState<
    SelectInputModel[]
  >([]);

  useEffect(() => {
    const loadEmployees = () => {
      let obj = {
        To: '-1',
      };
      transceiver.requestCommand(commands.SimwaveListEmployees, obj, (result: any) => {
        Listemployees(result);
      });
    };
    const Listemployees = (msg: any) => {
      let employeeList: SelectInputModel[] = [];
      msg.Items.forEach((item: any) => {
        employeeList.push({
          value: item.Id,
          label: item.Caption,
          ProfilePhoto: item.ProfilePhoto,
          Postion: item.Positions,
        });
      });

      setEmployees(employeeList);
      loadTemplateId();
    };

    ShowLoader();
    LoadTitleInstance();
    loadEmployees();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transceiver, operationId]);

  const loadTemplateId = () => {
    let obj = {
      InstanceId: operationId,
      ParameterName: 'Template',
    };
    transceiver.requestCommand(commands.LoadInstanceParameter,
      obj,
      (result: any) => {
        templateResult(result);
      },
    );
  };

  const LoadRequestTemplate = (employeeId: any) => {

    ShowLoader();
    let obj = {
      EmployeeId: employeeId,
      TemplateId: template.Id,
    };
    ;
    transceiver.requestCommand(
      commands.SimwaveAppraisalLoadRequestTemplate,
      obj,
      (result: any) => {
        loadRequestTemplateResult(result);
      },
    );
  };

  const loadAppraisalInfo = () => {
    let obj = {
      InstanceId: operationId,
      ParameterName: 'AppraisalInfo',
    };
    transceiver.requestCommand(commands.LoadInstanceParameter, obj, (result: any) => {
      appraisalInfo(result);
    });
  };

  const appraisalInfo = (msg: any) => {
    if (msg) {
      let appraisee = {
        ProfilePhoto: msg.Appraisee.ProfilePhoto,
        label: msg.Appraisee.label,
        value: msg.Appraisee.value,
        Postion: msg.Appraisee.Postion,
      };
      setAppraiseeId(appraisee);
      let selectedEvaluatorsList: SelectInputModel[] = [];
      msg.Managers.forEach((item: any) => {
        selectedEvaluatorsList.push({
          value: item.value,
          label: item.label,
          ProfilePhoto: item.ProfilePhoto,
          Postion: item.Postion,
        });
      });

      setLeadEvaluator([
        {
          value: msg.leadEvaluator.value,
          label: msg.leadEvaluator.label,
          ProfilePhoto: msg.leadEvaluator.ProfilePhoto,
          Postion: '',
        },
      ]);

      setSelectedEvaluators(selectedEvaluatorsList);
      loadQuestionnaire();
    } else HideLoader();
  };

  const loadQuestionnaire = () => {
    let obj = {
      InstanceId: operationId,
      ParameterName: 'Questionnaire',
    };
    transceiver.requestCommand(commands.LoadInstanceParameter, obj, (result: any) => {
      loadQuestionnaireResult(result);
    });
  };

  const loadQuestionnaireResult = (msg: any) => {
    HideLoader();
    if (msg) {
      let ListQuestionnaire: VmQuestionModel = { Questionnaires: [] };
      msg.Questionnaires.forEach((item: any) => {
        ListQuestionnaire.Questionnaires.push({
          Id: item.Id,
          Caption: item.Caption,
          Description: item.Description,
        });
      });
      setQuestionnaires(ListQuestionnaire);
    }
  };

  const LoadTitleInstance = () => {
    let obj = {
      ParameterName: 'Title',
      InstanceId: operationId,
    };
    transceiver.requestCommand(commands.LoadInstanceParameter, obj, (result: any) => {
      LoadTitleInstanceResult(result);
    });
  };

  const LoadTitleInstanceResult = (msg: any) => {
    if (msg) {
      let obj = {
        value: msg,
        label: msg,
      };
      setTitle(obj);
    }
    LoadDescriptionInstance();
  };

  const LoadDescriptionInstance = () => {
    let obj = {
      ParameterName: 'Description',
      InstanceId: operationId,
    };
    transceiver.requestCommand(commands.LoadInstanceParameter, obj, (result: any) => {
      LoadDescriptionInstanceResult(result);
    });
  };

  const LoadDescriptionInstanceResult = (msg: any) => {
    if (msg) {
      setDesc(msg);
    }
    loadAppraisalInfo();
  };

  const loadRequestTemplateResult = (msg: any) => {
    let evaluatorsList: SelectInputModel[] = [];
    msg.AvailableEvaluators.forEach((item: any) => {
      evaluatorsList.push({
        value: item.Id,
        label: `${item.Caption}(${item.Positions[0]})`,
        ProfilePhoto: item.ProfilePhoto,
        Postion: item.Positions,
      });
    });

    let appraiserQuestionnaires: VMAppraiserQuestionnaires[] = [];
    if (msg.AppraiserQuestionnaires) {
      msg.AppraiserQuestionnaires.forEach((item: any) => {
        appraiserQuestionnaires.push({
          Id: item.Id,
        });
      });
    }
    setAppraiserQuestionnaires(appraiserQuestionnaires);

    let selectedEvaluatorsList: SelectInputModel[] = [];
    msg.Evaluators.forEach((item: any) => {
      selectedEvaluatorsList.push({
        value: item.Id,
        label: `${item.Caption}(${item.Positions[0]})`,
        ProfilePhoto: item.ProfilePhoto,
        Postion: item.Positions,
      });
    });

    setSelectedEvaluators(selectedEvaluatorsList);

    let ListQuestionnaire: VmQuestionModel = { Questionnaires: [] };
    msg.Questionnaires.forEach((item: any) => {
      ListQuestionnaire.Questionnaires.push({
        Id: item.Id,
        Caption: item.Caption,
        Description: item.Description,
      });
    });
    setQuestionnaires(ListQuestionnaire);

    if (msg.LeadEvaluator)
      setLeadEvaluator([{
        value: msg.LeadEvaluator.Id,
        label: `${msg.LeadEvaluator.Caption}(${msg.LeadEvaluator.Positions[0]})`,
        ProfilePhoto: msg.LeadEvaluator.ProfilePhoto,
        Postion: msg.Positions

      }])


    let titleList: VmTitleInput[] = [];
    msg.Titles.forEach((item: any) => {
      titleList.push({
        value: item,
        label: item,
      })
    });
    setAvailableTitles(titleList);
    setDesc(msg.TemplateData.Description);
    setMangers(evaluatorsList)
    HideLoader();
  }

  const updateInstance = () => {

    if (!deadline) {
      setDateError("field is required");
      return;
    }
    ShowLoader();
    let obj = {
      InstanceId: operationId,
      Title: title.value,
      Description: desc,
    };
    transceiver.requestCommand(commands.UpdateInstanceData, obj, (result: any) => {
      updateInstanceResult();
    });
  };

  const setTitleForInstance = () => {
    let obj = {
      InstanceId: operationId,
      ParameterName: 'Title',
      Value: title.value,
      PersistenceFlag: true,
    };
    transceiver.requestCommand(commands.SetInstanceParameter, obj, () => {
      setTitleForInstanceResult();
    });
  };

  const setAppraiserQuestionnairesInstance = () => {
    let obj = {
      InstanceId: operationId,
      ParameterName: 'AppraiserQuestionnairesIds',
      Value: appraiserQuestionnaires.map((i: any) => i.Id),
      PersistenceFlag: true,
    };
    transceiver.requestCommand(commands.SetInstanceParameter, obj, () => {
      appraiserQuestionnairesInstanceResult();
    });
  };

  const appraiserQuestionnairesInstanceResult = () => {
    let obj = {
      ContainerId: operationId,
      StepName: props.StepFullName,
      Name: 'Next',
    };
    transceiver.requestCommand(
      commands.Transit,
      obj,
      (result: any) => {
        transitResult(result);
      },
      null,
    );
  };

  const setTitleForInstanceResult = () => {
    let obj = {
      InstanceId: operationId,
      ParameterName: 'Description',
      Value: desc,
      PersistenceFlag: true,
    };
    transceiver.requestCommand(commands.SetInstanceParameter, obj, () => {
      setInstanceDeadline();
    });
  };

  const setInstanceDeadline = () => {
    let obj = {
      InstanceId: operationId,
      ParameterName: 'DueDate',
      Value: new Date(deadline).toLocaleString(),
      PersistenceFlag: true,
    };
    transceiver.requestCommand(commands.SetInstanceParameter, obj, () => {
      setIstanceData();
    });
  };

  const updateInstanceResult = () => {
    setTitleForInstance();
  };

  const setIstanceData = () => {
    var apr = {
      Appraisee: appraiseeId,
      Managers: selectedEvaluators,
      Description: desc,
      Deadline: deadline,
      Title: title.value,
      leadEvaluator: leadEvaluator[0],
    };
    let obj = {
      InstanceId: operationId,
      ParameterName: 'AppraisalInfo',
      Value: apr,
      PersistenceFlag: true,
    };
    transceiver.requestCommand(commands.SetInstanceParameter, obj, () => {
      setInstanceData();
    });
  };

  const setInstanceData = () => {
    setQuestionnaireData();
  };
  const setQuestionnaireData = () => {
    let obj = {
      InstanceId: operationId,
      ParameterName: 'Questionnaire',
      Value: questionnaires,
      PersistenceFlag: true,
    };
    transceiver.requestCommand(
      commands.SetInstanceParameter,
      obj,
      (result: any, state: any) => {
        setinstanceData();
      },
    );
  };


  const setinstanceData = () => {
    setAppraiserQuestionnairesInstance();
  };

  const transitResult = (msg: any) => {
    let ListStep: VmProccessViewerSteps = { steps: [] };
    msg.Map.Steps.forEach((item: any) => {
      ListStep.steps.push({
        Name: item.Name,
        Index: item.Index,
        Title: item.Title,
        CurrentFlag: item.CurrentFlag,
      });
    });
    dispatch(setSteps(ListStep));
    window.history.pushState(null, '', msg.Step.FullName);
    props.setBody(
      <SwitchComponent
        setBody={props.setBody}
        {...msg?.Data}
        type={msg?.Data.StepName}
      />,
    );
    HideLoader();
  };

  const transitBack = () => {
    let obj = {
      ContainerId: operationId,
      StepName: props.StepFullName,
      Name: 'Back',
    };
    transceiver.requestCommand(
      commands.Transit,
      obj,
      (result: any) => {
        transitBackResult(result);
      },
      null,
    );
  };

  const transitBackResult = (msg: any) => {
    let ListStep: VmProccessViewerSteps = { steps: [] };
    msg.Map.Steps.forEach((item: any) => {
      ListStep.steps.push({
        Name: item.Name,
        Index: item.Index,
        Title: item.Title,
        CurrentFlag: item.CurrentFlag,
      });
    });
    dispatch(setSteps(ListStep));
    window.history.pushState(null, '', msg.Step.FullName);
    props.setBody(
      <SwitchComponent
        setBody={props.setBody}
        {...msg?.Data}
        type={msg?.Data.StepName}
      />,
    );
    HideLoader();
  };

  const validationNext = (): boolean => {
    if (!leadEvaluator) return true;
    if (!title) return true;

    else return false;
  };

  const templateResult = (msg: any) => {
    setTemplate(msg);
    LoadTitleInstance();
  };
  const handleAppraiseeId = (value: any) => {
    setAppraiseeId(value);
    LoadRequestTemplate(value.value);
  };

  const handleManagerId = (value: any) => {
    let items = value.filter((f: any) => f.value === leadEvaluator[0].value);
    if (items.length === 0) {
      setSelectedEvaluators(value);
    }
    else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'An employee can not have two evaluation positions in one appraisal process',
        showConfirmButton: false,
        timer: 1500
      })
    }
  };

  const CustomeStyles:
    | StylesConfig<SelectInputModel, false, GroupBase<SelectInputModel>>
    | undefined = {
    control: (styles: any) => ({
      ...styles,
      padding: '1.5px',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      borderRadius: '0.5rem',
      backgroundColor: 'rgb(249 250 251 / 1)',
      borderColor: '#00416f',
      outline: 'none',
      outlineWidth: '0px',
    }),
    valueContainer: (base: any) => ({
      ...base,
      overflow: 'visible',
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 10,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: '#00416f',
    }),
  };

  return (
    <Card className="bg-white/60 mt-4 min-h-[calc(100vh-180px)] !justify-start block">
      <div className="flex flex-col justify-between h-full">
        <div className="h-full">
          <div className="h-3/4">
            <div className="h-full">
              <div className='sm:-mt-6'>
                <div className='flex flex-col sm:flex-row justify-between sm:items-center'>
                  <h5 className="appraisal-title">
                    <MdOutlineAssessment className="scale-150" />
                    <p>Create Appraisal</p>
                  </h5>
                  <div>
                    <Template
                      className='md:w-fit p-0'
                      selected
                      icon={
                        IconMapper(template?.Icon, "ml-2", 16)
                      }
                      summary={template?.Description}
                      title={template?.Caption}
                      size='small'
                    />
                  </div>
                </div>
                <hr />
              </div>
              <div className="h-full flex flex-wrap justify-stretch gap-4 pt-2">
                <InputWithLabel
                  wrapperClassName="flex-grow "
                  iconNode={<PiUserPlus className="scale-125" />}
                  inputNode={
                    <Select
                      options={employees}
                      value={appraiseeId}
                      onChange={handleAppraiseeId}
                      components={{
                        MultiValue: CustomMultiValue,
                        Option: CustomMenuUserItem,
                      }}
                      styles={{
                        control: (base: any) => ({
                          ...base,
                          padding: '1.5px',
                          fontSize: '0.875rem',
                          lineHeight: '1.25rem',
                          borderRadius: '0.5rem',
                          backgroundColor: 'rgb(249 250 251 / 1)',
                          borderColor: '#00416f',
                        }),
                        menu: (base: any) => ({
                          ...base,
                          zIndex: 10,
                        }),
                        dropdownIndicator: (base: any) => ({
                          ...base,
                          color: '#00416f',
                        }),
                      }}
                    />
                  }
                  labelNode={
                    <Label htmlFor="txtAppraisee" value="Appraisee *" />
                  }
                />
                <InputWithLabel
                  wrapperClassName="basis-3/5"
                  iconNode={<PiSubtitles className="scale-125" />}
                  inputNode={
                    <Select
                      options={availableTitles}
                      value={title}
                      onChange={setTitle}
                      noOptionsMessage={() => (
                        <div className="py-4">
                          <EmptyList
                            text="Please Select an Appraisee first"
                            icon={<PiUserPlus className="scale-125" />}
                          />
                        </div>
                      )}
                    />
                  }
                  labelNode={<Label htmlFor="txtTitle" value="Title" />}
                />
                <div className="basis-full flex max-sm:flex-col justify-between gap-4">
                  <div className="basis-[60%] flex gap-x-4">
                    <InputWithLabel
                      wrapperClassName="h-fit w-full"
                      iconNode={<PiUserListFill className="scale-125" />}
                      labelNode={
                        <Label htmlFor="txtManagers" value="Managers" />
                      }
                      inputNode={
                        <Select
                          options={mangers}
                          isSearchable
                          isMulti
                          onChange={handleManagerId}
                          isDisabled={!appraiseeId}
                          styles={CustomeStyles}
                          value={selectedEvaluators}
                          components={{
                            MultiValue: CustomMultiValue,
                            Option: CustomMenuUserItem,
                          }}
                        />
                      }
                    />

                    <InputWithLabel
                      wrapperClassName="h-fit w-full"
                      iconNode={<PiUserFill className="scale-125" />}
                      labelNode={
                        <Label
                          htmlFor="txtLeadEvaluator"
                          value="Lead Evaluator"
                        />
                      }
                      inputNode={
                        <Select
                          options={leadEvaluator}
                          isSearchable
                          value={leadEvaluator}
                          components={{
                            MultiValue: CustomMultiValue,
                            Option: CustomMenuUserItem,
                          }}
                          isDisabled={!appraiseeId}
                          styles={{
                            control: (base: any) => ({
                              ...base,
                              padding: '1.5px',
                              fontSize: '0.875rem',
                              lineHeight: '1.25rem',
                              borderRadius: '0.5rem',
                              backgroundColor: 'rgb(249 250 251 / 1)',
                              borderColor: '#00416f',
                            }),
                            menu: (base: any) => ({
                              ...base,
                              zIndex: 10,
                            }),
                            dropdownIndicator: (base: any) => ({
                              ...base,
                              color: '#00416f',
                            }),
                          }}
                        />
                      }
                    />
                  </div>
                  <InputWithLabel
                    wrapperClassName="h-fit grow"
                    iconNode={<PiCalendarPlusDuotone className="scale-125" />}
                    labelNode={<Label htmlFor="txtDeadline" value="Deadline" />}
                    inputNode={
                      <>
                        <DatePicker
                          selected={deadline}
                          onChange={(date) => {
                            setDateError(null);
                            setDeadline(date)
                          }}
                          wrapperClassName="w-full z-50"
                          className="bg-gray-50 border border-simwave-brand text-gray-900 text-sm rounded-lg w-full z-50"
                          calendarClassName="z-10"
                          popperClassName="z-20"
                          disabled={!appraiseeId}
                          tabIndex={10}
                          calendarContainer={CusCalendarContainer}
                          calendarStartDay={1}
                          excludeDateIntervals={[
                            { start: new Date('01-01-1970'), end: new Date() },
                          ]}
                          timeInputLabel="Time:"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          showTimeInput
                          strictParsing
                        />
                        <b style={{ color: "red" }}> {dateError}</b>
                      </>
                    }
                  />

                </div>

                <InputWithLabel
                  wrapperClassName="basis-full h-fit"
                  iconNode={<MdOutlineDescription className="scale-125" />}
                  labelNode={
                    <Label htmlFor="txtDescription" value="Description" />
                  }
                  inputNode={
                    <Textarea
                      id="txtDescription"
                      disabled={true}
                      onChange={(e) => setDesc(e.target.value)}
                      value={desc}
                      placeholder="Description"
                      required
                      className="border border-simwave-brand resize-none min-h-[200px]"
                    />
                  }
                />

                <div className="basis-full flex flex-wrap w-full min-h-[190px] border border-dashed mb-3 border-slate-600 rounded-lg px-4 py-4 shadow-md relative">
                  {Array.isArray(questionnaires?.Questionnaires) ? (
                    questionnaires?.Questionnaires?.map(
                      (questionnaire: any, index: number) => (
                        <Questioner
                          reppleEffect
                          className="flex-wrap w-full sm:w-[100%] md:w-[33.33%] p-2"
                          selectable={true}
                          title={questionnaire.Caption}
                          summary={questionnaire.Description}
                          icon={MdOutlineQuestionAnswer}
                          key={`questionair-${index}`}
                        />
                      ),
                    )
                  ) : (
                    <EmptyList
                      text="Questionnaires was not found !"
                      subText={
                        appraiseeId ? undefined : 'Please select an appraisee'
                      }
                      icon={<FaUsersSlash className="scale-[2]" />}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <Button
            value="Cancel"
            variant="white"
            onClick={transitBack}
            className="rounded-lg"
          >
            Back
          </Button>

          <Button
            value="Create"
            disabled={validationNext()}
            variant="primary"
            onClick={updateInstance}
            className="rounded-lg"
          >
            Create
          </Button>
        </div>
      </div>
    </Card>
  );
};
