import { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Alert, Card } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { BiSelectMultiple } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { HiInformationCircle } from 'react-icons/hi';

import { Choice } from 'components/choice/Choice';
import Button from 'components/button/Button';
import { HideLoader, ShowLoader } from 'utilities/utils';
import {
  ChosenBy,
  QuestionChoice,
  QuestionModel,
} from 'common/models/QuestionModel';
import Comments from 'components/comment/Comments';
import { CommentModel } from 'common/models/Comment';
import { EvaluatorModel } from 'common/models/EvaluatorModel';
import { VmProccessViewerSteps } from 'common/models/TaskStepModel';
import { setSteps as SetTaskSteps } from 'components/task-steps/state/TaskStepsSlice';
import { SwitchComponent } from 'components/task-steps/SwitchComponent';
import { RootState } from 'app/store';
import PersonsRow from 'pages/task-viewer/appraisal/QuizViewer/PersonsRow';
import { commands } from 'commands';
import { transceiver } from 'global';
import { SET_BREADCRUMB } from 'utilities/SET_BREADCRUMB';
import { breadcrumb } from './breadcrumb';

interface QuestionStep {
  status: 'current' | 'upcoming' | 'complete';
}

const QuizViewer = (props: any) => {
  SET_BREADCRUMB(breadcrumb);

  let { operationId } = useParams();
  const dispatch = useDispatch();

  const isSideMenuOpen = useSelector((state: RootState) => state.sideMenu.open)

  const [currentStep, setCurrentStep] = useState(0);
  const [moving, setMoving] = useState('right');
  const [wrapperWidth, setWrapperWidth] = useState(1);
  const [steps, setSteps] = useState<QuestionStep[]>([]);
  const [questioners, setQuestioners] = useState<string[]>([]);
  const [commentMandatory, setCommentMandatory] = useState<boolean>(false);


  const [appraisalQuestions, setAppraisalQuestions] = useState<QuestionModel[]>(
    [],
  );
  const [evaluations, setEvaluations] = useState<EvaluatorModel[]>([]);
  // eslint-disable-next-line
  const portfolioId = props.Data.PortfolioId;

  const loadQuestionnaire = () => {
    let obj = {
      AppraisalId: portfolioId,
    };
    transceiver.requestCommand(commands.SimwaveAppraisalLoadAnswerSheets,
      obj,
      (result: any) => {
        LoadAnswerSheets(result);
      },
    );
  };

  const LoadAnswerSheets = (msg: any) => {
    let questions: QuestionModel[] = [];
    let questioners: string[] = [];
    let comments: CommentModel[] = [];
    msg.forEach((qun: any, questionIndexer: number) => {
      qun.Items.forEach((item: any, questionIndex: number) => {
        let choiceList: QuestionChoice[] = [];
        item.Choices.forEach((ch: any) => {
          let chosenList: ChosenBy[] = [];
          ch.ChosenBy.forEach((chb: ChosenBy, idx: number) => {
            chosenList.push(chb);
          });
          let choice:QuestionChoice = {
            Name: ch.Caption,
            Description: ch.Description,
            Icon: ch.Icon,
            Id: ch.Id,
            ChosenBy: chosenList,
            FollowingQuestions :ch.FollowingQuestions
          };
          choiceList.push(choice);
        });

        item.Comments.forEach((comment: any) => {
          let c: CommentModel = {
            Id: comment.Id,
            DateTime: comment.WrittenOn,
            Text: comment.Text,
            Positions: comment.Caption,
            QuestionId: item.Id,
            QuestionnaireId: qun.Id,
            Username: comment.Writer.Caption,
            ProfileImage: comment.Writer.ProfilePhoto,
          };
          comments.push(c);
        });
        let obj: QuestionModel = {
          Index: questionIndexer,
          Id: item.Id,
          Choices: choiceList,
          Description: item.Description,
          Icon: item.Icon,
          Caption: item.Caption,
          Name: item.Name,
          DiverseFlag: item.DiverseFlag,
          Commntabel: choiceList.find((f: any) => f.ChosenBy?.length > 0)
            ? true
            : false,
          Comments: comments,
          QuestionType: 0,
          Questioner: {
            CurrentEmployeeId: qun.CurrentEmployeeId,
            Id: qun.Id,
            Caption: qun?.Caption || '',
          },
          VisibleFlag:item.VisibleFlag
        };
        questions.push(obj);
      });
      questioners.push(qun?.Caption || '');
    });
    setAppraisalQuestions(questions);
    setQuestioners(questioners);
    readQuestions(questions);
    LoadAppraisal();
  };

  const LoadAppraisal = () => {
    let obj = {
      AppraisalId: portfolioId,
    };
    transceiver.requestCommand(
      commands.SimwaveAppraisalLoad,
      obj,
      (result: any, state: any) => {
        loadAppraisalResult(result);
      },
    );
  };

  const loadAppraisalResult = (msg: any) => {
    let evaluatorList: EvaluatorModel[] = [];
    msg.Evaluations?.forEach((item: any) => {
      let obj: EvaluatorModel = {
        Caption: item.Evaluator.Caption,
        Positions: item.Evaluator.Positions,
        Description: item.Evaluator.Description,
        ProfilePhoto: item.Evaluator.ProfilePhoto,
        Id: item.Evaluator.Id,
      };
      evaluatorList.push(obj);
    });
    setEvaluations(evaluatorList);
    HideLoader();
  };

  const wrapper = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const commentRef = useRef<any>();

  const handleResize = () => {
    if (wrapper.current !== null) {
      setWrapperWidth((wrapper.current as HTMLDivElement).offsetWidth);
    }
  }

  const TransitNext = () => {
    ShowLoader();
    let obj = {
      ContainerId: operationId,
      StepName: props.StepFullName,
      Name: 'Next',
    };
    transceiver.requestCommand(commands.Transit, obj, (result: any) => {
      transitResult(result);
    });
  };
  const transitResult = (msg: any) => {
    let ListStep: VmProccessViewerSteps = { steps: [] };
    msg.Map.Steps.forEach((item: any) => {
      ListStep.steps.push({
        Name: item.Name,
        Index: item.Index,
        Title: item.Title,
        CurrentFlag: item.CurrentFlag,
      });
    });
    dispatch(SetTaskSteps(ListStep));
    window.history.pushState(null, '', msg.Step.FullName);
    props.setBody(
      <SwitchComponent
        setBody={props.setBody}
        {...msg.Data}
        type={msg.Data.StepName}
      />,
    );
    HideLoader();
  };

  function readQuestions(questions: QuestionModel[]) {
    let tmpSteps: Array<QuestionStep> = [];
    questions.forEach((question, idx) => {
      if(question.VisibleFlag)
      tmpSteps.push({
        status:
          idx === currentStep
            ? 'current'
            : idx < currentStep
              ? 'complete'
              : 'upcoming',
      });
      return false;
    });
    setSteps(tmpSteps);
  }

  useEffect(() => {
    ShowLoader();
    handleResize();
    loadQuestionnaire();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // eslint-disable-line

  const isFinalStep = (): boolean => {
    return steps[steps.length - 1]?.status === 'current' ? true : false;
  };

  const goToQuestion = (selectedIndex: number) => {
    setMoving(currentStep > selectedIndex ? 'left' : 'right');
    setSteps((old) =>
      old.map((v, i) => {
        if (i < selectedIndex) v.status = 'complete';
        else if (i === selectedIndex) {
          v.status = 'current';
        } else if (i > selectedIndex - 1) {
          v.status = 'upcoming';
        }
        return v;
      }),
    );
    setCurrentStep(selectedIndex);
  }
  const prevStep = () => {
    if (steps.findIndex((x) => x.status === 'current') !== 0) {
      setMoving('left');
      setSteps((old) =>
        old.map((v, i) => {
          if (i === currentStep) {
            v.status = 'upcoming';
          } else if (i === currentStep - 1) {
            v.status = 'current';
          }
          return v;
        }),
      );
      setCurrentStep(currentStep - 1);
      return false;
    }
  };

  const nextStep = async () => {
    if (steps.findIndex((x) => x.status === 'current') !== steps.length - 1) {
      setMoving('right');
      if (true) {
        setSteps((old) =>
          old.map((v, i) => {
            if (i === currentStep) {
              v.status = 'complete';
            } else if (i === currentStep + 1) {
              v.status = 'current';
            }
            return v;
          }),
        );
        setCurrentStep(currentStep + 1);
      }
      return false;
    }
  };

  const setComment = (text: string) => {
    let currentIndex = steps.findIndex((x) => x.status === 'current');
    let question = appraisalQuestions[currentIndex];
    let choiceId = "";
    if (question !== undefined && question !== null) {
      let currentUserId = question.Questioner.CurrentEmployeeId;
      question.Choices.forEach((choice, choiceIdx) => {
        choice.ChosenBy?.forEach((chosenBy, chosenByIdx) => {
          if (chosenBy.Id === currentUserId) {
            choiceId = choice.Id
          }
        });
      });
    }
    setAnswer(choiceId, question.Questioner.Id, question.Id, text);
    setCommentMandatory(false);
  };

  const setAnswer = (
    answer: any,
    QuestionnaireId: string | undefined,
    QuestionId: string | undefined | null,
    Note?: string | undefined | null,
  ) => {
    ShowLoader()
    let obj = {
      AppraisalId: portfolioId,
      QuestionId: QuestionId,
      QuestionnaireId: QuestionnaireId,
      Answer: answer,
      Note: Note,
    };
    transceiver.requestCommand(
      commands.SimwaveAppraisalSetAnswer,
      obj,
      (result: any, state: any) => {
        setAnswerResult(result);
      },
    );
  };

  const setAnswerResult = (msg: any) => {
    loadQuestionnaire();
  };

  const isCurrentQuestioner = (questioner: string): boolean => {
    let currentIndex = steps.findIndex((x) => x.status === 'current');
    return (
      appraisalQuestions[currentIndex].Questioner?.Caption.toLowerCase() ===
      questioner.toLowerCase()
    );
  };

  const isSelected = (ch: any, CurrentEmployeeId: string): boolean => {
    let result: boolean = false;
    if (ch.ChosenBy !== undefined && ch.ChosenBy !== null && ch !== undefined)
      result =
        ch.ChosenBy.findIndex((x: any) => x.Id === CurrentEmployeeId) >= 0;
    return result;
  };

  const toggleSelect = (qI: number, cI: number): void => {
    let q = appraisalQuestions[qI];
    let choice = q?.Choices[cI];

    let appraiseId = evaluations[0]?.Id;
    let currentUserId = q.Questioner?.CurrentEmployeeId;

    let appraiseAnswerIndex = q.Choices.findIndex(ch => ch.ChosenBy?.find(chb => chb.Id === appraiseId));
    if (appraiseAnswerIndex !== -1 && currentUserId !== appraiseId)
      setCommentMandatory(appraiseAnswerIndex !== cI);


    setAnswer(choice?.Id, q?.Questioner?.Id, q?.Id);
  };

  // const followingQuestionToggleSelect = (qI:number,cI:number):void=>{
  //   let q = appraisalQuestions[qI];
  //   let choice = q?.Choices[cI];

  //   let appraiseId = evaluations[0]?.Id;
  //   let currentUserId = q.Questioner?.CurrentEmployeeId;

  //   let appraiseAnswerIndex = q.Choices.findIndex(ch => ch.ChosenBy?.find(chb => chb.Id === appraiseId));
  //   if (appraiseAnswerIndex !== -1 && currentUserId !== appraiseId)
  //     setCommentMandatory(appraiseAnswerIndex !== cI);


  //   setAnswer(choice?.Id, q?.Questioner?.Id, q?.Id);
  // }

  const isCommentable = () => {
    let currentIndex = steps.findIndex((x) => x.status === 'current');
    let question = appraisalQuestions[currentIndex];
    let result = false;
    if (question !== undefined && question !== null) {
      let currentUserId = question.Questioner.CurrentEmployeeId;
      let selectedChoice = null;
      question.Choices.forEach((choice, choiceIdx) => {
        choice.ChosenBy?.forEach((chosenBy, chosenByIdx) => {
          if (chosenBy.Id === currentUserId) {
            selectedChoice = choice;
          }
        });
      });

      //if(question.CommentMode)
      if (selectedChoice !== null) {
        result = true;
      }
    }
    return result;
  };

  return (
    <div className="h-[calc(100vh-170px)]">
      <PersonsRow evaluations={evaluations} />
      <Card className="bg-white/40 relative top-4 bottom-90">
        <div className="grid gap-6 grid-cols-6">
          <div className="lg:col-span-4 col-span-full pr-6">
            <div className="flex flex-wrap gap-2">
              {questioners.map((questioner, index) => (
                <div
                  key={index}
                  className={`w-auto p-6 h-8 md:p-2 text-center flex text-sm items-center font-semibold text-gray-600 ${isCurrentQuestioner(questioner)
                    ? 'bg-gray-200 shadow-md'
                    : 'bg-gray-100'
                    } duration-200 transition-all rounded-md`}
                >
                  <BiSelectMultiple className="mr-1" />
                  <p className="mx-auto">{questioner}</p>
                </div>
              ))}
            </div>
            <div className="flex-1 flex flex-col justify-top p-2">
              <hr />
              <div
                className="flex items-start overflow-hidden w-96 sm:w-full mt-4"
                ref={wrapper}
              >
                <div className="flex flex-nowrap ">
                  {appraisalQuestions.map((question, questionIdx) => (question.VisibleFlag)?(
                    <Transition
                      key={`transition-question-${questionIdx}`}
                      appear={false}
                      unmount={false}
                      show={currentStep === questionIdx}
                      enter="transform transition ease-in-out duration-500"
                      enterFrom={
                        moving === 'right'
                          ? `translate-x-96 opacity-0`
                          : `-translate-x-96 opacity-0`
                      }
                      enterTo={`translate-x-0 opacity-100`}
                      leave="transform transition ease-in-out duration-500 "
                      leaveFrom={`translate-x-0 opacity-100`}
                      leaveTo={
                        moving === 'right'
                          ? `-translate-x-full opacity-0`
                          : `translate-x-full opacity-0`
                      }
                      className="w-0 bg-green-200 overflow-visible"
                      as="div"
                    >
                      <div className='pb-20' style={{ width: `${wrapperWidth-6}px` }}>
                        <h2 className="mb-5">{question.Caption}</h2>
                        {question.Choices?.map((choice, choiceIdx) => (
                          <Choice
                            selected={isSelected(
                              choice,
                              question.Questioner.CurrentEmployeeId,
                            )}
                            currentEmployeeId = {question.Questioner.CurrentEmployeeId}
                            toggleSelect={(questionIndex:number, choiceIndex:number) =>
                              toggleSelect(questionIndex,choiceIndex)
                            }
                            questionIndex={questionIdx}
                            choiceIndex={choiceIdx}
                            choice={choice}
                            answerSheet={appraisalQuestions}
                            // followingQuestionToggleSelectedHandler={
                            //   ()=>followingQuestionToggleSelect(questionIdx, choiceIdx)
                            // }
                          >
                            {choice.Name}
                          </Choice>
                        ))}
                      </div>
                    </Transition>
                  ):null)}
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-2 col-span-full">
            {commentMandatory ? (
              <Alert color="failure" icon={HiInformationCircle} className='mb-2'>
                Comment is mandatory!
              </Alert>
            ) : null}
            <Comments disabled={!isCommentable()} onSubmit={setComment} className={"mb-[80px]"}>
              {appraisalQuestions[currentStep]?.Comments.map((comment, index) => {
                if (comment.QuestionId === appraisalQuestions[currentStep].Id)
                  return <Comments.Comment {...comment} />
                else
                  return null;
              }
              )}
            </Comments>
          </div>
        </div>
      </Card>
      <div className={`fixed ${isSideMenuOpen ? "lg:w-[calc(100vw-300px)] md:w-[calc(100vw-250px)]" : "lg:w-[calc(100vw-75px)] md:w-[calc(100vw-50px)]"} bg-simwave-bg max-md:left-0 -ml-6 bottom-0 right-0`}>
        <div className={`mt-2 w-full p-4`}>
          <nav
            className="flex items-center justify-between h-[48px]"
            aria-label="Progress"
          >
            <FiChevronLeft
              onClick={() => {
                if (!commentMandatory) {
                  prevStep();
                }
              }}
              className={`h-8 w-8 transform group-hover:translate-x-[-5px] font-medium transition-transform text-white ${commentMandatory
                ? 'cursor-not-allowed text-white/20'
                : 'cursor-pointer'
                }  ${steps.findIndex((x) => x.status === 'current') === 0
                  ? 'opacity-40'
                  : ''
                }`}
            />
            <span className="text-white select-none">
              {steps.findIndex((step) => step.status === 'current') + 1} {'/'}{' '}
              {steps.length}
            </span>
            <FiChevronRight
              onClick={() => {
                if (!commentMandatory) {
                  nextStep();
                }
              }}
              size={48}
              className={`h-8 w-8 text-white transform group-hover:translate-x-[5px] transition-transform ${commentMandatory
                ? 'cursor-not-allowed text-white/20'
                : 'cursor-pointer'
                } ${isFinalStep() ? 'opacity-40' : ''}`}
            />
            <ol className="mx-8 items-center space-x-2 flex-grow hidden xl:flex">
              {steps.map((step, i) => (
                <li key={`step_${i}`} className="flex-grow max-w-[80px]">
                  {step.status === 'complete' ? (
                    <span
                      onClick={() => {
                        if (!commentMandatory) {
                          goToQuestion(i);
                        }
                      }}
                      className={`block h-1 rounded-full bg-simwave-orange/50 hover:bg-simwave-orange/80 transition-all duration-200 ${commentMandatory
                        ? 'cursor-not-allowed opacity-25'
                        : 'cursor-pointer'
                        }`}
                    >
                      <span className="sr-only"></span>
                    </span>
                  ) : step.status === 'current' ? (
                    <span
                      className="relative flex items-center justify-center"
                      aria-current="step"
                    >
                      <span
                        className="absolute h-2 w-full p-px flex"
                        aria-hidden="true"
                      >
                        <span className="w-full h-full rounded-full bg-simwave-orange" />
                      </span>
                      <span className="sr-only"></span>
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        if (!commentMandatory) {
                          goToQuestion(i);
                        }
                      }}
                      className={`block h-1 bg-gray-200 rounded-full hover:bg-gray-400 ${commentMandatory
                        ? 'cursor-not-allowed opacity-25'
                        : 'cursor-pointer'
                        }`}
                    >
                      <span className="sr-only"></span>
                    </span>
                  )}
                </li>
              ))}
            </ol>
            {isFinalStep() ? (
              <Button onClick={() => TransitNext()}>Finish and Submit</Button>
            ) : null}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default QuizViewer;
