import type { CustomFlowbiteTheme } from 'flowbite-react';

const FlowbiteTabTheme: CustomFlowbiteTheme['tab'] = {
    tablist: {
        tabitem: {
            styles: {
              default: {
                active: {
                  on: "bg-gray-100 text-simwave-brand dark:bg-gray-800 dark:text-cyan-500",
                }
              }
            },
        }
      }
  }

export { FlowbiteTabTheme }