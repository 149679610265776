import React from 'react'
import { Avatar, Badge } from 'flowbite-react'
import { AiFillTags } from 'react-icons/ai'

import { IconMapper } from 'utilities/IconMapper'
import { HoverableAvatar } from 'components/common'

interface props {
    item: any
    setItem?: Function
}

const ArchiveItem: React.FC<props> = ({ item, setItem }) => {
    return (
        <div
            onClick={() => setItem?.({ id: item.Id, status: item.Status })}
            className='even:bg-slate-100/40 hover:bg-slate-100 hover:shadow-sm py-1 px-2 border-b transition-colors duration-200 cursor-pointer select-none'
        >
            <div className='mb-2'>
                <div className='flex items-center justify-between gap-x-4'>
                    <div className='flex items-center'>
                        {IconMapper(item?.icon ?? '', 'w-4 h-4')}
                        <div>
                            <h4 className='text-sm'>{item.Title}</h4>
                            <p className='time-style'>{item.CultureCDT}</p>
                        </div>
                    </div>

                    <div className='flex items-center gap-x-4'>
                        <Avatar.Group>
                            {item?.Interactors?.Interactors?.map((interactor: any) => <HoverableAvatar item={interactor} key={interactor.Id} />)}
                        </Avatar.Group>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-between'>
                <div className='flex gap-x-2 gap-y-1 flex-wrap grow'>
                    {item?.Properties?.map?.((item: any) => item.VisibleFlag && <Badge>{item.Key} : {item.Value}</Badge>)}
                </div>
                <div className='flex gap-x-1 items-center'>
                    <AiFillTags className='fill-slate-500' />
                    <span className='text-xs text-slate-500'>{item?.TagCount ?? 0}</span>
                </div>
            </div>
        </div>
    )
}

export default ArchiveItem