import { Avatar } from 'flowbite-react'
import React from 'react'
import { OptionProps } from 'react-select'

import { extractText } from 'utilities'
import { getImageUrl } from 'global'

import defaultImg from "assets/images/default.png"

const CustomMenuUserItem: React.FC<OptionProps<any>> = ({ data, innerProps, options }) => {

    const isLast = data.value === options[options.length - 1].value

    const { itemName, textInsideParentheses } = extractText(data.label, { fSymbol: '(', secSymbol: ')' })

    return (
        <div
            {...innerProps}
            className='flex items-center gap-x-2 relative menu-items'
        >
            <Avatar img={data.ProfilePhoto === '' ? defaultImg : getImageUrl(`/stream/Image/${data.ProfilePhoto}`)} rounded bordered />
            <div className='flex flex-col justify-evenly font-medium'>
                <p>{itemName}</p>
                <span className='text-xs text-slate-500'>{textInsideParentheses}</span>
            </div>
            {!isLast && <hr className=' absolute left-16 right-0 bottom-0' />}
        </div>
    )
}

export default CustomMenuUserItem