import { Toast as FlowbiteToast } from 'flowbite-react';
import { MdWarning, MdError, MdCheckCircle } from 'react-icons/md';
import { useState, useEffect } from 'react';

const tostIconMapper = {
  success: <MdCheckCircle className="h-7 w-7 text-green-500" />,
  error: <MdError className="h-7 w-7 text-rose-500" />,
  warning: <MdWarning className="h-7 w-7 text-yellow-50" />,
};

export interface ToastProps {
  message: string;
  variant: 'success' | 'error' | 'warning';
  duration?: number;
  onClose?: () => void;
}

const Toast = ({ message, variant, duration = 3000, onClose }: ToastProps) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (duration > 0) {
      const timeout = setTimeout(() => {
        setVisible(false);
        if (onClose) onClose();
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [duration, onClose]);

  return visible ? (
    <div className="z-50 fixed top-5 right-5 shadow-md">
      <FlowbiteToast>
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg">
          {tostIconMapper[variant]}
        </div>
        <div className="ml-3 text-sm font-normal">{message}</div>
      </FlowbiteToast>
    </div>
  ) : null;
};

export default Toast;
