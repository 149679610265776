import { Alert, Modal } from 'flowbite-react'
import React, { useRef, useState } from 'react'
import { HiInformationCircle } from 'react-icons/hi'

import Button from 'components/button/Button'

interface props {
    open: boolean
    header?: string
    textMsg?: string
    withTextBox?: boolean
    placeHolder?: string
    onClose: () => void
    onReject?: (obj: any) => void
    onApproved?: (obj: any) => void
}

const ConfirmModal = ({ open, header, onApproved, onReject, textMsg, withTextBox, onClose, placeHolder }: props) => {

    const textRef = useRef<any>();

    const textareaChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.currentTarget.value !== "") {
            setButtonsDisable(false);
        } else {
            setButtonsDisable(true);
        }
    }
    const [buttonsDisable, setButtonsDisable] = useState<boolean>(true);

    return (
        <Modal show={open} onClose={onClose}>
            <Modal.Header className='modal-header-style'>
                <p className='font-bold text-white'>{header}</p>
            </Modal.Header>
            <Modal.Body>
                <Alert color="failure" icon={HiInformationCircle} className='mb-2'>
                    Comment is mandatory!
                </Alert>
                <p className='text-sm font-semibold'>{textMsg}</p>
                {withTextBox && (
                    <textarea
                        id='comment-text'
                        onChange={(e) => textareaChangeHandler(e)}
                        rows={7}
                        ref={textRef}
                        placeholder={placeHolder}
                        className='mx-auto w-full rounded-lg my-2 outline-none'
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className='w-full flex justify-end gap-x-4'>
                    <Button
                        onClick={() => onReject?.({
                            Rejected: true,
                            Comment: textRef.current.value ?? ''
                        })}
                        className='rounded-lg px-2'
                        variant='white'
                        disabled={buttonsDisable}
                    >
                        Reject
                    </Button>
                    <Button
                        onClick={() => onApproved?.({
                            Approved: true,
                            Comment: textRef.current.value ?? ''
                        })}
                        disabled={buttonsDisable}
                        className='rounded-lg px-2'
                        variant='primary'
                    >
                        Approve
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmModal