import { ConfirmAppraisal } from 'pages/task-viewer/appraisal/ConfirmAppraisal';
import { SelectTemplate } from 'pages/task-viewer/appraisal/create/SelectTemplate';
import QuizViewer from 'pages/task-viewer/appraisal/QuizViewer/QuizViewer';
import { CreateAppraisal } from 'pages/task-viewer/appraisal/create/CreateAppraisal';
import QuizPreview from 'pages/task-viewer/appraisal/QuizPreview/QuizPreview';
import { AppraisalQuiz } from 'pages/task-viewer/appraisal/AppraisalQuiz';
import AppraisalGrid from 'pages/task-viewer/appraisal/AppraisalGrid';
import ArchiveAppraisal from 'pages/archive-appraisal/ArchiveAppraisal';

export const SwitchComponent = (props: any) => {
  
  let Output;
  switch (props.type) {
    case "appraisalInfo":
      Output = <CreateAppraisal {...props} />;
      break;

    case 'ConfirmAppraisal':
      return <ConfirmAppraisal {...props} />;

    case "SelectTemplate":
      Output = <SelectTemplate {...props}/>
      break;

    case "AnswerQuestion":
      Output = <QuizViewer {...props} />
      break;

    case "ReviewAnswersAndSubmit":
      Output = <QuizPreview {...props} />
      break;

    case "QuizPreview":
      Output = <AppraisalQuiz {...props} />;
      break;

    case "OpinionView":
      Output = <AppraisalGrid {...props} />
      break;

    case "Archive":
      Output = <ArchiveAppraisal {...props} />
      break;
    default:
      return null; // to return nothing, use null
  }
  return Output;
};
