import { useLocation, Navigate, Outlet } from "react-router-dom"
import { transceiver } from "global";

const RequireAuth = () => {
    var token = transceiver.readyFlag;
    const location = useLocation()
    return (
        token
            ? <Outlet />
            : <Navigate to="/waiting" state={{ from: location }} replace />
    )
}
export default RequireAuth