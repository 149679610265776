import { Card } from 'flowbite-react'
import { PiStudent } from 'react-icons/pi'
import { LiaUserTieSolid } from 'react-icons/lia'

import { EvaluatorModel } from 'common/models/EvaluatorModel'
import OnePersonCard from 'pages/task-viewer/appraisal/QuizViewer/OnePersonCard'

interface props {
    evaluations: EvaluatorModel[]
}

function PersonsRow({ evaluations }: props) {
    const imgRenderer = (role?: string) => {
        switch (role) {
            case 'Appraisee': return <PiStudent />
            case 'Lead Evaluator': return <LiaUserTieSolid />
        }
    }
    return (
        <Card className="bg-white/40 mt-4 max-md:h-[600px] overflow-x-scroll">
            <div className="flex max-md:flex-col max-md:items-center h-full justify-between md:gap-x-6 pt-4">
                <OnePersonCard person={evaluations[0]} role={'Appraisee'} icon={imgRenderer('Appraisee')} />
                <div className='connectors-style' />
                <OnePersonCard person={evaluations[evaluations?.length - 1]} role={'Lead Evaluator'} icon={imgRenderer('Lead Evaluator')} />
            </div>
        </Card>
    )
}

export default PersonsRow