import { getImageUrl } from 'global'

import defaultImg from "assets/images/default.png"

interface props {
    name: string
    avatarImg: string
    email: string
    positions: string[]
    className: string
    title: string
}
const PersonCard = ({ name, avatarImg, email, positions, className, title }: Partial<props>) => {
    return (
        <div className='col-span-1 relative border border-simwave-brand rounded-lg shadow-sm h-full flex xl:flex-col justify-between xl:justify-center items-end xl:items-center pt-7 pb-2 xl:pb-7 px-4 overflow-x-scroll'>
            <div className='text-left bg-simwave-brand rounded-t-lg font-bold text-sm px-3 h-7 pt-[2px] text-slate-50 w-full absolute top-0 left-0 right-0'>{title}</div>

            <div className='flex flex-col py-4 xl:py-12 justify-between items-center h-full w-full'>
                <img className='xl:w-14 w-24 mx-auto aspect-square rounded-full xl:ring-4 xl:ring-offset-4 xl:ring-simwave-orange'
                    alt=''
                    src={(avatarImg === undefined || avatarImg === null || avatarImg === "") ? defaultImg : getImageUrl(`/stream/Image/${avatarImg}`)}
                />

                <div className='self-center grow xl:grow-0'>
                    <p className='xl:text-lg text-sm font-semibold'>{name}</p>

                    <p className='xl:text-base text-sm font-medium min-h-[10px]'>{positions?.map((item) => item)}</p>
                </div>
            </div>
        </div>
    )
}

export default PersonCard