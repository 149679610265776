import { ReactNode } from 'react';
import { Avatar } from 'flowbite-react';
import { QuestionChoice, QuestionModel } from 'common/models/QuestionModel';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { getImageUrl } from 'global';

interface ChoiceVM {
  className?: string;
  children?: ReactNode;
  comments?: Array<any>;
  choice?: QuestionChoice;
  toggleSelect: Function;
  selected: boolean;
  question?:QuestionModel;
  currentEmployeeId:string;
  answerSheet:QuestionModel[];
  questionIndex:number;
  choiceIndex:number;
}

export const Choice = (props: ChoiceVM) => {
  let followingQuestion:QuestionModel|null = (props.selected && (props.choice?.FollowingQuestions?.length !== undefined) && (props.choice?.FollowingQuestions?.length)>0)?props.choice?.FollowingQuestions[0]:null

  const isSelected = (ch: any, CurrentEmployeeId: string): boolean => {
    let result: boolean = false;
    if (ch.ChosenBy !== undefined && ch.ChosenBy !== null && ch !== undefined)
      result =
        ch.ChosenBy.findIndex((x: any) => x.Id === CurrentEmployeeId) >= 0;
    return result;
  };

  return (
    <div
      onClick={(e) => {props.toggleSelect(props.questionIndex,props.choiceIndex);e.stopPropagation()}}
      style={{
        color: `${props.choice?.ForeColor}`,
        border: `${props.choice?.ForeColor !== undefined ? 'solid 1px' : ''}`,
      }}
      className={`border-gray-300 border min-h-0 font-medium hover:bg-simwave-orange/20 hover:shadow cursor-pointer transition-all duration-200 items-center p-3 mb-4 text-sm text-teal-900 rounded-lg  ${props.className
        } ${props.selected ? '!bg-simwave-orange !text-white' : ''}`}
    >
      <div className="pl-3 flex items-center justify-between w-full">
        <div className="flex items-center flex-grow">
          <div className="pr-2">
            {!props.selected ? (
              <MdCheckBoxOutlineBlank size={24} className="!text-choice-gray" />
            ) : (
              <MdCheckBox className="!text-white" size={24} />
            )}
          </div>
          {props.children}
        </div>
        <div className='flex justify-end items-center pr-2'>
          <Avatar.Group>
            {props.choice?.ChosenBy?.map((user, index) => {
              return (
                <Avatar size={"xs"} img={getImageUrl(`/stream/Image/${user?.ProfilePhoto}`)} rounded stacked />
              )
            })}
          </Avatar.Group>
        </div>
      </div>
      {(followingQuestion!==null)?
      <div className={`mt-3 p-3 pl-4 bg-white border-dashed rounded-md`}>
        <div className='w-100 flex-wrap'>
            <div>
              <div>
                <h2 className="mb-5 text-simwave-bg">{followingQuestion.Caption}</h2>
                {followingQuestion.Choices?.map((choice, chx) => (
                  <Choice
                    selected={isSelected(
                      choice,
                      props.currentEmployeeId,
                    )}
                    answerSheet = {props.answerSheet}
                    currentEmployeeId={props.currentEmployeeId}
                    questionIndex={props.answerSheet.findIndex(q=>q.Id===followingQuestion?.Id)}
                    choiceIndex={chx}
                    toggleSelect={(questionIndex:number, choiceIndex:number) =>
                      props.toggleSelect(props.answerSheet.findIndex(q=>q.Id===followingQuestion?.Id),chx)
                    }
                    choice={choice}
                  >
                    {choice.Caption}
                  </Choice>
                ))}
              </div>
            </div>
          </div>
      </div>
      :null}
    </div>
  );
};
