import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, Card } from 'flowbite-react';
import {
  MdCalendarViewDay,
  MdEditCalendar,
  MdOutlineAssessment,
  MdQuestionAnswer,
  MdSubdirectoryArrowRight,
} from 'react-icons/md';
import { AiFillWarning } from 'react-icons/ai';
import { FiChevronUp } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from 'sweetalert2';

import Button from 'components/button/Button';
import { HideLoader, ShowLoader } from 'utilities/utils';
import {
  ChosenBy,
  QuestionChoice,
  QuestionModel,
} from 'common/models/QuestionModel';
import { CommentModel } from 'common/models/Comment';
import Accordion from 'components/accordion/Accordion';
import { VmProccessViewerSteps } from 'common/models/TaskStepModel';
import { setSteps } from 'components/task-steps/state/TaskStepsSlice';
import { SwitchComponent } from 'components/task-steps/SwitchComponent';
import { commands } from 'commands';
import { getImageUrl, transceiver } from 'global';
import { SET_BREADCRUMB } from 'utilities/SET_BREADCRUMB';
import { breadcrumb } from './breadcrumb';

import defaultImg from 'assets/images/default.png';
import moment from 'moment';

const QuizPreview = (props: any) => {
  SET_BREADCRUMB(breadcrumb);
  let { operationId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [appraisalQuestions, setAppraisalQuestions] = useState<QuestionModel[]>(
    [],
  );
  const [expandAll, setExpandAll] = useState<boolean>(true);

  const portfolioId = props.Data.PortfolioId;
  const TransitNext = () => {
    ShowLoader();
    let obj = {
      ContainerId: operationId,
      StepName: props.StepFullName,
      Name: 'Next',
    };
    transceiver.requestCommand(commands.Transit, obj, (result: any) => {
      transitResult(result);
    });
  };
  const transitResult = (msg: any) => {
    navigate('/task-list');
    HideLoader();
  };

  useEffect(() => {
    ShowLoader();
    loadQuestionnaire();
  }, []); // eslint-disable-line

  const loadQuestionnaire = () => {
    let obj = {
      AppraisalId: portfolioId,
    };
    transceiver.requestCommand(
      commands.SimwaveAppraisalLoadAnswerSheets,
      obj,
      (result: any, state: any) => {
        LoadAnswerSheets(result);
      },
    );
  };

  const LoadAnswerSheets = (msg: QuestionModel[]) => {
    let questions: QuestionModel[] = [];

    msg.forEach((questionnair: any) => {
      questionnair.Items.forEach((question: QuestionModel, idx: number) => {
        let choiceList: QuestionChoice[] = [];
        let comments: CommentModel[] = [];
        question.Choices.forEach((ch: QuestionChoice) => {
          let chosenList: ChosenBy[] = [];
          ch.ChosenBy?.forEach((chb: ChosenBy, idx: number) => {
            chosenList.push(chb);
          });
          let choice: QuestionChoice = {
            Name: ch.Caption,
            Description: ch.Description,
            Icon: ch.Icon,
            Id: ch.Id,
            ChosenBy: chosenList,
            FollowingQuestions: ch.FollowingQuestions,
          };
          choiceList.push(choice);
        });

        if (question.Comments.length > 0) {
          question.Comments.forEach((comment: any) => {
            let c: CommentModel = {
              Id: comment.Id,
              DateTime: comment.WrittenOn,
              Text: comment.Text,
              Positions: comment.Caption,
              QuestionId: question.Id,
              QuestionnaireId: questionnair.Id,
              Username: comment.Writer.Caption,
              ProfileImage: comment.Writer.ProfilePhoto,
            };
            comments.push(c);
          });
        }

        let obj: QuestionModel = {
          Index: idx,
          Id: question.Id,
          Choices: choiceList,
          Description: question.Description,
          Icon: question.Icon,
          Caption: question.Caption,
          Name: question.Name,
          ChosenBy: choiceList.find((f: any) => f.ChosenBy?.length > 0)
            ?.ChosenBy,
          Commntabel: choiceList.find((f: any) => f.ChosenBy?.length > 0)
            ? true
            : false,
          Comments: comments,
          QuestionType: 0,
          Questioner: {
            CurrentEmployeeId: questionnair.CurrentEmployeeId,
            Id: questionnair.Id,
            Caption: questionnair.Caption,
          },
          VisibleFlag: question.VisibleFlag,
        };
        questions.push(obj);
      });
    });
    setAppraisalQuestions(questions);
    HideLoader();
  };

  const transitBackResult = (msg: any) => {
    let ListStep: VmProccessViewerSteps = { steps: [] };
    msg.Map.Steps.forEach((item: any) => {
      ListStep.steps.push({
        Name: item.Name,
        Index: item.Index,
        Title: item.Title,
        CurrentFlag: item.CurrentFlag,
      });
    });
    dispatch(setSteps(ListStep));
    props.setBody(
      <SwitchComponent
        setBody={props.setBody}
        {...msg.Data}
        type={msg.Data.StepName}
      />,
    );
    HideLoader();
  };

  const TransitBack = () => {
    ShowLoader();
    let obj = {
      ContainerId: props.InstanceId,
      StepName: props.StepFullName,
      Name: 'Back',
    };
    transceiver.requestCommand(
      commands.Transit,
      obj,
      (result: any, state: any) => {
        transitBackResult(result);
      },
      null,
    );
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton:
        'bg-orange-500 text-white hover:bg-orange-700 focus:ring-orange-300 active:bg-orange-800 disabled:bg-orange-300 shadow-sm rounded-md p-2 ml-3',
      cancelButton:
        'bg-white !text-indigo-900 rounded-md !border-indigo-900 p-2 border hover:bg-gray-100 mr-3',
    },
    buttonsStyling: false,
  });

  const submit = () => {
    swalWithBootstrapButtons
      .fire({
        title: 'Confirm Submission',
        text: 'Are you sure to submit this appraisal?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, submit',
        cancelButtonText: 'No, cancel',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          TransitNext();
        }
      });
  };

  const getComment = (question: QuestionModel, chosenBy: ChosenBy) => {
    let comment = question.Comments.find(
      (c) => c.Username === chosenBy.Caption,
    );
    return comment !== undefined ? (
      <div className="pl-12 flex">
        <div className="comment-bubble mt-2 min-w-[360px]">
          <div className="text-xs text-gray-800 flex items-center pb-2">
            <MdEditCalendar className="mr-2 text-gray-400" size={16} />
            {moment(comment.DateTime).format('ll')}
          </div>
          {comment.Text}
        </div>
      </div>
    ) : null;
  };

  const getFollowingQuestions = (
    choice: QuestionChoice,
  ) => {
    let hasFollowing: boolean = hasFollowingQuestions(choice);
    let res: JSX.Element = <></>;
    if (hasFollowing) {
      let followingQuestion: QuestionModel;
      if (
        choice?.FollowingQuestions !== undefined &&
        choice?.FollowingQuestions !== null &&
        choice?.FollowingQuestions.length > 0
      )
      {
        followingQuestion = choice?.FollowingQuestions[0];
        res = <div className='ml-12 mt-8'>
            <h4 className='font-semibold text-xl flex'><MdSubdirectoryArrowRight size={28} />{followingQuestion.Caption}</h4>
            <hr className='mt-4' />
            {followingQuestion.Choices.map((choice:QuestionChoice)=>(
                <div className='border rounded-md border-gray-300 mt-2 p-4'>
                    <div className='font-semibold'>{choice.Caption}</div>
                    {choice.ChosenBy?.length!==undefined && choice.ChosenBy?.length>0?<hr className='mt-4'/>:<></>}
                    {choice.ChosenBy?.map((chosenBy:ChosenBy)=>(
                        <div className="flex items-center pt-2">
                            <img
                            className="w-11 aspect-square rounded-full"
                            src={
                                chosenBy?.ProfilePhoto
                                ? getImageUrl(
                                    `/stream/Image/${chosenBy?.ProfilePhoto}`,
                                    )
                                : defaultImg
                            }
                            alt=""
                            />
                            <div className="pl-2">
                            <div className="font-semibold">
                                {chosenBy.Caption}
                            </div>
                            <div className="text-xs font-semibold text-gray-500">
                                {chosenBy.Positions}
                            </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>;
      }
    }
    return res;
  };

  const hasFollowingQuestions = (choice: QuestionChoice): boolean => {
    //let result = false;
    // question.Choices?.forEach((ch) => {
    //   if (!result && ch.FollowingQuestions?.length !== undefined) {
    //     result = ch.FollowingQuestions?.length > 0;
    //   }
    // });
    return (
      choice.FollowingQuestions?.length !== undefined &&
      choice.FollowingQuestions?.length > 0
    );
  };
  return (
    <Card className="mt-4 min-h-[calc(100vh-180px)] flex flex-col justify-between">
      <div className="">
        <div className="flex items-center justify-between">
          <h3 className="text-2xl font-bold flex items-center gap-x-2">
            <MdOutlineAssessment className="scale-125" />
            Appraisal Questions Review
          </h3>
          <Button
            onClick={() => setExpandAll((prev) => !prev)}
            variant="tertiary"
            className="rounded-lg mb-1 w-36 focus:border-none focus-within:border-none"
            type="button"
          >
            {expandAll ? 'Collapse All' : 'Expand All'}
            <FiChevronUp
              className={`transition-transform transform ml-2 ${
                expandAll ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </Button>
        </div>
        <hr className="my-2" />
      </div>

      <div className="flex flex-col gap-y-2">
        {appraisalQuestions.map((question, i) =>
          question.VisibleFlag ? (
            <Accordion
              rippleEffect
              iconClassName="scale-150 mr-4"
              className={`h-full rounded-lg border ${
                question.ChosenBy ? '' : 'bg-pink-100'
              }`}
              expanded={expandAll || false}
              enabled
              title={
                <p
                  className={`font-semibold text-lg ${
                    question.ChosenBy ? 'text-slate-500' : 'text-red-700'
                  } select-none`}
                >
                  {i + 1}: {question.Caption}
                </p>
              }
            >
              <div className="flex flex-col gap-y-2">
                <div>
                  {question.Choices?.map((choice) => (
                    <div className="border-gray-200 bg-gray-50 p-4 mt-2 rounded-md border">
                      <span className="font-semibold text-xl text-simwave-bg">
                        {choice?.Name}
                      </span>
                      {choice.ChosenBy?.length !== undefined &&
                      choice.ChosenBy?.length > 0 ? (
                        <hr className="mt-4" />
                      ) : null}

                      <div>
                        {choice.ChosenBy?.map((chosenBy: ChosenBy) => (
                          <div className="pt-4">
                            <div>
                              <div className="flex items-center">
                                <img
                                  className="w-11 aspect-square rounded-full"
                                  src={
                                    chosenBy?.ProfilePhoto
                                      ? getImageUrl(
                                          `/stream/Image/${chosenBy?.ProfilePhoto}`,
                                        )
                                      : defaultImg
                                  }
                                  alt=""
                                />
                                <div className="pl-2">
                                  <div className="font-semibold">
                                    {chosenBy.Caption}
                                  </div>
                                  <div className="text-xs font-semibold text-gray-500">
                                    {chosenBy.Positions}
                                  </div>
                                </div>
                              </div>
                              {getComment(question, chosenBy)}
                            </div>
                          </div>
                        ))}
                      </div>
                      {getFollowingQuestions(choice)}
                    </div>
                  ))}
                </div>
              </div>
            </Accordion>
          ) : null,
        )}
      </div>
      <div className="flex items-center justify-between">
        <Button
          value="Cancel"
          variant="white"
          onClick={() => TransitBack()}
          className="rounded-lg"
        >
          Back
        </Button>
        <Button variant="primary" onClick={submit} className="rounded-lg">
          Confirm
        </Button>
      </div>
    </Card>
  );
};

export default QuizPreview;
