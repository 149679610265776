import GlowImage from 'pages/waiting/GlowImage'

const Waiting = () => {
    return (
        <div className='z-[999999] fixed top-0 left-0 right-0 bottom-0'>
            <GlowImage />
        </div>
    )
}

export default Waiting