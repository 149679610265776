import { EvaluatorModel } from 'common/models/EvaluatorModel'
import { getImageUrl } from 'global'

interface props {
    person?: EvaluatorModel
    icon?: JSX.Element
    role?: 'Appraisee' | 'Lead Evaluator' | 'Manager'
}

const OnePersonCard = ({ person, role, icon }: props) => {

    return (
        <div className="max-md:h-[175px] md:min-w-[100px] lg:w-[150px] md:max-w-sm py-3 px-1 lg:px-4 relative rounded-lg flex flex-col items-center shadow-inner-shadow-xs hover:shadow-md">
            <img
                alt=''
                className='-mt-8 w-14 h-14 aspect-square rounded-full border-[2px] border-slate-600'
                src={getImageUrl(`/stream/Image/${person?.ProfilePhoto}`)}
            />
            <div className='text-center'>
                <h2 className="text-sm text-gray-900 font-semibold title-font mb-1">{person?.Caption ?? person?.label ?? person?.Name}</h2>
                <p className="leading-relaxed text-xs text-slate-500">{person?.Positions}</p>
            </div>
            <div className='absolute top-0 left-0 right-0 bottom-0 overflow-hidden'>
                <div className='absolute right-4 bottom-6 scale-[7.5] opacity-[0.04]'>
                    {icon}
                </div>
            </div>
        </div>
    )
}

export default OnePersonCard