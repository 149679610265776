import React, { useEffect, useState } from 'react'
import { Card, Pagination } from 'flowbite-react'
import { useSearchParams } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { FiSearch } from 'react-icons/fi'

import { TemplateModel } from 'common/models/TemplateModel'
import { commands } from 'commands'
import { transceiver } from 'global'
import TemplateDetails from './TemplateDetails'
import HierarchicalMenu from './HierarchicalMenu'
import ArchiveItem from './ArchiveItem'
import { debounce } from 'utilities'
import DetailsModal from './DetailsModal/DetailsModal'
import { HideLoader, ShowLoader } from 'utilities/utils'

const PageSize = 10

export interface selectedItem {
    id: string
    status: any
}

const ArchiveAppraisal = () => {

    const [listAppraisals, setListAppraisals] = useState<any[]>()
    const [treeStructure, setTreeStructure] = useState<any>()
    const [treeConfig, setTreeConfig] = useState<string>()

    const [selectedTreeItemId, setSelectedTreeItemId] = useState<string>()
    const [searchPhrase, setSearchPhrase] = useState<string>()
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateModel>()

    const [selectedItem, setSelectedItem] = useState<selectedItem>({ id: '', status: undefined })

    const [searchParams, setSearchParams] = useSearchParams()
    const [total, setTotal] = useState(0);

    const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.get('page')) || 1)

    const readConfigResult = (res: any) => {
        setTreeConfig(res.StringValue)
    }

    useEffect(() => {
        setSelectedTreeItemId(treeStructure?.Id)
        transceiver.requestCommand(commands.ReadConfig, { Name: 'AppraisalWalk' }, readConfigResult)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedTreeItemId) {
            ShowLoader();
            transceiver.requestCommand(
                commands.ListAppraisals,
                {
                    From: (currentPage - 1) * PageSize,
                    To: currentPage * PageSize,
                    ContainerId: selectedTreeItemId,
                    searchPhrase
                },
                (result: any, state: any) => {
                    const totalPage = Math.ceil(result.Total / PageSize)
                    setTotal(totalPage);
                    setListAppraisals(result.Items);
                    HideLoader();
                },
            );
        }
    }, [currentPage, searchPhrase, selectedTreeItemId])

    useEffect(() => {
        if (treeConfig) {
            ShowLoader();
            transceiver.requestCommand(commands.LoadTreeStructure, { Walk: treeConfig, IdentitySpec: ["Taxonomy", "Domain"] }, (msg:any)=>{setTreeStructure(msg);HideLoader()})
        }
    }, [treeConfig])


    const loadAppraisal = (appraisal: any) => {
        ShowLoader();
        transceiver.requestCommand(commands.SimwaveAppraisalLoad, { AppraisalId: appraisal.Id }, (result: any, state: any) => { loadAppraisalResult(result) ; HideLoader();})
    }

    const loadAppraisalResult = (result: any) => {
        
        setSelectedItem({ id: result.Id, status: result })
    }

    const handleSearch = (searchStr: string) => {
        setSearchPhrase(searchStr)
    }

    const debouncedSearch = debounce(handleSearch, 500)

    return (
        <>
            <Card className='mt-6'>
                <div className='flex gap-x-4'>
                    <div className='border rounded-lg py-3 px-2 h-[500px] w-[300px] hidden sm:block sm:visible md:invisible lg:visible'>
                        <HierarchicalMenu
                            data={treeStructure}
                            setData={setTreeStructure}
                            setId={setSelectedTreeItemId}
                        />
                    </div>
                    <div className='flex flex-col justify-between max-h-[calc(100vh-240px)] grow border rounded-lg overflow-auto'>
                        <div className='flex items-center px-4 border-b'>
                            <FiSearch />
                            <input
                                type="text"
                                placeholder='Search...'
                                onChange={(e) => debouncedSearch(e.target.value)}
                                className='outline-none border-none grow text-sm py-3 text-slate-800'
                            />
                        </div>
                        <div className='grow overflow-y-scroll h-[430px] flex flex-col'>
                            {
                                listAppraisals?.map((item) => (
                                    <ArchiveItem key={item.Id} item={item} setItem={() => loadAppraisal(item)} />
                                ))
                            }
                        </div>

                        <AnimatePresence>
                            {
                                selectedTemplate && (
                                    <TemplateDetails
                                        key="selcted-tmp"
                                        selectedTemplate={selectedTemplate}
                                        onClose={() => setSelectedTemplate(undefined)}
                                    />
                                )
                            }
                        </AnimatePresence>

                        <div className='flex items-center justify-center bg-white border-t py-1 relative h-12'>
                            <Pagination
                                className='absolute bottom-1'
                                currentPage={currentPage ?? 1}
                                layout="pagination"
                                onPageChange={(page) => {
                                    setCurrentPage(page)
                                    setSearchParams({ page: page.toString() });
                                }}
                                showIcons
                                totalPages={total}
                                previousLabel="Previous"
                            />
                        </div>
                    </div>
                </div>
            </Card>
            <DetailsModal
                open={selectedItem.id ? true : false}
                selectedItem={selectedItem}
                onClose={() => setSelectedItem({ id: '', status: undefined })}
            />
        </>
    )
}

export default ArchiveAppraisal