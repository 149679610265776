import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as swiperType } from 'swiper/types'
import { MdArrowBackIos } from 'react-icons/md'

import Stepper  from 'components/Stepper/Stepper'
import { PersonType } from 'common/types'
import { extractText } from 'utilities'
import { getImageUrl } from 'global'

import defaultImg from 'assets/images/default.png'
import 'swiper/css';


interface props {
    managers?: PersonType[]
}

const ManagersSwiper = ({ managers }: props) => {

    const swiperRef = useRef<swiperType>()
    const [activeIndex, setActiveIndex] = useState<number>(swiperRef.current?.activeIndex ?? 0)

    const swipeSlidePrev = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        if (!swiperRef.current?.isBeginning) {
            swiperRef.current?.slidePrev()
        }
    }

    const swipeSlideNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        if (!swiperRef.current?.isEnd) {
            swiperRef.current?.slideNext()
        }
    }

    return (
        <div className='border border-simwave-brand rounded-lg relative flex flex-col justify-center'>
            <div className='text-left bg-simwave-brand rounded-t-lg font-bold text-sm px-3 h-7 pt-[2px] text-slate-50 absolute top-0 right-0 left-0'>
                <p className='text-slate-50'>Managers ({managers?.length})</p>
            </div>
            <div className='h-full'>
                <Swiper
                    className='relative shadow-sm h-full'
                    draggable={false}
                    spaceBetween={16}
                    slidesPerView={1}
                    centeredSlides
                    onSwiper={(s) => swiperRef.current = s}
                    onSlideChangeTransitionEnd={(s) => setActiveIndex(s.activeIndex)}
                >
                    {managers?.map((item) => {
                        const { itemName } = extractText(item.label, { fSymbol: '(', secSymbol: ')' })
                        return (
                            <SwiperSlide key={item?.value} className='flex xl:flex-col justify-center items-center pt-7 pb-2 xl:pb-7'>
                                <div className='flex gap-x-2 xl:flex-col justify-between items-center h-3/4 px-4'>
                                    <img className='xl:w-14 w-10 mx-auto aspect-square rounded-full xl:ring-4 xl:ring-offset-4 xl:ring-simwave-orange'
                                        src={(item.ProfilePhoto === undefined || item.ProfilePhoto === null || item.ProfilePhoto === "") ? defaultImg : getImageUrl(`/stream/Image/${item.ProfilePhoto}`)}
                                        alt=''
                                    />
                                    <div className='self-center'>
                                        <p className='xl:text-lg text-sm font-semibold'>{itemName}</p>
                                        <p className='xl:text-base text-sm font-medium min-h-[10px]'>
                                            {item?.Postion?.map((item) => item)}
                                        </p>
                                    </div>
                                    <a href={`mailto:${item?.label + "@simwave.nl"}`} className='text-[10px] self-center'>{item?.label + "@simwave.nl"}</a>
                                </div>

                            </SwiperSlide>
                        )
                    })
                    }

                    <div className='z-[9999] absolute top-[6px] right-2'>
                        <div className='flex items-center gap-x-[2px]'>
                            <button
                                type='button'
                                onClick={swipeSlidePrev}
                                className={swiperRef.current?.isBeginning ? 'invisible' : 'visible'}
                            >
                                <MdArrowBackIos color='#FFF' />
                            </button>
                            <Stepper
                                items={managers}
                                activeStep={activeIndex}
                            />
                            <button
                                type='button'
                                onClick={swipeSlideNext}
                                className={swiperRef.current?.isEnd ? 'invisible' : 'visible'}
                            >
                                <MdArrowBackIos color='#FFF' className="rotate-180" />
                            </button>
                        </div>
                    </div>


                </Swiper>
            </div>
        </div>
    )
}

export default ManagersSwiper