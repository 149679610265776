import React from 'react'
import { Variants, motion } from 'framer-motion'
import Button from 'components/button/Button'

interface props {
    onClose: () => void
    selectedTemplate?: any
}

const CusModalAnimVariants: Variants = {
    remove: {
        opacity: 0,
        scale: 0,
        width: '40vw',
        height: '75vh'
    },
    animate: {
        opacity: 1,
        position: 'fixed',
        top: "50%",
        left: "50%",
        scale: 1,
        x: "-50%",
        y: "-50%"
    }
}

const TemplateDetails: React.FC<props> = ({ selectedTemplate, onClose }) => {

    return (
        <div
            onClick={(e) => { e.stopPropagation(); onClose() }}
            className='bg-slate-900/40 backdrop-blur-[2px] fixed top-0 left-0 bottom-0 right-0 z-50'
        >
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className='bg-white rounded-2xl border shadow-lg overflow-hidden relative'
                layoutId={selectedTemplate?.Id}
                variants={CusModalAnimVariants}
                initial="remove"
                animate="animate"
                exit="remove"
            >
                <div className='flex justify-between items-center py-4 px-6 bg-simwave-bg text-white'>
                    <h2 className='text-lg font-semibold'>{selectedTemplate.Title}</h2>
                    <button type='button' onClick={onClose}>x</button>
                </div>
                <div className='bg-simwave-orange h-1 w-full' />
                <div className='py-4 px-6 overflow-y-scroll h-full pb-44'>
                    <div className='flex items-center justify-between'>
                        <p className='font-medium'>{selectedTemplate.Organization}</p>
                        <p className='text-sm text-slate-600'>{selectedTemplate.CultureCDT}</p>
                    </div>
                    <div className='text-sm mt-4'>
                        <p className='font-medium'>Description</p>
                        <p className='border rounded-lg py-1 px-2'>{selectedTemplate?.Description}</p>
                    </div>
                </div>

                <div className='absolute left-0 right-0 bottom-0 border-t bg-white'>
                    <div className='flex items-center justify-end py-2 px-6'>
                        <Button className='rounded-lg' type='button' variant='white' onClick={onClose}>Close</Button>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default TemplateDetails