import { mapToTreeStructure } from 'mapperConfigs';
import React, { useEffect, useState } from 'react';
import TreeMenu, { TreeNode, TreeNodeInArray } from 'react-simple-tree-menu';

import 'styles/TreeComponent.css';

interface props {
  data?: any;
  setData: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  setId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

type TreeType = { [name: string]: TreeNode } | TreeNodeInArray[] | undefined;

const HierarchicalMenu: React.FC<props> = ({ data, setId }) => {
  const [treeData, setTreeData] = useState<TreeType>();
  const [initialOpenNodes,setinitialOpenNodes] = useState<string[]>();
  useEffect(() => {
    if (data) {
      const mappedData = mapToTreeStructure(data);
      setTreeData(mappedData);
      setinitialOpenNodes(["0"]);
    }
  }, [data]);

  return data ? (
    <TreeMenu
      data={treeData}
      hasSearch={false}
      debounceTime={300}
      initialOpenNodes={initialOpenNodes}
      onClickItem={({ key, label, level, ...props }) => setId(props.id)}
    />
  ) : (
    <div className="flex justify-center text-simwave-brand">
      No data available
    </div>
  );
};

export default HierarchicalMenu;
