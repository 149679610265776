import { CalendarContainer } from 'react-datepicker';

interface props {
    className?: string
    children: any
}

const CusCalendarContainer = ({ children, className }: props) => {
    return (
        <div className='z-10 absolute bottom-11'>
            <CalendarContainer className={`${className}  z-50`}>
                {children}
            </CalendarContainer>
        </div>
    );
}

export default CusCalendarContainer