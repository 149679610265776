import { useState } from 'react';
import moment from 'moment';

import { CommentModel } from 'common/models/Comment';
import Button from 'components/button/Button';
import Divider from 'components/divider/Divider';

import defaultImg from "assets/images/default.png"
import { getImageUrl } from 'global';
export const Comments = (props: any) => {
    const [text, setText] = useState<string>();

    return (
        <div className={props.className}>
            {props.children}
            {
                <form id='send-comment-container' className="mb-6">
                    <div className={`py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 transition-colors duration-300 relative`}>
                        <label className="sr-only">Your comment</label>
                        <textarea
                            rows={3}
                            onChange={(e) => setText(e.target.value)}
                            className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none"
                            placeholder={"Write a comment..."}
                            required
                            disabled={props.disabled}
                            value={text}
                        />
                    </div>
                    <Button type="submit" disabled={props.disabled} onClick={(e) => {
                        e.preventDefault();
                        props.onSubmit(text);
                        setText('');
                    }} variant="secondary">
                        Post comment
                    </Button>
                </form>
            }

        </div>
    )
}

Comments.Comment = (props: CommentModel) => {
    //console.log("comment.ProfileImage",props.ProfileImage);
    return (
        <div className="mb-2 text-base">
            <div className="flex justify-between items-center mb-2">
                <div className="flex justify-between w-full">
                    <div className="inline-flex gap-2 items-center text-sm text-gray-900">
                        <img
                            className="w-10 h-10 rounded-full"
                            
                            src={getImageUrl(`/stream/Image/${props.ProfileImage}`)}
                            alt="user-avatar"
                        />
                        <div className="flex flex-col">
                            <p className='font-medium'>
                                {props.Username}
                            </p>
                            <p className="text-xs text-gray-900 italic">
                                {props.Positions}
                            </p>
                        </div>
                    </div>
                    <p className="time-style">
                        {moment(props.DateTime).fromNow()}
                    </p>
                </div>
            </div>
            <p className="text-gray-500 text-sm text-justify">{props.Text}</p>
            <Divider className="mt-6" thickness={2} />
        </div >
    )
};


export default Comments;