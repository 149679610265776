import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { VmProccessViewerSteps } from "common/models/TaskStepModel";

const initialState: VmProccessViewerSteps = {
    steps: [],
}

export  const TaskStepsSlice = createSlice({
    name: 'taskStep-path',
    initialState,
    reducers: {
        setSteps: (state, action: PayloadAction<VmProccessViewerSteps>) => {
            state.steps = action.payload.steps;
        },
        clearPath: (state) => {
            state.steps = [];
        }
    },
})

export const { setSteps,clearPath } = TaskStepsSlice.actions

export default TaskStepsSlice.reducer