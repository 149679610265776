import React, { ReactNode } from 'react';
import { Button as FlowbiteButton } from 'flowbite-react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  variant?:
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'positive'
  | 'negative'
  | 'text'
  | 'white'
  | 'transparent'
  | 'uncontained';
}

const Button: React.FC<ButtonProps> = ({
  className,
  children,
  variant = 'primary',
  disabled = false,
  ...rest
}) => {
  let color;
  switch (variant) {
    case 'primary':
      color =
        'bg-orange-500 text-white hover:bg-orange-700 focus:ring-orange-300 active:bg-orange-800 disabled:bg-orange-300 shadow-sm rounded-full';
      break;
    case 'secondary':
      color =
        'bg-blue-600 text-white hover:bg-blue-800 focus:ring-blue-200 active:bg-blue-500 disabled:bg-blue-200 shadow-sm rounded-full';
      break;
    case 'tertiary':
      color =
        '!bg-blue-100 !text-indigo-900 hover:bg-blue-100 focus:ring-blue-200 active:bg-blue-200 disabled:bg-gray-200 shadow-sm rounded-full';
      break;
    case 'positive':
      color =
        'bg-green-500 text-white hover:bg-green-700 focus:ring-green-300 active:bg-green-800 disabled:bg-green-300 shadow-sm rounded-full';
      break;
    case 'negative':
      color =
        'bg-pink-700 text-white hover:bg-pink-800 focus:ring-pink-300 active:bg-pink-800 disabled:bg-pink-300 shadow-sm rounded-full';
      break;
    case 'text':
      color =
        '!text-indigo-900 hover:!bg-blue-100 focus:!ring-blue-200 active:!bg-blue-200 disabled:!bg-transparent rounded-md';
      break;
    case 'white':
      color = '!bg-white !text-indigo-900 rounded-full !border-indigo-900';
      break;
    case 'transparent':
      color = '!bg-transparent rounded-full !text-black';
      break;
    case 'uncontained':
      color =
        '!text-blue-400 focus:!text-blue-700 active:!text-blue-910 disabled:!text-blue-200';
      break;
  }
  return (
    <FlowbiteButton
      disabled={disabled}
      className={`${color} ${className}`}
      {...rest}
    >
      {children}
    </FlowbiteButton>
  );
};

export default Button;
