import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface OrgSliceType {
    name: string
}

const initialState: OrgSliceType = {
    name: '',

}

export const OrgSlice = createSlice({
    name: 'setOrg',
    initialState,
    reducers: {
        setOrg: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        }
    },
})

export const { setOrg } = OrgSlice.actions

export default OrgSlice.reducer