import { Spinner } from 'flowbite-react'

export const Loading = () => {
  return (
    <div className='bg-gray-600/70 text-white global-loading'>
        <Spinner
            aria-label="Extra large spinner example"
            size="xl"
        />
    </div>
  )
}
