import { MdKeyboardDoubleArrowRight } from 'react-icons/md'
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';

export const TaskSteps = () => {
    const taskSteps = useSelector((state: RootState) => state.taskStep.steps)
    const activeClassLi = "text-simwave-brand dark:text-simwave-bg";
    const activeClassNumber = "border-simwave-bg text-simwave-bg rounded-full shrink-0 dark:text-simwave-bg";
    const notActiveClassNumeber = "border-gray-500 rounded-full shrink-0 dark:border-gray-400";
    return (
        <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4">
            {taskSteps.map((item: any, index: any) => (
                <li key={`step-${index}`} className={`flex items-center ${(item.CurrentFlag) ? activeClassLi : null}`}>
                    <span className={`flex items-center justify-center w-5 h-5 mr-2 text-xs border ${(item.CurrentFlag) ? activeClassNumber : notActiveClassNumeber}`}>
                        {index + 1}
                    </span>
                    {item.Title}
                    {(index + 1 === taskSteps.length) ? null : <MdKeyboardDoubleArrowRight />}
                </li>
            ))}
        </ol>
    )
}
