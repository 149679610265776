import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert } from 'flowbite-react';
import { useDispatch } from 'react-redux'

import { setCredentials } from 'features/auth/authSlice'
import { useLoginMutation } from 'features/auth/authApiSlice';
import { setCookie, transceiver } from 'global';

import Logo from "assets/images/Logo-Light.png";
import Slogan from "assets/images/slogan-bg.png";

const Index = () => {
  const userRef = useRef<HTMLInputElement>()
  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [login, { isLoading }] = useLoginMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    userRef.current?.focus()
    setErrMsg('')
  }, [user, pwd])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    var ctn = {
      username: user,
      password: pwd
    };
    transceiver.requestMessage("*", ctn, "SignIn@Master", (result: any) => { handleSetToken(result) }, (state: any) => { }, (result: any) => { handleLoginFailure(result) })
  }

  const handleSetToken = (msg: any) => {
    if (msg.Performative.toLowerCase() === "agree") {
      let accessToken = msg.Content.Token;
      setCookie('Token', accessToken, null);
      setCookie('GAMTKNWEBSELFHOST', accessToken, null);
      dispatch(setCredentials({ user, accessToken }))
      transceiver.Ready();
      navigate('/dashboard');
    } else {
      setErrMsg(msg.Content);
    }
  }

  const handleLoginFailure = (msg: any) => {
    setErrMsg(msg.Content);
  }
  const handleUserInput = (e: any) => setUser(e.target.value)
  const handlePwdInput = (e: any) => setPwd(e.target.value)
  return (
    <>
      <div>
        <div className="relative bg-simwave-bg bg-gradient-to-br from-simwave-brand to-simwave-brand-light flex min-h-full h-screen justify-center md:px-12 lg:px-0">
          <div className="relative bg-simwave-bg bg-gradient-to-br from-simwave-brand to-simwave-brand-light justify-center z-10 flex flex-1 flex-col py-10 px-4 shadow-2xl sm:justify-center md:flex-none md:px-10">
            <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
              <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                  <img className="mx-auto h-20 w-auto" src={Logo} alt="Simwave" />
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                  {isLoading ?
                    <h1>Loading...</h1> :
                    <form className="space-y-6" method="POST" onSubmit={handleSubmit}>
                      <div className="flex items-center justify-center">
                        {
                          errMsg ? <Alert
                            color="failure"
                          >
                            <span>
                              <p>
                                {errMsg}
                              </p>
                            </span>
                          </Alert> : null
                        }

                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-white text-sm font-medium leading-6"
                        >
                          Username
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="text"
                            tabIndex={1}
                            placeholder="Email / Username / Phone number"
                            autoComplete="email"
                            onChange={handleUserInput}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div>
                        <div className="flex items-center justify-between">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium leading-6 text-white"
                          >
                            Password
                          </label>
                          <div className="text-sm">
                            <a
                              tabIndex={4}
                              href="/login"
                              className="font-semibold text-simwave-orange duration-300 hover:text-indigo-500"
                            >
                              Forgot password?
                            </a>
                          </div>
                        </div>
                        <div className="mt-2">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            tabIndex={2}
                            placeholder="Password"
                            autoComplete="current-password"
                            onChange={handlePwdInput}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div>
                        <button tabIndex={3} className="flex w-full justify-center rounded-md bg-simwave-orange transform duration-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign In</button>
                      </div>
                    </form>}

                  <p className="mt-10 text-center text-sm text-gray-300">
                    <a
                      href="/login"
                      className="font-semibold leading-6 duration-300 text-simwave-orange hover:text-indigo-400"
                    >
                      Privacy and Policy
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="login-main-image sm:contents lg:relative lg:flex lg:flex-1 justify-items-center p-0 items-center justify-center" >
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 px-5 py-5">
              <img className="max-lg:hidden" src={Slogan} alt='Simwave Slogan' />
            </div>
          </div>
        </div>
      </div>
    </>)
}
export default Index;
