import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadcrumbSimwave } from 'components/breadcrumb/BreadcrumbSimwave';
import { Loading } from 'components/Loading/Loading';
import SideMenu from 'components/sideMenu/SideMenu';
import { RootState } from 'app/store';
import Toast from 'components/toast/Toast';
import { hideToast } from 'features/toast/toastSlice';

const Layout = () => {
  const toast = useSelector((state: RootState) => state.toast);
  const dispatch = useDispatch();

  return (
    <div className="flex relative">
      <Loading />
      <SideMenu />
      <div
        id="outlet"
        className={`panel-main-body relative p-0 md:p-6 h-screen w-full bg-cover overflow-auto`}
      >
        <div className="px-6 pl-6 mt-14 md:p-0 md:mt-0 relative ">
          <BreadcrumbSimwave />
          <Outlet />
          {toast.showToast && (
            <Toast
              message={toast.message}
              variant={toast.variant}
              onClose={() => dispatch(hideToast())}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
