import { Card } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { HideLoader, ShowLoader } from 'utilities/utils';
import { TemplateModel } from 'common/models/TemplateModel';
import { Template } from 'components/template/Template';
import { VmProccessViewerSteps } from 'common/models/TaskStepModel';
import { setSteps } from 'components/task-steps/state/TaskStepsSlice';
import { SwitchComponent } from 'components/task-steps/SwitchComponent';
import Button from 'components/button/Button';
import { IconMapper } from 'utilities/IconMapper';
import { commands } from 'commands';
import { transceiver } from 'global';
import { breadcrumb } from './breadcrumb';
import { SET_BREADCRUMB } from 'utilities/SET_BREADCRUMB';

export const SelectTemplate = (props: any) => {
  SET_BREADCRUMB(breadcrumb);

  const [templates, setTemplate] = useState<TemplateModel[]>();
  let [selectedTemplate, setSelectedTemplate] =
    useState<TemplateModel | null>();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const operationId = props.InstanceId;
  const stepName = props.StepFullName;

  useEffect(() => {
    ShowLoader();
    loadtemplates();
  }, []); // eslint-disable-line

  const loadtemplates = () => {
    transceiver.requestCommand(
      commands.SimwaveAppraisalLoadTemplates,
      {},
      (result: any, state: any) => {
        loadtemplatesResult(result);
      },
    );

  };

  const loadtemplatesResult = (msg: any) => {
    let templateList: TemplateModel[] = [];
    msg.forEach((item: any) => {
      templateList.push({
        Id: item.Id,
        Name: item.Data.Name,
        Caption: item.Data.Caption,
        Description: item.Data.Description,
        Icon: item.Data.Icon,
      });
    });

    setTemplate(templateList);
    HideLoader();
  };


  const setTemplateData = () => {
    ShowLoader();
    let obj = {
      InstanceId: operationId,
      ParameterName: 'Template',
      Value: selectedTemplate,
      PersistenceFlag: true,
    };
    transceiver.requestCommand(
      commands.SetInstanceParameter,
      obj,
      (result: any, state: any) => {
        setinstanceData(result, state);
      },
    );
  };

  const setinstanceData = (msg: any, state: number) => {
    let obj = {
      ContainerId: operationId,
      StepName: stepName,
      Name: 'Next',
    };
    transceiver.requestCommand(
      commands.Transit,
      obj,
      (result: any, state: any) => {
        transitResult(result);
      },
      null,
    );
  };

  const transitResult = (msg: any) => {
    let ListStep: VmProccessViewerSteps = { steps: [] };
    msg.Map.Steps.forEach((item: any) => {
      ListStep.steps.push({
        Name: item.Name,
        Index: item.Index,
        Title: item.Title,
        CurrentFlag: item.CurrentFlag,
      });
    });
    dispatch(setSteps(ListStep));
    window.history.pushState(null, '', msg.Step.FullName);
    props.setBody(
      <SwitchComponent
        setBody={props.setBody}
        {...msg.Data}
        type={msg.Data.StepName}
      />,
    );
    HideLoader();
  };

  const checkIsSelected = (template: TemplateModel): boolean => {
    return selectedTemplate === template;
  };

  return (
    <Card className="bg-white/60 mt-4 block !justify-start">
      <div className="h-full flex flex-col justify-between">
        <div>
          <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white pb-5">
            Select Template
          </h5>
          <hr />

          <div className="container px-0 py-5 mx-auto flex flex-wrap">
            <div className="flex flex-wrap -m-4">

              {templates?.map((template, index) => (
                <Template
                  setSelectedHandler={() => setSelectedTemplate(template)}
                  selected={checkIsSelected(template)}
                  icon={
                    IconMapper(template.Icon, "", 24)
                  }
                  summary={template.Description}
                  title={template.Caption}
                  size='normal'
                />
              ))}
            </div>
          </div>
          <div className="grid gap-6 md:grid-cols-4 pt-5">
          </div>
        </div>
        <div className="flex justify-between mt-10">
          <Button
            value="Cancel"
            variant="white"
            className='rounded-lg'
            onClick={() => navigate('/task-list')}
          >
            Cancel
          </Button>

          <Button
            value="Create"
            variant="primary"
            disabled={!selectedTemplate}
            className='rounded-lg'
            onClick={() => setTemplateData()}
          >
            Next
          </Button>
        </div>
      </div>
    </Card>
  );
};
