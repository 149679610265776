import React from 'react';
import { MdComment } from 'react-icons/md';

interface GradeWrapperProps {
  grade: {
    earned: number;
    max: number;
    Text: string | null | undefined
  };
}

export const GradeWrapper: React.FC<GradeWrapperProps> = ({ grade }) => {
  const percentage = (grade?.earned / grade?.max) * 100;
  let classes = '';

  if (percentage >= 0 && percentage <= 25) {
    classes = 'bg-red-200 text-red-600';
  } else if (percentage > 25 && percentage <= 50) {
    classes = 'bg-orange-200 text-orange-600';
  } else if (percentage > 50 && percentage <= 75) {
    classes = 'bg-blue-200 text-blue-600';
  } else if (percentage > 75 && percentage <= 100) {
    classes = 'bg-green-200 text-green-600';
  }

  return grade ? (
    <>
    <div className=' text-center inline-block'>
      <div
          className={`min-w-[100px] font-semibold rounded-md text-lg flex flex-col justify-center items-center w-1/2 md:flex-row md:w-5/6 mx-auto ${classes}`}
        >
        <span>{grade.earned}</span>
        <span>/</span>
        <span>{grade.max}</span>
      </div>
    </div>
      
      <div className='w-full'>
        {
          grade.Text ? <><p color='info' className={`mt-2 text-left bg-slate-300/40 text-gray-800 rounded-md text-xs p-1`}> <MdComment  className='inline-block text-gray-500' size={16}/>  {grade.Text}</p> </> : null
        }
      </div>

    </>
  ) : (
    <div className="bg-gray-200 text-center font-semibold rounded-md uppercase">
      Missing data
    </div>
  );
};
