import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react'
import { Avatar, Badge, Card, Progress } from 'flowbite-react';
import {
  MdEditCalendar,
  MdOutlineSpatialAudioOff,
  MdOutlineSupervisedUserCircle,
  MdSecurity,
} from 'react-icons/md';
import { BiSelectMultiple } from 'react-icons/bi';

import { setSteps } from 'components/task-steps/state/TaskStepsSlice';
import Button from 'components/button/Button';
import { HideLoader, ShowLoader } from 'utilities/utils';
import { VmProccessViewerSteps } from 'common/models/TaskStepModel';
import { SwitchComponent } from 'components/task-steps/SwitchComponent';
import { EvaluatorModel } from 'common/models/EvaluatorModel';
import { generateBadgeColor } from 'utilities';
import { commands } from 'commands';
import { getImageUrl, transceiver } from 'global';

import defaultImg from "assets/images/default.png"

interface VMEvaluation {
  Progress: number,
  Evaluator: EvaluatorModel
}

export const AppraisalQuiz = (props: any) => {

  const [questioners, setQuestioners] = useState<string[]>([]);
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [remainingEvaluationCount, setRemainingEvaluationCount] = useState<number>();
  const [evaluations, setEvaluations] = useState<VMEvaluation[]>([]);
  const [appraisee, SetAppraisee] = useState<EvaluatorModel>();
  const [deadline, setDeadline] = useState<string>();
  const portfolioId = props.Data.PortfolioId;

  const dispatch = useDispatch();
  let { operationId } = useParams();


  useEffect(() => {
    loadQuestionnaire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadQuestionnaire = () => {
    ShowLoader();
    let obj = {
      AppraisalId: portfolioId
    }
    transceiver.requestCommand(commands.SimwaveAprLoadIntroduction, obj, (result: any, state: any) => { LoadAnswerSheets(result) })
  }

  const LoadAnswerSheets = (msg: any) => {
    let questioners: string[] = [];
    msg.Specification.Questionnaires.forEach((qun: any) => {
      questioners.push(qun?.Caption);
    });
    setQuestioners(questioners);
    let evaluatorList: VMEvaluation[] = [];
    msg.Specification.Evaluations.forEach((item: any) => {
      let evaluation: EvaluatorModel = {
        Caption: item.Evaluator.Caption,
        Positions: item.Evaluator.Positions,
        Description: item.Evaluator.Description,
        ProfilePhoto: item.Evaluator.ProfilePhoto,
        Id: item.Evaluator.Id
      }

      let obj = {
        Progress: item.Progress,
        Evaluator: evaluation
      }
      evaluatorList.push(obj);
    });

    SetAppraisee(evaluatorList[0].Evaluator);
    evaluatorList = evaluatorList.filter(f => f.Evaluator.Id !== appraisee?.Id);
    setTitle(msg.Specification.Title);
    setEvaluations(evaluatorList);
    setDescription(msg.IntroductionText);
    setRemainingEvaluationCount(msg.Specification.RemainingEvaluationCount);
    setDeadline(new Date(msg.Specification.CultureDeadline).toLocaleDateString());
    HideLoader();
  }

  const Transit = () => {
    ShowLoader();
    let obj = {
      ContainerId: operationId,
      StepName: props.StepFullName,
      Name: "Next"
    };
    transceiver.requestCommand(commands.Transit, obj, (result: any) => { transitResult(result) }, null);
  }

  const transitResult = (msg: any) => {
    let ListStep: VmProccessViewerSteps = { steps: [] };
    msg.Map.Steps.forEach((item: any) => {
      ListStep.steps.push({
        Name: item.Name,
        Index: item.Index,
        Title: item.Title,
        CurrentFlag: item.CurrentFlag
      });
    })
    dispatch(setSteps(ListStep));
    window.history.pushState(null, "", msg.Step.FullName);
    props.setBody(<SwitchComponent setBody={props.setBody}  {...msg.Data} type={msg.Data.StepName} />);
    HideLoader();
  }


  return (
    <div>
      <div className="grid gap-6 md:grid-cols-6 mt-5">
        <div className="md:col-span-4">
          <Card>
            <h1 className="text-simwave-brand text-xl">
              <div>
                {title}
              </div>
              <div className="flex justify-start items-center text-sm mt-3 text-gray-700">
                <div className="flex justify-start items-center">
                  <MdEditCalendar />
                  <span className="pl-2">{deadline}</span>
                </div>
                <div className="flex justify-start items-center px-10">
                  <MdOutlineSpatialAudioOff />
                  <span className="pl-2">{appraisee?.Caption}</span>
                </div>
                <div className="flex justify-start items-center">
                  <MdOutlineSupervisedUserCircle />
                  <span className="pl-2">{remainingEvaluationCount}</span>
                </div>
              </div>
            </h1>
            <hr />
            <pre className='whitespace-pre-wrap text-justify'>
              <p>
              {description}
              </p>
            </pre>
            <hr />
            <div className='flex justify-start mt-3 flex-wrap gap-1'>
              {
                questioners.map((item) => {
                  return (
                    <div
                      className={`w-auto p-6 h-8 md:p-2 text-center flex text-sm items-center font-semibold text-gray-600 bg-gray-100 duration-200 transition-all rounded-md`}
                    >
                      <BiSelectMultiple className="mr-1" />
                      <p className="mx-auto">{item}</p>
                    </div>
                  );
                })
              }

            </div>
            <div className="flex justify-start mt-4">
              <Button
                onClick={() => Transit()}
                className="w-[200px]"
              >
                Start
              </Button>
            </div>
          </Card>
        </div>
        <div className="md:col-span-2">
          <Card className='mt-16'>
            <div className="d-flex flex-col justify-center items-center">
              <div className="flex justify-center">
                <div className="-mt-16 bg-white w-fit rounded-full">
                  <Avatar
                    size={'lg'}
                    img={appraisee?.ProfilePhoto ? getImageUrl(`/stream/Image/${appraisee?.ProfilePhoto}`) : defaultImg}
                    rounded
                    bordered
                    stacked
                  />
                </div>
              </div>
              <div className="bg-gray-200/50 rounded-lg mt-4 pb-3">
                <h3 className="text-center font-bold text-black/80 pt-2">
                  {appraisee?.Caption}
                </h3>
                <h4 className="text-center text-sm text-black/60 flex justify-center">
                  <MdSecurity className="text-black/60 text-base mt-1 mr-2" />{' '}
                  ({appraisee?.Positions})
                </h4>
                <div></div>
              </div>

              <hr className="mt-4" />
              <div className="flow-root">
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                  {
                    evaluations.map((item: any) => {
                      return (
                        <li className="py-3 sm:py-4">
                          <div className="flex items-center space-x-4">
                            <div className="shrink-0">
                              <Avatar
                                size={'sm'}
                                img={item?.Evaluator.ProfilePhoto ? getImageUrl(`/stream/Image/${item?.Evaluator.ProfilePhoto}`) : defaultImg}
                                bordered
                                color="success"
                                rounded
                              />
                            </div>
                            <div className="min-w-0 flex-1">
                              <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                {item.Evaluator.Caption}
                              </p>
                              <div className="truncate text-sm text-gray-500 dark:text-gray-400 flex">
                                <MdSecurity className="text-black/60 text-base mt-1 mr-2" />{' '}
                                {item.Evaluator.Positions}
                              </div>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              <Badge
                                style={{ backgroundColor: generateBadgeColor(item.Progress) }}
                                className="w-[90px] flex flex-col"
                              >
                                {item.Progress > 50 ? "Finished" : "Not Completed"}
                                <Progress
                                  size={'sm'}
                                  progress={item.Progress}
                                  className="my-2 w-full"
                                />
                              </Badge>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
