import { Breadcrumb } from 'flowbite-react';
import type { RootState } from 'app/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const BreadcrumbSimwave = () => {
  const breadcrumbState = useSelector((state: RootState) => state.breadcrumb.value);
  const navigate = useNavigate();
  return (
    <Breadcrumb
      aria-label='Solid background breadcrumb example'
      className='bg-transparent py-3 px-5 dark:bg-transparent'
    >
      {breadcrumbState.map((item: any, index: any) => (
        <Breadcrumb.Item
          key={`breadcrumb-${index}`}
          onClick={() => {
            if (item.isCurrent !== true) navigate(item.url);
          }}
          href='#'
          icon={item.icon}
        >
          {item.text}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
