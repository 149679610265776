import { Modal } from 'flowbite-react';

export interface CusPropsType {
    children: any;
    open: boolean
    onClose: () => void
    title?: string
    extBodyClassName?: string
    size?: string
}

export const CusModal = ({ children, open, onClose, title, extBodyClassName, size }: CusPropsType) => {
    return (
        <Modal show={open} onClose={onClose} size={size ?? '5xl'}>
            <Modal.Header className='modal-header-style'>
                <p className='font-bold text-white'>
                    {title}
                </p>
            </Modal.Header>
            <Modal.Body>
                <div className={`grid grid-cols-1 gap-4 ${extBodyClassName}`}>
                    {children}
                </div>
            </Modal.Body>
        </Modal>
    )
}