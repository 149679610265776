import { useDispatch } from "react-redux";
import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { setSteps } from "components/task-steps/state/TaskStepsSlice";
import { VmProccessViewerSteps } from "common/models/TaskStepModel";
import { HideLoader, ShowLoader } from "utilities/utils";
import { TaskSteps } from "components/task-steps/TaskSteps";
import { SwitchComponent } from "components/task-steps/SwitchComponent";
import { breadcrumb } from "./breadcrumb";
import { transceiver } from "global";
import { mapToStep } from "mapperConfigs";
import { commands } from "commands";
import { SET_BREADCRUMB } from "utilities/SET_BREADCRUMB";
import CusTab from "components/CusTab/CusTab";
import { TaskbarModel } from "common/models/Taskbar";
import { ListStepsType } from "common/types/ApiTypes";

const TaskViewer = () => {
  let navigate = useNavigate();
  SET_BREADCRUMB(breadcrumb);

  const dispatch = useDispatch();
  const [body, setBody] = useState<ReactElement>();
  const [taskbars, setTaskbars] = useState<TaskbarModel[]>([]);
  let { operationId, stepName } = useParams();

  useEffect(() => {
    LoadStep();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LoadStep = () => {
    ShowLoader();
    let obj = {
      ContainerId: operationId,
      Name: stepName
    };
    transceiver.requestCommand(commands.Loadstep, obj, (result: any, state: any) => { loadStepResult(result) })
  }

  const loadStepResult = (msg: any) => {
    let ListStep: VmProccessViewerSteps = {
      steps: msg.Map.Steps.map(mapToStep)
    };

    dispatch(setSteps(ListStep));
    setBody(<SwitchComponent setBody={setBody} {...msg?.Data} type={msg?.Data?.StepName} />)
    LoadListTaskbar();
  }

  const LoadListTaskbar = (searchPhrase?: string, currentPartition?: string) => {
    let obj: Partial<ListStepsType> = {
      From: 0,
      To: -1,
      SearchPhrase: searchPhrase,
      IgnoreMinSearchLength: true,
      PartitionNames: [currentPartition ?? "Default"]
    };
    transceiver.requestCommand(commands.ListTaskbar, obj, (result: any, state: any) => { ListTaskbar(result) })
  }

  const ListTaskbar = (msg: any) => {
    let taskbarList: TaskbarModel[] = [];
    msg.Items?.forEach((item: TaskbarModel) => {
      let obj: TaskbarModel = {
        Caption: item.Caption,
        CultureStartTime: item.CultureStartTime,
        InstanceId: item.InstanceId,
        StepName: item.StepName
      }
      taskbarList.push(obj);
    });
    setTaskbars(taskbarList);
    HideLoader();

  }

  return (
    <div>
      <TaskSteps />
      {body}
    </div>
  );
};

export default TaskViewer;
