import { cloneElement, useRef, useState } from 'react';
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion } from 'framer-motion';

export interface AnimatedTooltipProps {
  content: JSX.Element;
  placement?: Placement;
  children: JSX.Element;
  isDark?: boolean;
  hasDelay?: boolean | number;
  size?: 'md' | 'lg';
}

const AnimatedTooltip = (props: AnimatedTooltipProps) => {
  const {
    children,
    content,
    placement: floatingPlacement = 'top',
    isDark = false,
    hasDelay = false,
    size = 'lg',
  } = props;

  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    placement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    placement: floatingPlacement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(10),
      flip(),
      shift({ padding: 8 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { restMs: 20 }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  const staticSide: any = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]];

  const { className: childrenClassName = '', ...childrenRestProps } =
    children.props;

  const motionProps = {
    initial: { opacity: 0, scale: 0.2 },
    animate: {
      opacity: 1,
      scale: 1,
      ...(hasDelay
        ? {
            transition: {
              delay: typeof hasDelay === 'number' ? hasDelay : 0.5,
            },
          }
        : {}),
    },
    exit: { opacity: 0, scale: 0.4 },
    transition: { type: 'tween', duration: 0.2 },
  };

  return (
    <>
      {cloneElement(
        <span>{children}</span>,
        getReferenceProps({
          ref: reference,
          className: `cursor-default ${childrenClassName}`,
          ...childrenRestProps,
        }),
      )}
      <AnimatePresence>
        {open && (
          <motion.div
            ref={floating}
            className={`${
              isDark ? 'bg-gray-600 text-white' : 'bg-white'
            } rounded-md drop-shadow-xl ${size === 'lg' ? 'p-4' : 'p-2'} z-50`}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...motionProps}
            {...getFloatingProps()}
          >
            {content}
            <div
              className={`absolute ${
                isDark ? 'bg-gray-600' : 'bg-white'
              }  shadow-lg rotate-45`}
              style={{
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                width: '10px',
                height: '10px',
                [staticSide]: '-5px',
              }}
              ref={arrowRef}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AnimatedTooltip;
