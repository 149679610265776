import { useNavigate } from 'react-router-dom';

import Button from 'components/button/Button';

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="panel-main-body bg-cover flex flex-col items-center w-full h-screen">
      <h1 className="text-5xl text-blue-500 font-semibold text-center mb-6 mt-auto">
        404: Page not found
      </h1>
      <svg
        width="280"
        height="280"
        viewBox="0 0 280 280"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="140"
          cy="140"
          r="136"
          fill="#EEF5FD"
          stroke="white"
          strokeWidth="8"
        />
        <mask
          id="mask0_4279_20272"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="8"
          width="264"
          height="264"
        >
          <circle
            cx="140"
            cy="140"
            r="128.229"
            fill="#EEF5FD"
            stroke="white"
            strokeWidth="7.54286"
          />
        </mask>
        <g mask="url(#mask0_4279_20272)">
          <path
            d="M340.834 205.667C329.251 205.667 317.667 201.75 307.501 194.667C287.167 208.917 261.167 208.917 240.834 194.667C230.667 201.75 219.084 205.667 207.501 205.667H190.834L198 214L207.501 222.334C219.001 222.334 230.334 219.417 240.834 214.084C261.834 224.834 286.501 224.834 307.501 214.084C318.001 219.5 329.334 222.334 340.834 222.334H357.501V205.667H340.834Z"
            fill="#121950"
          />
          <path
            d="M-59.334 205.667C-47.7507 205.667 -36.1673 201.75 -26.0007 194.667C-5.66732 208.917 20.3327 208.917 40.666 194.667C50.8327 201.75 62.416 205.667 73.9993 205.667H90.666L83.5 214L73.9993 222.334C62.4993 222.334 51.166 219.417 40.666 214.084C19.666 224.834 -5.00065 224.834 -26.0007 214.084C-36.5007 219.5 -47.834 222.334 -59.334 222.334H-76.0007V205.667H-59.334Z"
            fill="#121950"
          />
          <path
            d="M207.019 205.667C195.436 205.667 183.853 201.75 173.686 194.667C153.353 208.917 127.353 208.917 107.019 194.667C96.8528 201.75 85.2694 205.667 73.6861 205.667H57.0194C57.0194 205.667 58.3951 212.762 62 216.5C65.7228 220.36 73.6861 222.333 73.6861 222.333C85.1861 222.333 96.5194 219.417 107.019 214.083C128.019 224.833 152.686 224.833 173.686 214.083C184.186 219.5 195.519 222.333 207.019 222.333C207.019 222.333 213.398 218.686 218 214.083C222.602 209.481 223.686 205.667 223.686 205.667H215.353H207.019ZM73.2694 199H73.6861C87.0194 199 98.8528 191.667 107.019 182.333C115.186 191.667 127.019 199 140.353 199C153.686 199 165.519 191.667 173.686 182.333C181.853 191.667 193.686 199 207.019 199H207.436L223.186 143.333C223.853 141.167 223.686 138.833 222.686 136.833C221.686 134.833 219.853 133.333 217.686 132.667L207.019 129.167V90.6667C207.019 81.5 199.519 74 190.353 74H165.353V65C165.353 60.5817 161.771 57 157.353 57H123.353C118.934 57 115.353 60.5817 115.353 65V74H90.3528C81.1861 74 73.6861 81.5 73.6861 90.6667V129.167L62.9361 132.667C60.7694 133.333 58.9361 134.833 57.9361 136.833C56.9361 138.833 56.6861 141.167 57.4361 143.333L73.2694 199ZM90.3528 90.6667H190.353V123.75L140.353 107.333L90.3528 123.75V90.6667Z"
            fill="#121950"
          />
        </g>
      </svg>
      <Button
        className="mb-auto mt-6"
        variant="secondary"
        onClick={() => navigate('/dashboard')}
      >
        Go to Dashboard
      </Button>
    </div>
  );
};

export default ErrorPage;
