import { BreadcrumbState } from "components/breadcrumb/state/BreadcrumbSlice";
import { MdDashboard } from "react-icons/md";

export const breadcrumb: BreadcrumbState = {
    value: [
      {
        url: 'dashboard',
        text: 'Dashboard',
        icon: MdDashboard,
        isCurrent: true,
      },
    ],
  };