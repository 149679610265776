import { CusModal } from 'components/common'
import { CusPropsType } from 'components/common/CusModal'

export interface IAddUserFormProps extends CusPropsType {
    onSubmit: () => void
}

function SetCurrentBinModal({ open, onClose, children, size,onSubmit }: IAddUserFormProps) {
    return (
        <CusModal
            open={open}
            onClose={onClose}
            title='Select Organization'
            extBodyClassName='grid-rows-7'
            size={size}
        >
            {children}
        </CusModal>
    )
}

export default SetCurrentBinModal