export enum commands {
  ListSteps = 'listSteps',
  Createoperation = 'createoperation',
  Listprocess = 'listprocess',
  LoadCurrentAccount = 'LoadCurrentAccount',
  SetCurrentBin = 'setCurrentBin',
  Loadstep = 'loadstep',
  SimwaveAprraisalLoadReport = 'sim_apr_loadreport',
  SimwaveAppraisalAddFeedback = 'sim_apr_addfeedback',
  Transit = 'transit',
  SimwaveAppraisalLoadAnswerSheets = 'sim_apr_loadanswersheets',
  SimwaveAppraisalLoad = 'sim_apr_load',
  LoadInstanceParameter = 'loadinstanceparameter',
  SimwaveListEmployees = 'SIM_ListEmployees',
  SimwaveAppraisalLoadRequestTemplate = 'SIM_APR_LoadRequestTemplate',
  UpdateInstanceData = 'updateinstancedata',
  SetInstanceParameter = 'setinstanceparameter',
  SimwaveAppraisalLoadTemplates = 'sim_apr_loadtemplates',
  SimwaveAppraisalSetAnswer = 'sim_apr_setanswer',
  ListAppraisals = 'SIM_APR_ListAppraisals',
  LoadTaxonomy = 'loadtaxonomy',
  LoadTreeStructure = "loadtreestructure",
  ReadConfig = "readconfig",
  ListTaskbar = "listtaskbar",
  CloseOperationStep = "closeoperationstep",
  SimwaveAprLoadIntroduction="sim_apr_loadintroduction",
}
