import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastState {
  showToast: boolean;
  message: string;
  variant: 'success' | 'error';
}

const initialState: ToastState = {
  showToast: false,
  message: '',
  variant: 'success',
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (
      state: ToastState,
      action: PayloadAction<{ message: string; variant: 'success' | 'error' }>,
    ) => {
      state.showToast = true;
      state.message = action.payload.message;
      state.variant = action.payload.variant;
    },
    hideToast: (state) => {
      state.showToast = false;
      state.message = '';
      state.variant = 'success';
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
