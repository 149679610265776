import SIMAjax from "core/SIMAjax";
import MessageProcessor from "core/MessageProcessor";

let debugMode = true;
if (process.env.NODE_ENV === 'production')
  debugMode = false
function getDebugSocketAddress() {
  var prefixurl = "http://192.168.111.65:2021";
  new SIMAjax({
    async: false,
    url: prefixurl + "/Config/SocketIP",
    successFunction: function (d) {
      localStorage.setItem("Host", d);
      new SIMAjax({
        async: false,
        url: prefixurl + "/Config/Record/TCPEndpointPort",
        successFunction: function (d) {
          localStorage.setItem("MPort", JSON.parse(d).IntValue);
          new SIMAjax({
            async: false,
            url: prefixurl + "/Config/Record/HttpServerPort",
            successFunction: function (d) {
              localStorage.setItem("RPort", JSON.parse(d).IntValue);
            },
          });
        },
      });
    },
    errorFunction: function (xhr, a, b) {
    },
  });
}
function getPublishSocketAddress() {
  new SIMAjax({
    async: false,
    url: "/Config/SocketIP",
    successFunction: function (d) {
      localStorage.setItem("Host", d);
      new SIMAjax({
        async: false,
        url: "/Config/Record/TCPEndpointPort",
        successFunction: function (d) {
          localStorage.setItem("MPort", JSON.parse(d).IntValue);
          new SIMAjax({
            async: false,
            url: "/Config/Record/HttpServerPort",
            successFunction: function (d) {
              localStorage.setItem("RPort", JSON.parse(d).IntValue);
            },
          });
        },
      });
    },
    errorFunction: function (xhr, a, b) {
    },
  });
}
var messageProcessor = new MessageProcessor();
var globalcontext = {
  name: "ICAGlobalContext",
  currentUser: null,
  currentUserAccount: null,
  currentDate: null,
  regions: [],
  onNewRegion: null,
  regionManager: null,
  transceiver: messageProcessor,
  systemCurrentVersion: null,
  add: function (cmp) {
    if (cmp.getId() < 0)
      if (cmp.getType() === "region") {
        cmp.setId(this.regions.length + 1);
        this.regions.push(cmp);
        if (this.regionManager) this.regionManager.onNewRegionDetected(this);
      }
  },
  setProperty: function (name, value, context) {
    var items = name.split(".");
    var orgname = items.pop();
    var el = context;
    for (let i = 0; i < items.length; i++) {
      el = el[items[i]];
    }
    el[orgname] = value;
  },
  getProperty: function (name, context) {
    var items = name.split(".");
    var el = context;
    items.forEach((element) => {
      el = el[element];
    });
    return el;
  },
  getBinding: function (context, name, change) {
    var obj = {
      context: context,
      name: name,
      onChange: change,
      sgntr: "binding",
      set: function (value) {
        globalcontext.setProperty(name, value, context);
        if (obj.onChange) obj.onChange(obj);
      },
      get: function () {
        return globalcontext.getProperty(name, context);
      },
      getProperty: function (n) {
        return globalcontext.getProperty(n, context);
      },
    };
    return obj;
  },
  setCookie: function (cname, cvalue, exdays) {
    var expiretime = null;
    if (exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      expiretime = "expires=" + d.toUTCString();
    }
    if (!expiretime) document.cookie = cname + "=" + cvalue + ";path=/";
    else document.cookie = cname + "=" + cvalue + ";" + expiretime + ";path=/";
  },
  getCookie: function (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  deleteCookie: function (name) {
    document.cookie =
      "" + name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  },
  getUrl: function (path) {
    if (debugMode)
      return (
        "http://" +
        localStorage.getItem("Host") +
        ":" +
        localStorage.getItem("RPort") +
        path
      );
    else return path;
  },
  getSocketUrl: function () {
    return (
      "ws://" +
      localStorage.getItem("Host") +
      ":" +
      localStorage.getItem("MPort")
    );
  },
  getUrlWithOrigin: function (path) {
    if (debugMode)
      return (
        "http://" +
        localStorage.getItem("Host") +
        ":" +
        localStorage.getItem("RPort") +
        path
      );
    else return window.location.origin + path;
  },
  getRegistryConfig: function (key, necessaryFlag = true) {
    var cfg = null;
    new SIMAjax({
      async: false,
      url: this.getUrl("/Config/Record/" + key),
      successFunction: function (d) {
        cfg = JSON.parse(d);
      },
      errorFunction: function (xhr, a, b) {
        if (necessaryFlag) {
          throw new Error(xhr.responseText);
        } else {
          console.error(xhr.responseText)
        }
      }
    });
    return cfg;
  },
  getSocketAddress: function () {
    if (debugMode) getDebugSocketAddress();
    else getPublishSocketAddress();
  },
  getWelcomeScreen: function () {
    new SIMAjax({
      async: false,
      url: this.getUrl("/Config/WelcomeScreen"),
      successFunction: function (d) {
        localStorage.setItem("WelcomeScreen", d);
      },
    });
  },
  searchObjectInArray: function (array, objectproperty, searchphrase) {
    searchphrase = searchphrase.split(" ");
    return array.filter(function (item) {
      return searchphrase.every(function (el) {
        return item[objectproperty].indexOf(el) > -1;
      });
    });
  },
  exactsearchObjectInArray: function (array, objectproperty, searchphrase) {
    return array.filter(el => el[objectproperty] === searchphrase)
  },
  base64ToFile: function (data, filename) {
    var arr = data.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },
  printArea: function (html) {
    var printBuffer = document.createElement("div");
    printBuffer.classList.add("printable");
    printBuffer.innerHTML = html;
    document.body.appendChild(printBuffer);
    window.print();
    printBuffer.remove();
    return;
  },
  informError: function (a, b) {
  },
  informToast: function (viewmodelobj, variant, message) {
    if (!viewmodelobj)
      return;
    viewmodelobj.sendMessage("VCU@LayoutViewModel", "REQUEST", { variant: variant, message: message }, null, "informusermessage");
  },
  prepareImageURLs: function (items, array) {
    for (let index = 0; index < items.length; index++) {
      var obj = {};
      const element = items[index];
      if (element.FrontSide) {
        obj.FrontImg = this.getUrl(
          "/stream/image/" +
          element.FrontSide.Id +
          "?igVX=" +
          element.FrontSide.Version
        );
        obj.thumbnail = this.getUrl(
          "/stream/timage/" +
          element.FrontSide.Id +
          "?igVX=" +
          element.FrontSide.Version
        );
        obj.Id = element.FrontSide.Id;
        obj.FileName = element.FrontSide.Image.Name;
      }
      if (element.BackSide) {
        obj.BackImg = this.getUrl(
          "/stream/image/" +
          element.BackSide.Id +
          "?igVX=" +
          element.BackSide.Version
        );
      }
      obj.Attachments = [];
      if (element.Attachments && element.Attachments.length > 0) {
        this.prepareImageURLs(element.Attachments, obj.Attachments);
      }
      obj.Sketches = [];
      if (element.Sketches && element.Sketches.length > 0) {
        for (let i = 0; i < element.Sketches.length; i++) {
          var sk = element.Sketches[i];
          var skobj = {
            FrontImg: this.getUrl("/stream/image/" + sk.FrontSide.Id),
            thumbnail: this.getUrl("/stream/timage/" + sk.FrontSide.Id),
            Id: sk.FrontSide.Id,
          };
          obj.Sketches.push(skobj);
        }
      }
      array.push(obj);
    }
  },
  download: function (url, fileName, completeCallbackFunction) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      if (completeCallbackFunction)
        completeCallbackFunction(e);
    };
    xhr.send();
  },
  isNumeric: function (str) {
    if (typeof str == "number")
      return true;
    if (typeof str != "string")
      return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  },
  storeData: function (key, value) {
    if (!this.currentUser)
      return null;
    if (!this.currentUser.SystemName)
      if (!this.currentUser.OrganizationalCode)
        return null;
    var user = this.currentUser.SystemName;
    if (!user)
      user = this.currentUser.OrganizationalCode;
    var lobj = localStorage.getItem(user);
    if (lobj) {
      lobj = JSON.parse(lobj);
      lobj[key] = value;
    }
    else {
      lobj = {};
      lobj[key] = value;
    }
    localStorage.setItem(user, JSON.stringify(lobj))
  },
  getData: function (key) {
    if (!this.currentUser)
      return null;
    if (!this.currentUser.SystemName)
      if (!this.currentUser.OrganizationalCode)
        return null;
    var user = this.currentUser.SystemName;
    if (!user)
      user = this.currentUser.OrganizationalCode;
    var obj = localStorage.getItem(user);
    if (!obj)
      return null;
    return JSON.parse(obj)[key];
  },
  initialize: function () {
    globalcontext.getSocketAddress();
    messageProcessor.initialize(globalcontext.getSocketUrl());
  }
};
 // eslint-disable-next-line
export default { globalcontext };
