import React from 'react';

type DividerProps = {
  thickness?: number;
  horizontal?: boolean;
  vertical?: boolean;
  className?: string;
};

const Divider: React.FC<DividerProps> = ({
  thickness = 1,
  horizontal = false,
  vertical = false,
  className = '',
}) => {
  const horizontalStyles = {
    width: '100%',
    height: `${thickness}px`,
  };

  const verticalStyles = {
    width: `${thickness}px`,
    height: '100%',
  };

  return (
    <div
      className={`bg-gray-200 ${
        horizontal ? 'my' : 'mx'
      }-[${thickness}px] ${className}`}
      style={vertical ? verticalStyles : horizontalStyles}
    ></div>
  );
};

export default Divider;
