import { commands } from 'commands';
import { transceiver } from 'global';
import { ShowLoader } from 'utilities/utils';

const createOperation = (
  workflowName: string,
  operationTitle:string,
  operationName:string,
  onCreated: (result: any) => void,
  onFailed: (result: any) => void,
) => {
  ShowLoader();

  var args = {
    Workflow: workflowName,
    Title: operationTitle,
    Name: operationName,
    Priority: '0',
    PFCAFlag: true,
    InteractiveWaitFlag: true,
  };
  transceiver.requestCommand(
    commands.Createoperation,
    args,
    (result: any) => {
      onCreated(result);
    },
    (state: any) => {},
    (inform: any) => {},
    (result: any) => {
      onFailed(result);
    },
  );
};

export { createOperation };
