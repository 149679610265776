import { Badge } from 'flowbite-react';
import { useState } from 'react';
import { HiCheck, HiClock, HiFire } from 'react-icons/hi';
import { IconType } from 'react-icons/lib';

interface QuestionerBoxModel {
  title: string;
  selectable: boolean;
  summary?: string;
  icon: IconType;
  className?: string;
  status?: 'Completed' | 'Inprogress' | 'Not Started';
  progress?: number;
  selectedHandler?: Function
  reppleEffect?: boolean
}

export const Questioner = (props: QuestionerBoxModel) => {

  const [selected, setSelected] = useState<boolean>(false);

  let questionerClass = `rounded-md
    shadow
    text-center 
    p-2 
    pt-4 
    pb-4 
    border-solid
    flex 
    items-center
    text-ellipsis
    overflow-hidden
    bg-gray-50
    cursor-pointer
    transition-all 
    duration-200
    hover:bg-gray-200
    `;
 // eslint-disable-next-line
  const selectHandler = () => {
    if (props.selectable) setSelected(!selected);
  };

  return (
    <div className={`${props.className}`}>
      <div
       className={`!bg-simwave-brand shadow border-dashed !text-white/80 ${questionerClass}`}
      >
        <props.icon size={40} className={`fill-white/80 mr-3`} />
        <div className='flex-1 justify-start'>
          <div className='text-left text-sm font-semibold line-clamp-2'>
            {props.title}
          </div>
          {props.summary !== '' ? (
            <div className={`text-xs truncate text-left ${!selected ? "text-white/50" : "text-black/50"}`}>
              {props.summary}
            </div>
          ) : null}
        </div>
        {props.status === "Completed" ? <Badge color="success" icon={HiCheck}>
          Completed
        </Badge> : null}
        {props.status === "Inprogress" ? <Badge
          color="yellow"
          icon={HiClock}>
          <p>
            In progress
          </p>
        </Badge> : null}
        {props.status === "Not Started" ? <Badge
          color="pink"
          icon={HiFire}>
          <p>
            Inprogress
          </p>
        </Badge> : null}

      </div >
      </div>
  );
};
