import { useEffect, useState } from 'react';
import {
  MdChevronLeft,
  MdSearch,
  MdDashboard,
  MdOutlineLogout,
  MdMenu,
  MdClose,
  MdSecurity,
  MdAddCircle,
} from 'react-icons/md';
import { Avatar, Badge, Tooltip } from 'flowbite-react';
import Ripples from 'react-ripples';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaTasks } from 'react-icons/fa';

import SetCurrentBinModal from 'components/sideMenu/SetBinModal/SetCurrentBinModal';
import { AccessibleBinsType } from 'common/types/ApiTypes';
import { HideLoader, ShowLoader } from 'utilities/utils';
import { UserAccountModel } from 'common/models/UserAccountModel';
import { RootState } from 'app/store';
import { setOrg } from 'components/sideMenu/SetBinModal/orgSlice';
import { useResponsive } from 'common/hooks';
import { setOpenSideMenu } from 'components/sideMenu/SetBinModal/sideMenuSlice';

import { commands } from 'commands';
import { deleteCookie, getImageUrl, transceiver } from 'global';

import ProcessMenu from './ProcessMenu/ProcessMenu';

import LogoLight from 'assets/images/Logo-Light.png';
import { createOperation } from 'apicall/processApi/createOperation';

const SideMenu = () => {
  const selectedOrg = useSelector((state: RootState) => state.organization);
  const dispatch = useDispatch();
  const location = useLocation();
  const winWidth = useResponsive();

  const isDesktopSize = winWidth >= 768;

  const [sideOpen, setSideOpen] = useState<boolean>();
  const [account, setAccount] = useState<UserAccountModel>();
  const [accessibleBins, setAccessibleBins] = useState<AccessibleBinsType[]>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedBin, setSelectedBin] = useState<string>();
  const [stepCount, setStepCount] = useState<number>();

  const [openProcesses, setOpenProcesses] = useState<boolean>(false);
  const [createAppraisalView,setCreateAppraisalView] = useState<boolean>(false);
  
  const LoadCountSteps = () => {
    let obj = {
      To: -1,
      PrivateFlag: true,
    };
    transceiver.requestCommand(
      commands.ListSteps,
      obj,
      (result: any, state: any) => {
        StepCount(result);
      },
    );
  };

  const StepCount = (msg: any) => {
    setStepCount(msg.Total);
    HideLoader();
  };

  useEffect(() => {
    if (isDesktopSize) {
      setSideOpen(true);
    } else {
      setSideOpen(false);
    }
  }, [isDesktopSize]);

  useEffect(() => {
    dispatch(setOpenSideMenu(sideOpen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideOpen]);

  useEffect(() => {
    LoadCountSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const loadCurrentAccount = () => {
      transceiver.requestCommand(
        commands.LoadCurrentAccount,
        {},
        (result: any, state: any) => {
          LoadCurrentAccount(result);
        },
      );
    };

    const LoadCurrentAccount = (msg: any) => {
      //console.log("current user msg",msg);
      
      let account: UserAccountModel = {
        UserName: msg.Title ?? msg.SystemName,
        position: msg.Position || '',
        ProfilePhoto: msg.ProfilePhoto,
        Tickets:msg.Tickets
      };

      if(account.Tickets.findIndex(x=>x.toLowerCase()==="green" || x.toLowerCase()==="createappraisal@interactive")>=0)
      {
        setCreateAppraisalView(true);
      }

      setAccount(account);
      setAccessibleBins(msg.AccessibleBins);
      
      dispatch(setOrg(msg.DefaultBin));
      LoadCountSteps();
    };

    ShowLoader();
    loadCurrentAccount();
  }, [selectedOrg, transceiver, selectedBin]); // eslint-disable-line

  const setCurrentBin = (binId: string) => {
    if (binId) {
      ShowLoader();
      let obj = {
        BinId: binId,
      };
      transceiver.requestCommand(
        commands.SetCurrentBin,
        obj,
        (result: any, state: any) => {
          //console.log('selected bin result', result);
          setCurrentBinResult();
        },
      );
      dispatch(setOrg(binId));
      setOpenModal(false);
    }
  };

  const setCurrentBinResult = () => {
    HideLoader();
    setOpenModal(false);
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton:
        'bg-orange-500 text-white hover:bg-orange-700 focus:ring-orange-300 active:bg-orange-800 disabled:bg-orange-300 shadow-sm rounded-md p-2 ml-3',
      cancelButton:
        'bg-white !text-indigo-900 rounded-md !border-indigo-900 p-2 border hover:bg-gray-100 mr-3',
    },
    buttonsStyling: false,
  });

  const logOut = () => {
    swalWithBootstrapButtons
      .fire({
        title: 'Confirm Logout',
        text: 'Are you sure to logout from Simwave Academy?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, logout',
        cancelButtonText: 'No, cancel',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          transceiver.requestMessage(
            '*',
            {},
            'SignOut@Master',
            (result: any, state: any) => {
              handleLogout(result);
            },
          );
        }
      });
  };

  const handleLogout = (msg: any) => {
    if (msg.Performative.toLowerCase() === 'agree') {
      deleteCookie('Token');
      deleteCookie('GAMTKNWEBSELFHOST');
    }
    navigate('/login');
  };

  const navigate = useNavigate();

  const createAppraisal = () =>{
    createOperation("CreateAppraisal","Appraisal creation","appraisal",(result)=>{
      navigate(`/task-viewer/${result.Step.OperationId}/${result.Step.FullName}`);
    },(result)=>{

    })
  }
  const Menus = [
    {
      title: 'Dashboard',
      icon: <MdDashboard />,
      onClick: () => navigate('/dashboard'),
    },
    {
      title: 'Task List',
      onClick: () => navigate('/task-list'),
      icon: <FaTasks />,
      spacing: false,
      badge: (
        <Badge
          color="pink"
          size="sm"
          className="mr-2 absolute text-[10px] -top-3 -left-1 font-bold rounded-full w-[20px] h-[20px] p-0 text-center flex justify-center"
        >
          {stepCount || 0}
        </Badge>
      ),
    },

    {
      title: 'Create Appraisal',
      onClick: () => createAppraisal(),
      icon: <FaTasks />,
      spacing: true
    },

    {
      title: 'Appraisal Archive',
      onClick: () => navigate('/appraisal-archive'),
      icon: <FaTasks />,
      spacing: false
    },

    {
      title: 'Logout',
      spacing: true,
      icon: <MdOutlineLogout />,
      onClick: logOut,
    },
  ];

  const setBin = (binId: string) => {
    ShowLoader();
    setSelectedBin(binId);
    setCurrentBin(binId);
  };

  return (
    <>
      <div
        className={`block md:hidden bg-gradient-to-br
            from-simwave-brand
            to-simwave-brand-light p-2
            fixed
            w-full
            z-50
            shadow
            duration-200 
            text-white 
            bg-simwave-bg
            justify-between
            top-0
            h-14
            cursor-pointer
            `}
      >
        <div className="flex w-full h-full justify-between items-center ">
          <MdMenu className="text-4xl" onClick={() => setSideOpen(!sideOpen)} />
          <img src={LogoLight} className="h-[32px]" alt="logo" />
        </div>
      </div>
      <div
        className={`bg-gradient-to-br
        from-simwave-brand
        to-simwave-brand-light 
        bg-cover h-screen 
        text-white 
        shadow-2xl 
        bg-simwave-bg
        p-4 
        md:pt-8
        absolute
        z-50
        md:relative 
        transition-all
        duration-200
        w-full
        ${
          sideOpen
            ? 'left-0 overflow-y-auto md:w-96'
            : '-left-full md:w-20 md:left-0'
        }`}
      >
        <div
          className={` 
        md:absolute 
        overflow-ellipsis 
        overflow-y-auto 
        overflow-x-hidden
        w-full top-0 
        left-0 
        z-20 
        px-4 
        md:pt-8`}
        >
          <MdChevronLeft
            className={`cursor-pointer
             bg-white 
             text-simwave-brand 
             text-4xl 
             rounded 
             absolute 
             hidden md:block
             duration-200
             ${
               sideOpen
                 ? 'right-6 md:right-4'
                 : 'right-0 md:-right-6 md:hover:-right-2'
             } 
             shadow 
             border 
             top-8
             ${!sideOpen && 'rotate-180'}`}
            onClick={() => setSideOpen(!sideOpen)}
          />
          <div className="inline-flex mt-12">
            <img
              src={LogoLight}
              className={`duration-200 ${sideOpen ? 'w-1/2' : 'w-full'}`}
              alt="logo"
            />
            <MdClose
              className={`cursor-pointer
             text-white
             text-4xl
             absolute 
             block md:hidden
             right-4
             shadow
             top-3
             ${!sideOpen && 'rotate-180'}`}
              onClick={() => setSideOpen(!sideOpen)}
            />
          </div>
          {sideOpen ? (
            <>
              <div className="flex flex-col items-center justify-items-start bg-white/10 p-4 rounded-md mt-16">
                <Avatar
                  img={getImageUrl(`/stream/Image/${account?.ProfilePhoto}`)}
                  bordered={true}
                  size="lg"
                  className="-mt-14"
                  rounded
                />
                <div className="px-4 pt-2 b">
                  <div className="text-2xl text-center">
                    {account?.UserName}
                  </div>
                  <div className="text-sm flex pt-1 justify-center mt-2">
                    {account?.position === '' ? null : (
                      <MdSecurity className="text-white text-base mt-1 mr-2" />
                    )}{' '}
                    {account?.position}
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => setOpenModal(true)}
                  className="text-base w-full focus:border-transparent focus:ring-0 pl-1 bg-white/40 rounded-md mt-3 py-2 flex items-center justify-center"
                >
                  {selectedOrg.name}
                </button>
              </div>
            </>
          ) : (
            <div className="pt-4">
              <Tooltip content="Mike Json" placement="right">
                <Avatar
                  img={getImageUrl(`/stream/Image/${account?.ProfilePhoto}`)}
                  rounded={true}
                  bordered={true}
                  size="sm"
                  className="ml-2"
                />
              </Tooltip>
            </div>
          )}

          <div
            className={`flex items-center justify-center text-lg rounded-md bg-white/40 mt-6 ${
              !sideOpen ? 'px-2 py-2' : 'px-2.5'
            }`}
          >
            <MdSearch
              onClick={() => setSideOpen(true)}
              className="text-2xl cursor-pointer"
            />
            <input
              type="search"
              placeholder="Search"
              className={`text-base bg-transparent w-full text-white focus:border-transparent focus:ring-0 pl-1 placeholder-white/40 ${
                !sideOpen && 'hidden'
              } border-0`}
            />
          </div>

          <div>
            <ul className="pt-2 pb-2">
              {Menus.map((menu, index) => (
                ((menu.title === 'Create Appraisal' && !createAppraisalView))?<></>:
                <>
                  <div
                    onClick={menu.onClick}
                    key={`main-menu-${index}`}
                    className={`text-gray-300 text-sm 
                      flex items-center gap-x-4 cursor-pointer p-2 hover:bg-white/20 rounded mt-2 ${
                        menu.spacing ? 'mt-9' : 'mt-2'
                      }`}
                  >
                    <span className="text-2xl pl-1 flex relative">
                      {menu.badge ? menu.badge : null}
                      {menu.icon}
                    </span>
                    <span
                      className={`text-base font-medium select-none flex-1 ${
                        !sideOpen && 'hidden'
                      }`}
                    >
                      <div className="flex items-center relative justify-between">
                        <p>{menu.title}</p>
                      </div>
                    </span>
                  </div>
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <SetCurrentBinModal
        size="md"
        open={openModal}
        onSubmit={() => {}}
        title="Select Organization"
        onClose={() => setOpenModal(false)}
      >
        {accessibleBins?.map((item, index) => (
          <Ripples
            className="w-1/2 mx-auto"
            during={1000}
            key={`side-menu-bin-key-${index}`}
          >
            <button
              type="button"
              onClick={() => {
                setBin(item.Id);
              }}
              className={`w-full border-none transition-all duration-500 rounded border border-simwave-bg px-6 pb-2 pt-2.5 text-sm font-medium ${
                selectedOrg.name === item.Id
                  ? 'bg-simwave-bg text-white'
                  : 'hover:text-white hover:bg-simwave-brand'
              }`}
            >
              {item.Caption}
            </button>
          </Ripples>
        ))}
      </SetCurrentBinModal>
    </>
  );
};
export default SideMenu;
