import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface sideMenuSliceType {
    open?: boolean
}

const initialState: sideMenuSliceType = {
    open: undefined
}

export const OpenSideMenu = createSlice({
    name: 'setOpenSideMenu',
    initialState,
    reducers: {
        setOpenSideMenu: (state, action: PayloadAction<boolean | undefined>) => {
            state.open = action.payload;
        }
    },
})

export const { setOpenSideMenu } = OpenSideMenu.actions

export default OpenSideMenu.reducer