import AppraisalGrid from 'pages/task-viewer/appraisal/AppraisalGrid'
import React from 'react'

interface props {
    id: string
}

const ReportTab: React.FC<props> = ({ id }) => {
    return (
        <div className='archive-modal-contents'>
            <AppraisalGrid AppraisalId={id} IsArchive={true} />
        </div>
    )
}

export default ReportTab