import { TaskType } from 'common/models/TaskType';
import { TaskStepModel } from 'common/models/TaskStepModel';
import { TaskbarModel } from 'common/models/Taskbar';

export const mapToTask = (item: any) => {
  let result: TaskType = {
    BackFlag: item.BackFlag,
    CDT: item.CultureCDT,
    NextFlag: item.NextFlag,
    OperationDescription: item.OperationDescription,
    OperationId: item.OperationId,
    OperationName: item.OperationName,
    OperationTitle: item.OperationTitle,
    RelatedPortfolioId: item.RelatedPortfolioId,
    StepName: item.FullName,
    Name: item.Name,
    Deadline: item.Deadline,
    Tags: item.Tags,
    Progress: item.Portfolio?.Progress || 0,
    Properties: item.Portfolio?.Properties,
    Interactors: item.Portfolio?.Interactors,
    Title: item.Title,
  };
  return result;
};

export const mapToStep = (item: any) => {
  let result: TaskStepModel = {
    Name: item.Name,
    Index: item.Index,
    Title: item.Title,
    CurrentFlag: item.CurrentFlag,
  };
  return result;
};

export const mapToPartitions = (item: any) => {
  let result: any = {
    value: item.Name,
    label: item.Caption
      ? `${item.Caption} (${item.Count})`
      : `${item.Description} (${item.Count})`,
    ProfilePhoto: '',
  };
  return result;
};

export const mapProcessList = (item: any) => {
  let result: any = {
    title: item.Name,
    caption: item.Title,
    icon: item.Icon
  }
  return result
}
export const mapToTreeStructure = (data: any) => {
  const convertedData = [
    {
      key: data.Key,
      label: data.Data.Caption,
      index: data.Key,
      id: data.Id,
      nodes: data?.Children?.map((item: any, i: number) => ({
        key: `${item.Key}-${i}`,
        label: item.Data.Caption,
        index: `${item.Key}-${i}`,
        id: item.Id,
        nodes: item?.Children?.map((sub: any, i: number) => ({
          key: `${sub.Key}-${i}`,
          label: sub.Name,
          index: `${sub.Key}-${i}`,
          id: sub.Id,
          nodes: sub?.Children?.map((subsub: any, i: number) => ({
            key: `${subsub.Key}-${i}`,
            label: subsub.Name,
            index: `${subsub.Key}-${i}`,
            id: subsub.Id,
          }))
        }))
      }))
    }
  ]

  return convertedData
}

export const mapToTaskBar = (msg: any) => {
  let taskbarList: TaskbarModel[] = [];
  msg.Items?.forEach((item: TaskbarModel) => {
    let obj: TaskbarModel = {
      Caption: item.Caption,
      CultureStartTime: item.CultureStartTime,
      InstanceId: item.InstanceId,
      StepName: item.StepName
    }
    taskbarList.push(obj);
  });
  return taskbarList
}