import { motion } from 'framer-motion'
import { Avatar, Tooltip } from 'flowbite-react'
import { UserLink } from 'common/models'
import { getImageUrl } from 'global'

interface props {
    item: UserLink
}

const HoverableAvatar = ({ item }: props) => {
    return (
        <Tooltip content={item.Caption} className='w-36 text-center'>
            <motion.div
                className='relative group flex justify-start'
                initial={{ width: '50px', marginRight: '-20px' }}
                whileHover={{ x: '-15px' }}
                transition={{ duration: 0.2 }}
            >
                <Avatar
                    className=''
                    size='sm'
                    img={getImageUrl(`/stream/Image/${item.ProfilePhoto}`)}
                    rounded
                    stacked
                />
            </motion.div>
        </Tooltip>
    )
}

export default HoverableAvatar