import React from 'react'

interface props {
    wrapperClassName: string
    iconNode: React.ReactNode
    labelNode: React.ReactNode
    inputNode: React.ReactNode
}

const InputWithLabel = ({ iconNode, inputNode, labelNode, wrapperClassName }: Partial<props>) => {
    return (
        <div className={`h-fit ${wrapperClassName} max-sm:w-full max-sm:basis-full`}>
            <div className='flex items-center gap-x-2 mb-1'>
                {iconNode}
                {labelNode}
            </div>
            {inputNode}
        </div>
    )
}

export { InputWithLabel }