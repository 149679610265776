import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import Dashboard from 'pages/dashboard/Dashboard';
import Login from 'pages/login';
import PageNotFound from 'pages/page-not-found/ErrorPage';
import Layout from 'components/Layout';
import RequireAuth from 'features/auth/RequireAuth';
import TaskList from 'pages/taskList/TaskList';
import TaskViewer from 'pages/task-viewer/TaskViewer';
import ICAContext from 'core/ICAContext';
import Waiting from 'pages/waiting/waiting';

import 'swiper/css';
import 'styles/App.css';
import "react-datepicker/dist/react-datepicker.css";
import 'styles/App.css';
import ArchiveAppraisal from 'pages/archive-appraisal/ArchiveAppraisal';

const App = () => {
  let navigate = useNavigate();
  const location = useLocation()
  ICAContext.globalcontext.transceiver.OnReadyHandler = () => {
    navigate(location.state?.from.pathname || "/task-list");
  }
  ICAContext.globalcontext.transceiver.OnRequireAuthenticationHandler = () => {
    navigate("/login");
  }
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/waiting" element={<Waiting />} />
      <Route element={<RequireAuth />}>
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/task-list" element={<TaskList />} />
          <Route
            path="/task-viewer/:operationId/:stepName"
            element={<TaskViewer />}
          />
          <Route path="/appraisal-archive" element={<ArchiveAppraisal />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
};

export default App;
