import { useEffect, useState } from 'react';

import {
  colEndClassNames,
  colSpanClassNames,
  colStartClassNames,
  rowEndClassNames,
  rowStartClassNames,
} from './gridClassNames';
import AnimatedTooltip from '../AnimatedTooltip';
import { ReportGridData } from 'common/models/ReportGridData';
import { CellData } from 'common/models/CellData';
import { Cell } from 'common/models/Cell';

import { transceiver } from 'global';
import { commands } from 'commands';
import { HideLoader, ShowLoader } from 'utilities/utils';
import { GradeWrapper } from 'pages/task-viewer/helpers/AppraisalGridHelpers';
import { MdCheck, MdCheckBox, MdWarning } from 'react-icons/md';

interface CellContentProps extends React.HTMLAttributes<HTMLDivElement> {
  cellData: CellData;
}

const renderCellContent = (cell:CellData):JSX.Element =>{
  let res:JSX.Element = <></>;
  console.log("gridCell",cell);
  switch (cell.Browser) {
    case "GaugeValueBrowser":{
      if(cell.Properties!==undefined && cell.Properties!==null && cell.Properties.length!==undefined && cell.Properties.length > 0)
        res = <div className='w-full text-center'>
                <GradeWrapper grade={{ earned: cell.Properties.filter((item) => item.Key === 'Value')[0].Value, max: cell.Properties.filter((item) => item.Key === 'MaxValue')[0].Value,Text:cell.Description }} />
              </div>;
      }
      break;

    case "ANSCNFBrowser":
      {res = <div className='w-full flex justify-center'><MdWarning className='text-yellow-300' size={32} /></div>;}
      break;

    case "ANSACCBrowser":
      {res = <div className='w-full flex justify-center'><MdCheckBox size={32} className='text-green-400' /></div>;}
      break;

    default:
      res = <div>
      {cell.Caption && <p className={`${((cell.Caption??'').length>20)?'text-left':'text-center'}`}>{cell.Caption}</p>}
      {cell.Description && (
        <p className="text-xs">{cell.Description}</p>
      )}
    </div>
      break;
  }
  return res;
}
const CellContent = (props: CellContentProps) => {
  const { cellData } = props;
  const content = (
    <div className="overflow-hidden flex-grow py-2">
      <div>
        {renderCellContent(cellData)}
      </div>
    </div>
  );

  return cellData.Hint ? (
    <AnimatedTooltip content={<p>{cellData.Hint}</p>}>
      {content}
    </AnimatedTooltip>
  ) : (
    <>{content}</>
  );
};

interface GridCellProps extends React.HTMLAttributes<HTMLDivElement> {
  cell: Cell;
}

const GridCell = (props: GridCellProps) => {
  const { cell } = props;

  const {
    ColumnIndex: columnIndex,
    RowIndex: rowIndex,
    ColSpan: colSpan,
    RowSpan: rowSpan,
    Data: data,
  } = cell;
  const startColumn = columnIndex;
  const endColumn = startColumn + colSpan + 1;
  const startRow = rowIndex;
  const endRow = startRow + rowSpan + 1;
  const locationClassName = `${colStartClassNames[startColumn]} ${colEndClassNames[endColumn]} ${rowStartClassNames[startRow]} ${rowEndClassNames[endRow]}`;

  let content = null;
  if (data) {
    content = <CellContent cellData={data} />;
  }

  return (
    <div
      className={`${locationClassName} flex justify-center items-center w-full bg-gradient-to-br from-slate-200/50 from-10% via-slate-100/40 via-70% to-slate-100/30 shadow-md rounded-md p-2`}
      key={`C${columnIndex}R${rowIndex}`}
    >
      {content}
    </div>
  );
};

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  data: ReportGridData;
  equalWidths?: boolean;
}

const Grid = (props: any) => {
  const AppraisalId = props.AppraisalId;
  const [reportData, setReportData] = useState<ReportGridData>({ Cells: [], ColumnCount: 0, RowCount: 0 });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusData, setStatusData] = useState<any>()

  const loadReport = () => {
    ShowLoader();
    let obj = {
      AppraisalId
    }
    transceiver.requestCommand(commands.SimwaveAprraisalLoadReport, obj, (result: any, state: any) => loadReportResult(result))
  }

  const loadReportResult = (result: any) => {
    let obj: ReportGridData = { ColumnCount: result.OverviewMatrix.ColumnCount, RowCount: result.OverviewMatrix.RowCount, Cells: [] };
    result.OverviewMatrix.Cells.forEach((item: any) => {
      obj.Cells.push({
        ColSpan: item.ColSpan,
        ColumnIndex: item.ColumnIndex,
        Data: item.Data,
        RowIndex: item.RowIndex,
        RowSpan: item.RowSpan
      })
    })
    
    setReportData(obj);
    setStatusData(result.Status);
    HideLoader();
  }

  useEffect(() => {
    if (AppraisalId) {
      loadReport()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppraisalId])

  return (
    <>
      <div
        className={`grid gap-1 ${colSpanClassNames[reportData?.ColumnCount - 1]}`}
      >
        {reportData?.Cells.map((cell) => (
          <GridCell key={`C${cell.ColumnIndex}R${cell.RowIndex}`} cell={cell} />
        ))}
      </div>
    </>
  );
};

export default Grid;
