import { Badge, Table, TableProps, Tooltip } from 'flowbite-react'
import React from 'react'
import { TiTick } from 'react-icons/ti'

import defaultImg from '../../../assets/images/default.png'
import { MdCancel } from 'react-icons/md'
import { getImageUrl } from 'global'

export type RowCellType = { Key: string, Value: string | any[] }

interface props extends TableProps {
    headCells?: string[]
    rowCells?: RowCellType[]
}

const TableRowCell = ({ row }: { row: RowCellType }) => {
    const { Key, Value } = row;

    const valueRenderer = (v: string | any) => {
        if (typeof v === 'boolean') {
            return v ? <TiTick className='scale-[2] fill-lime-500' /> : <MdCancel className='scale-150 fill-red-600' />
        }
        if (typeof v === 'string' || typeof v === 'number') {
            return <p>{v}</p>
        }
        if (typeof v === 'undefined') {
            return <p>-----</p>
        }

        if (typeof v === 'object' && v !== null) {
            return v.map((item: any) => (
                <Tooltip content={item?.Key} className={`w-20 ${Key === 'Properties' ? '' : 'hidden'}`}>
                    <div className=''>
                        <Badge color='info' className={`px-2 border shadow-md border-sky-200 ${item.ProfilePhoto ? 'w-32' : ''}`}>
                            <div className='flex items-center gap-x-2'>
                                {item.ProfilePhoto && (
                                    <img
                                        alt=''
                                        className='w-6 aspect-square rounded-full'
                                        src={item.ProfilePhoto === '' ? defaultImg : getImageUrl(`/stream/Image/${item.ProfilePhoto}`)}
                                    />
                                )}
                                <p className='line-clamp-1'>{item?.Caption ?? item?.Value}</p>
                            </div>
                        </Badge>
                    </div>
                </Tooltip>
            ))
        }

        return <div>asd</div>
    }


    return (
        <Table.Row className='even:bg-slate-100/75 odd:bg-slate-50/75 hover:bg-slate-100 select-none'>
            <Table.Cell>{Key}</Table.Cell>
            <Table.Cell className='flex items-center gap-x-2 w-[300px] overflow-x-scroll'>
                {valueRenderer(Value)}
            </Table.Cell>
        </Table.Row>
    )
}

const CusTable = (props: props) => {

    const { headCells, rowCells } = props

    return (
        <div className='h-[70vh]'>
            <Table {...props}>
                <Table.Head className='bg-slate-300/50'>
                    {headCells?.map((cell, index) => (<Table.Cell key={index}>{cell}</Table.Cell>))}
                </Table.Head>
                <Table.Body className='overflow-y-scroll'>
                    {rowCells?.map((row, index) => (
                        <TableRowCell key={`RowCell-${index}`} row={row} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

export { CusTable }