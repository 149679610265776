import {BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery,createApi} from "@reduxjs/toolkit/query/react"
import { setCredentials, logOut } from 'features/auth/authSlice'
import { RootState } from "app/store";

const baseQuery = fetchBaseQuery({
    baseUrl : "",
    credentials:'include',
    prepareHeaders:(headers, {getState}) => {
        const token = (getState() as RootState).auth.token;
        if(token)
            headers.set("Authorization",`Bearer ${token}`);
        return headers;
    },
})

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result = await baseQuery(args, api, extraOptions);

    if(result?.error?.status===403)
    {
        // send refresh token to get new access token 
        const refreshResult = await baseQuery('/refresh', api, extraOptions)
        if (refreshResult?.data) {
            const user = (api.getState() as RootState).auth.user
            // store the new token 
            api.dispatch(setCredentials({ ...refreshResult.data, user }))
            // retry the original query with new access token 
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(logOut({}))
        }
    }
    
    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})