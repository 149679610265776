
import { MdDashboard, MdEco, MdHideSource, MdQuickreply } from 'react-icons/md';

export const IconMapper = (name: any, className?: string, size?: number) => {
  if (size === undefined) size = 40;
  let Output;
  switch (name) {
    case "leadership":
      Output = <MdEco size={size} className={`mr-2 opacity-40 min-w-[40px] ${className}`} />;
      break;

    case "risk":
      Output = <MdHideSource size={size} className={`mr-2 opacity-40 min-w-[40px] ${className}`} />;
      break;
    case "<MdDashboard/>":
      Output = <MdDashboard size={size} className={`mr-2 opacity-40 min-w-[40px] ${className}`} />;
      break;

    default:
      return <MdQuickreply size={size} className={`mr-2 opacity-40 min-w-[40px] ${className}`} />;
  }
  return Output;
};