import { BreadcrumbState } from "components/breadcrumb/state/BreadcrumbSlice";
import { FaTasks } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";

export const breadcrumb:BreadcrumbState = {
    value: [
        {
          url: "dashboard",
          text: "Dashboard",
          icon: MdDashboard,
          isCurrent: false,
        },
        {
          url: "task-list",
          text: "Task List",
          icon: FaTasks,
          isCurrent: false,
        },
        {
          url: "",
          text: "Quiz Review",
          isCurrent: true,
        },
      ],
  };