import { Loading } from 'components/Loading/Loading';

import LoaderSimwave from 'assets/images/loader-simwave.gif'
const GlowImage = () => {
    return (
        <div className="flex flex-col justify-center items-center bg-simwave-bg w-full h-full">
            <img src={LoaderSimwave} className="w-1/4 aspect-video" alt="" />
            <Loading />
        </div>
    );
};

export default GlowImage;
