import { MdOutlineSearch, } from "react-icons/md";
import { Badge, Card, Modal, Pagination, TextInput } from "flowbite-react";
import Select from 'react-select';
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { TaskType } from "common/models/TaskType";
import { HideLoader, ShowLoader } from "utilities/utils";
import { SelectInputModel } from "common/models/SelectInputModel";
import { ListStepsType } from "common/types/ApiTypes";
import TaskListItem from "./TaskListItem";
import { CusMenuItem } from "components/select";
import Button from "components/button/Button";
import { CusTable } from "components/common";
import { RootState } from "app/store";
import { TaskCard } from "./helpers/TaskListHelpers";
import { useResponsive } from "common/hooks";
import { GoNumber } from "react-icons/go";
import { breadcrumb } from "./breadcrumb";
import { PageSize, transceiver } from "global";
import { mapToPartitions, mapToTask } from "mapperConfigs";
import { commands } from "commands";
import { SET_BREADCRUMB } from "utilities/SET_BREADCRUMB";

const TaskList = () => {
  SET_BREADCRUMB(breadcrumb);

  const selectedOrg = useSelector((state: RootState) => state.organization)
  const [searchParams, setSearchParams] = useSearchParams();
  const winWidth = useResponsive();

  const isDesktop = winWidth >= 1024

  const [tasks, setTasks] = useState<TaskType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [partitions, setPartitions] = useState<SelectInputModel[]>([]);
  const [selectedPartition, setSelectedPartition] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.get('page')) || 1);
  const [totalItems, setTotalItems] = useState<number>()
  const [selectedItem, setSelectedItem] = useState<any>()

  const listSteps = (msg: any, state: number) => {
    HideLoader();
    let taskList: TaskType[] = msg.Items.map(mapToTask);

    const totalPage = Math.ceil(msg.Total / PageSize)
    setTasks(taskList);
    setTotal(totalPage);
    setTotalItems(msg.Total)
    LoadPartitions(msg.Partitions);
  };

  useEffect(() => {
    LoadListSteps();
  }, [currentPage, selectedOrg, selectedPartition]);// eslint-disable-line

  const LoadListSteps = (searchPhrase?: string) => {
    ShowLoader();
    let obj: Partial<ListStepsType> = {
      From: (currentPage - 1) * PageSize,
      To: currentPage * PageSize,
      SearchPhrase: searchPhrase,
      IgnoreMinSearchLength: true,
      PartitionNames: [selectedPartition ?? "Default"]
    };
    transceiver.requestCommand(commands.ListSteps, obj, (result: any, state: any) => { listSteps(result, state) })
  };

// eslint-disable-line

  const LoadPartitions = (Partitions: any) => {
    let partitionList: SelectInputModel[] = Partitions.map(mapToPartitions);
    setPartitions(partitionList);
    HideLoader();
  }

  const openInfoModal = (task: any) => {
    let favdt = [];
    for (let key in task) {
      if (Object.hasOwnProperty.call(task, key))
        favdt.push({ Key: key, Value: task[key] });
    }
    setSelectedItem(favdt)
  }

  const closeInfoModal = () => {
    setSelectedItem(undefined)
  }

  const partitionChangeHandler = (e: any) => {
    setCurrentPage(1);
    setSearchParams({ page: "1" });
    setSelectedPartition(e?.value);
  }

  return isDesktop ? (
    <Card className="bg-white/60 min-h-[calc(100vh-100px)]">
      <div className="h-full flex flex-col justify-between">
        <div className="flex-grow">
          <div className="mb-2 flex items-center space-x-4" style={{ justifyContent: "end" }}>
            <div style={{ width: "19%" }}>
              <Select
                name="opertions"
                onChange={(e: any) => {
                  partitionChangeHandler(e)
                }}
                components={{ Option: CusMenuItem }}
                options={partitions}
                styles={{
                  control: (base: any, props: any) => ({
                    ...base,
                    padding: '1.5px',
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    borderRadius: '0.5rem',
                    backgroundColor: 'rgb(249 250 251 / 1)',
                    borderColor: 'rgb(209 213 219 / 1)'
                  }),
                }}
              />
            </div>
            <div style={{ width: "36%" }}>
              <TextInput
                icon={MdOutlineSearch}
                id="txtSearch"
                placeholder="Search Task"
                required
                type="text"
                onChange={(e) => LoadListSteps(e.target.value)}
              />
            </div>
            <Badge color="gray" icon={GoNumber} size="sm" style={{ position: 'absolute', left: 9 }}>
              Count All : {totalItems}
            </Badge>
          </div>
          <div className="h-full">
            <ul className="divide-y divide-gray-300 dark:divide-gray-700 h-[95%]">
              {
                tasks?.map((item, index) => (
                  <TaskListItem
                    key={`task-${index}`}
                    item={item}
                    infoModalHandler={() => openInfoModal(item)}
                  />
                ))
              }
              <Pagination
                className="flex flex-row justify-center"
                currentPage={currentPage}
                layout="pagination"
                onPageChange={(page) => {
                  setCurrentPage(page);
                  setSearchParams({ page: page.toString() });
                }}
                showIcons
                totalPages={total}
                previousLabel="Prev"
              />
            </ul>
          </div>
        </div>
      </div>
      <Modal size="xl" show={selectedItem ? true : false} onClose={closeInfoModal} title={`Task Information`}>
        <Modal.Header className="modal-header-style">
          <p className="text-white">Task Information</p>
        </Modal.Header>
        <Modal.Body>
          {selectedItem && (
            <CusTable
              className="rounded-xl overflow-hidden"
              rowCells={selectedItem}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end">
          <Button
            onClick={closeInfoModal}
            variant="primary"
            className="rounded-lg"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Card >
  ) : (
    <Card className="bg-white/60 min-h-[calc(100vh-125px)]">
      <div className="mb-4 flex items-center justify-between">
        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
          Task List
        </h5>
        <TextInput
          icon={MdOutlineSearch}
          placeholder="Search Task"
          className="w-40"
        />
      </div>
      <div className="grid grid-cols-1 gap-4">
        {tasks?.map((task) => (
          <TaskCard key={task.Name} task={task} />
        ))}
      </div>
    </Card>
  )
};
export default TaskList;
