
function SIMAjax (obj) {
    if (obj) {
        this.data = obj.data ? obj.data : null;
        this.url = obj.url ? obj.url : null;
        this.method = obj.method ? obj.method : "GET";
        this.async = obj.async;
        this.contentType = obj.contentType ? obj.contentType : "application/x-www-form-urlencoded";
        this.errorfunction = obj.errorFunction ? obj.errorFunction : null;
        this.successfunction = obj.successFunction ? obj.successFunction : null;
        this.onStateChange = e => {
            e = e.currentTarget;
            if (e.readyState !== 4)
                return;
            if (e.status === 200) {
                if (this.successfunction)
                    this.successfunction(xhttp.responseText);
            }
            else {
                if (this.errorfunction)
                    this.errorfunction(xhttp);
            }
        }
        if (this.url !== null) {
            var xhttp = new XMLHttpRequest();
            xhttp.open(this.method.toUpperCase(), this.url + '?_=' + new Date().getTime(), this.async);
            if (this.method.toLowerCase() === "post")
                xhttp.setRequestHeader("Content-Type", this.contentType);
            xhttp.onreadystatechange = this.onStateChange;
            if (this.method.toLowerCase() === "post")
                xhttp.send(this.data);
            else
                xhttp.send();
        }
    }
}

export default SIMAjax;