import moment from 'moment';
import { Avatar, Badge, Progress } from 'flowbite-react';
import { useNavigate } from 'react-router';

import { TaskType } from 'common/models/TaskType';
import { MdLockOpen, MdLockOutline } from 'react-icons/md';
import { HoverableAvatar } from 'components/common';
import { generateBadgeColor, generateProggressColor } from 'utilities';

interface TaskCardProps {
  task: TaskType;
}

export const TaskCard: React.FC<TaskCardProps> = ({ task }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => { (task.ExpiredFlag || task.LockFlag) ? navigate(``) : navigate(`/task-viewer/${task?.OperationId}/${task?.StepName}`) }}
      className={`bg-white shadow rounded-lg my-2 py-1 transition-all duration-200 overflow-hidden hover:${(task.ExpiredFlag || task.LockFlag) ? "cursor-not-allowed" : "bg-gray-100 cursor-pointer"} `}
    >
      <div className="px-2 pt-2 flex items-center gap-2 justify-between">
        <div className="flex gap-2">
          <div>
            {/* <TaskIcon className="h-6 w-6 text-simwave-brand" /> */}
            <div>
              {(task.ExpiredFlag || task.LockFlag)
                ?
                <MdLockOutline className="fill-red-500" size={24} />
                :
                <MdLockOpen className="fill-green-500" size={24} />
              }
            </div>
          </div>
          {(task.LockFlag)
            ?
            <h3 className="text-lg leading-6 font-bold text-gray-700">{task.OperationTitle} - Locked</h3>
            :
            <h3 className="text-lg leading-6 font-bold text-gray-700">{task.OperationTitle}</h3>
          }
        </div>
        <div className="text-gray-900 dark:text-white w-[120px] flex justify-center px-2 flex-col">
          <Badge color="" className='py-1 w-full text-center flex flex-col items-center justify-center' style={{ backgroundColor: generateBadgeColor(task.Progress) }}>
            <p className="mb-1 text-[10px]">Progress: {task.Progress}%</p>
            <Progress
              size={"sm"}
              progress={task.Progress}
              color={generateProggressColor(task.Progress)}
            />
          </Badge>
          <Badge color="info" className='mt-1 text-center px-2 flex justify-center'>
            {task.Title}
          </Badge>
        </div>
      </div>
      <p className="p-6 text-sm text-gray-500 font-semibold mt-0 col-span-2">
        {task.OperationDescription}
      </p>
      <div className="px-2 flex justify-between items-center">
        <span className="text-sm">
          {task.CDT}
        </span>
        <div className="flex flex-row items-center my-2 mx-3">
          <Avatar.Group>
            {task?.Interactors?.map((item) => <HoverableAvatar item={item} key={item.Id} />)}
          </Avatar.Group>
          {(task.Interactors !== undefined && task.Interactors !== null)
            ?
            <span className="text-black/30 mb-1 mx-2">|</span>
            :
            null
          }
          {(task.ExpiredFlag)
            ?
            <div className='flex flex-col items-center font-bold'>
              <p className="text-[14px] text-red-500">Expired</p>
            </div>
            : (task.Deadline !== undefined && task.Deadline !== null)
              ?
              < div className='flex flex-col items-center font-bold'>
                <p className="text-[12px] text-simwave-brand">Deadline:</p>
                <p className="text-[12px]">{moment(task.Deadline).format('lll')}</p>
              </div>
              :
              null
          }
        </div>
      </div>
    </div >
  );
};
