import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineQuestionAnswer } from 'react-icons/md'
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Card } from 'flowbite-react';
import Swal from 'sweetalert2';

import { PersonType } from 'common/types';
import  PersonCard  from 'components/PersonCard/PersonCard';
import { Questioner } from 'components/questioner/Questioner';
import Button from 'components/button/Button';
import { HideLoader, ShowLoader } from 'utilities/utils';
import { VmQuestionModel } from "pages/task-viewer/appraisal/create/CreateAppraisal"
import ManagersSwiper from 'pages/task-viewer/appraisal/ManagersSwiper';
import { VmProccessViewerSteps } from 'common/models/TaskStepModel';
import { setSteps } from 'components/task-steps/state/TaskStepsSlice';
import { SwitchComponent } from 'components/task-steps/SwitchComponent';
import { commands } from 'commands';
import { transceiver } from 'global';

import 'react-confirm-alert/src/react-confirm-alert.css';

export interface AppraiseeModel {
  value: {
    Name: string,
    Id: string,
    ProfilePhoto?: string
  }[]
}

export interface appraisalRootData {
  Deadline: string
  Description: string
  Title: string
}

export const ConfirmAppraisal = (props: any) => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate();

  // eslint-disable-next-line
  let { operationId, stepName } = useParams();
  useEffect(() => {
    ShowLoader();
    loadAppraisalInfo();
  }, []);// eslint-disable-line

  const [questionnaires, setQuestionnaires] = useState<VmQuestionModel>();

  const [appraisee, setAppraisee] = useState<AppraiseeModel>();
  const [managers, setManagers] = useState<PersonType[]>()

  const [leadEv, setLeadEv] = useState<PersonType>()
  const [rootData, setRootData] = useState<appraisalRootData>()

  const loadAppraisalInfo = () => {
    let obj = {
      InstanceId: operationId,
      ParameterName: "AppraisalInfo",
    };
    transceiver.requestCommand(commands.LoadInstanceParameter, obj, (result: any, state: any) => { AppraisalInfoResult(result) })
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'bg-orange-500 text-white hover:bg-orange-700 focus:ring-orange-300 active:bg-orange-800 disabled:bg-orange-300 shadow-sm rounded-md p-2 ml-3',
      cancelButton: 'bg-white !text-indigo-900 rounded-md !border-indigo-900 p-2 border hover:bg-gray-100 mr-3'
    },
    buttonsStyling: false
  })

  const submit = () => {
    swalWithBootstrapButtons.fire({
      title: 'Confirm Submit',
      text: "Are you sure to submit this appraisal?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, submit',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        TransitNext();
      }
    })
  }


  const AppraisalInfoResult = (msg: any) => {
    const { Appraisee, leadEvaluator, Managers, Deadline, Description, Title } = msg
    if (msg) {
      let appraiseeList: AppraiseeModel = { value: [] };
      appraiseeList.value.push({
        Name: Appraisee.label,
        Id: Appraisee.value,
        ProfilePhoto: Appraisee.ProfilePhoto
      });
      setAppraisee(appraiseeList);
      setLeadEv(leadEvaluator)
      setManagers(Managers)
      setRootData({ Deadline, Description, Title })
    }
    loadQuestionnaires();
  }

  const loadQuestionnaires = () => {
    let obj = {
      InstanceId: operationId,
      ParameterName: "questionnaire",
    };
    transceiver.requestCommand(commands.LoadInstanceParameter, obj, (result: any, state: any) => { QuestionnaireResult(result) })
  }

  const QuestionnaireResult = (msg: any) => {
    let ListQuestionnaire: VmQuestionModel = { Questionnaires: [] }
    Array.isArray(msg.Questionnaires) && msg.Questionnaires.forEach((item: any) => {
      ListQuestionnaire.Questionnaires.push({
        Id: item.Id,
        Description: item.Description,
        Caption: item.Caption
      });
    })
    setQuestionnaires(ListQuestionnaire);
    HideLoader();
  }

  const TransitNext = () => {
    ShowLoader();
    let obj = {
      ContainerId: props.InstanceId,
      StepName: props.StepFullName,
      Name: "Next"
    };
    transceiver.requestCommand(commands.Transit, obj, (result: any, state: any) => { transit() }, null);
  }

  const TransitBack = () => {
    ShowLoader();
    let obj = {
      ContainerId: props.InstanceId,
      StepName: props.StepFullName,
      Name: "Back"
    };
    transceiver.requestCommand(commands.Transit, obj, (result: any, state: any) => { transitBackResult(result) }, null);
  }

  const transit = () => {
    HideLoader();
    navigate("/task-list");
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Your appraisal has been submitted',
      showConfirmButton: false,
      timer: 1500
    })
  }

  const transitBackResult = (msg: any) => {
    let ListStep: VmProccessViewerSteps = { steps: [] };
    msg.Map.Steps.forEach((item: any) => {
      ListStep.steps.push({
        Name: item.Name,
        Index: item.Index,
        Title: item.Title,
        CurrentFlag: item.CurrentFlag
      });
    })
    dispatch(setSteps(ListStep));
    props.setBody(<SwitchComponent setBody={props.setBody}  {...msg.Data} type={msg.Data.StepName} />);
    HideLoader();
  }


  return (
    <Card className="bg-white/60 mt-4 min-h-[calc(100vh-180px)] block !justify-start">
      <div className='flex flex-col justify-between gap-y-4 relative min-h-[calc(100vh-180px)]'>
        <div className='grow flex flex-col'>
          <div className='row-span-1 col-span-full rounded-md py-1 mb-4'>
            <div className='flex items-center justify-between mb-1'>
              <p className='appraisal-title'>{rootData?.Title}</p>
              <p className='time-style text-sm'>{moment(rootData?.Deadline).format('YYYY/MM/DD')}</p>
            </div>
            <hr className='' />
          </div>

          <div className='grid grid-cols-2 grow grid-rows-10 gap-4'>
            <div className='row-span-6 col-span-2 md:col-span-1 text-center gap-x-4 h-full flex flex-col justify-between gap-y-4'>
              <div className='grid gap-4 xl:grid-cols-3 grid-cols-1 h-full'>
                <PersonCard
                  title='Appraisee'
                  key={appraisee?.value[0].Id}
                  name={appraisee?.value[0].Name}
                  email={appraisee?.value[0].Name + "@simwave.nl"}
                  avatarImg={appraisee?.value[0].ProfilePhoto}
                  className=''
                />
                
                <ManagersSwiper managers={managers} />

                <PersonCard
                  title='Lead Evaluator'
                  key={leadEv?.value}
                  name={leadEv?.label}
                  email={leadEv?.label + "@simwave.nl"}
                  avatarImg={leadEv?.ProfilePhoto}
                />
              </div>
            </div>
            <div className='col-span-2 md:col-span-1 row-span-6 h-full rounded-md border border-simwave-brand py-2 px-4'>
              <div className='flex flex-wrap overflow-y-scroll xl:max-h-[400px]'>
                {questionnaires?.Questionnaires.map((item: any) => (
                  <Questioner
                    icon={MdOutlineQuestionAnswer}
                    selectable={false}
                    summary={item.Description}
                    title={item.Caption}
                    className='w-full p-2'
                  />
                ))}
              </div>
            </div>
            <p className='col-span-2 row-span-4 rounded-md border border-simwave-brand py-2 px-4 overflow-y-scroll'>{rootData?.Description ?? 'This appraisal has no description'}</p>
          </div>
        </div>
        <div className='flex justify-between'>
          <Button
            value="Cancel"
            variant='white'
            onClick={TransitBack}
            className='rounded-lg'
          >
            Back
          </Button>

          <Button
            value="Create"
            variant='primary'
            onClick={submit}
            className='rounded-lg'
          >
            Confirm
          </Button>
        </div>
      </div>
    </Card>
  )
}