import { Avatar } from 'flowbite-react'

import { getImageUrl } from 'global'

const OptTooltipNode = (props: any) => {
    return (
        <div className='flex items-center justify-around gap-x-4 z-[9999] w-60 h-12'>
            {
                props.ProfilePhoto ?
                    <Avatar img={getImageUrl(`/stream/Image/${props.ProfilePhoto}`)} rounded />
                    :
                    <Avatar rounded />
            }
            <p>
                {props.label}
            </p>
        </div>
    )
}

export default OptTooltipNode