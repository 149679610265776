import React from 'react'

interface props {
    text: string
    subText?: string
    icon?: React.ReactNode
    className?: string
}

const EmptyList = ({ text, icon, subText, className }: props) => {
    return (
        <div className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full text-center text-sm ${className}`}>
            <div className='flex justify-center items-center gap-x-4'>
                {icon}
                <div>
                    <p className='font-semibold'>
                        {text}
                    </p>
                    {subText && <span className='text-slate-500 text-xs'>{subText}</span>}
                </div>
            </div>
        </div>
    )
}

export { EmptyList }