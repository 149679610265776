import {breadcrumb} from './breadcrumb';
import { SET_BREADCRUMB } from 'utilities/SET_BREADCRUMB';

const Dashboard = () => {
  SET_BREADCRUMB(breadcrumb);

  return (
    <>
      <div className="grid grid-cols-1">
        <div className="mt-4 md:mt-0">
          {/* <ToDoList /> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
