import { useState, ReactNode, useEffect } from 'react';
import { FiChevronUp, FiLock, FiUnlock } from 'react-icons/fi';
import Ripples from 'react-ripples'

interface AccordionProps {
  title: string | React.ReactNode | JSX.Element
  enabled?: boolean;
  enabledIcon?: boolean;
  expanded?: boolean;
  children?: ReactNode;
  className?: string;
  extraInfo?: string
  iconClassName?: string
  rippleEffect?: boolean
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  enabled = true,
  enabledIcon = false,
  expanded,
  className = '',
  children,
  extraInfo,
  iconClassName,
  rippleEffect
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (enabled) {
      setIsExpanded(expanded ?? false)
    }
  }, [enabled, expanded])


  const toggleAccordion = () => {
    if (enabled) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div className={`rounded-lg bg-white ${!enabled && 'bg-gray-100'}`}>
      <div
        className={`px-4 py-2 h-16 flex items-center hover:bg-gray-100
          ${enabled ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-100'}
          ${isExpanded && 'bg-gray-100 border-b-[0px]'}
          ${className}
          `}
        onClick={toggleAccordion}
      >
        <Ripples className='rounded-lg' color={rippleEffect ? '#aeb6bf51' : ''} onClick={toggleAccordion}>
          <div className="flex items-center gap-1">
            <FiChevronUp
              className={`transition-transform transform ${iconClassName} ${isExpanded ? 'rotate-180' : 'rotate-0'
                }`}
            />
            {enabledIcon && (enabled ? <FiUnlock /> : <FiLock />)}
          </div>
          <div className='flex items-center justify-between w-full'>
            <h3 className={`course-content-title ${isExpanded ? "" : "line-clamp-1"}`}>{title}</h3>
            <p className='acc-extra-info'>{extraInfo}</p>
          </div>
        </Ripples>
      </div>
      {isExpanded && <div className={`border-b p-6 ${isExpanded ? 'border-x rounded-b-lg' : ''} -mt-1`}>{children}</div>}
    </div>
  );
};

export default Accordion;
