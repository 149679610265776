import { TemplateBoxModel } from 'common/models/TemplateBoxModel';

export const Template = (props: TemplateBoxModel) => {
  return (
    <div className={`p-4 md:w-full ${props.className}`} >
      <div onClick={() => props?.setSelectedHandler?.()} className={`flex border-2 rounded-lg cursor-pointer hover:bg-gray-100 transition-all duration-200 border-gray-200 border-opacity-50  ${props.size === 'small' ? 'py-2 px-4 flex-row' : 'p-4 sm:flex-row flex-col'}  ${props.selected ? '!bg-simwave-brand !text-white/80' : ''}`}>
        <div className={`w-12 h-12 ${props.size === 'small' ? 'w-8 h-8 sm:mb-0 mb-0 mr-2' : 'sm:mr-4 sm:mb-0 mb-4'} inline-flex items-center justify-center rounded-full bg-indigo-100  flex-shrink-0 ${props.selected ? "!text-simwave-bg" : "text-indigo-500"}`}>
          {props.icon}
        </div>
        <div className="flex-grow flex flex-col justify-center">
          <h2 className={`text-lg ${props.size === 'small' ? 'text-sm' : ''} title-font font-medium ${props.selected ? "text-white" : "text-gray-900"}`}>{props.title}</h2>
          {
            (props.summary === "" ? <p className="leading-relaxed pt-3 text-base line-clamp-6">{props.summary}</p> : null)
          }
        </div>
      </div>
    </div>
  );
};
