import React, { useMemo } from 'react';
import { Avatar, Badge, Card, Progress } from 'flowbite-react';
import moment from 'moment';
import {
  AiFillPieChart,
  AiOutlineFieldTime,
  AiOutlineFolderOpen,
  AiOutlineFundProjectionScreen,
} from 'react-icons/ai';

import { getImageUrl } from 'global';
import { generateProggressColor } from 'utilities';
import { HiCheck } from 'react-icons/hi';
import { MdCancelScheduleSend } from 'react-icons/md';
import { EvalutEvaluationsType } from 'common/types/report/loadReportApi';
import Divider from 'components/divider/Divider';

interface props {
  appraisal: any;
  status: any;
}

const AccomplishmentStetes = [
  { label: 'Unknown', value: 0 },
  { label: 'Prepared', value: 1 },
  { label: 'Evaluated', value: 64 },
  { label: 'Submitted', value: 128 },
  { label: 'Cancled', value: 256 },
];

const OverviewTab: React.FC<props> = ({ appraisal, status }) => {
  const appraisee = useMemo(
    () => appraisal?.Evaluations?.shift(),
    [appraisal?.Evaluations],
  );
  const leadEvaluator = useMemo(
    () => appraisal?.Evaluations?.pop(),
    [appraisal?.Evaluations],
  );

  const getLeadAssessorProgress = (): number => {
    let res: number | null | undefined = 0;
    let evaluators = status?.Evaluations as EvalutEvaluationsType[];
    let leadEvaluatorPerson: EvalutEvaluationsType | undefined =
      evaluators?.find((x) => x.Evaluator?.Id === leadEvaluator?.Evaluator?.Id);
    if (leadEvaluatorPerson !== undefined) res = leadEvaluatorPerson.Progress;
    return res === null || res === undefined ? 0 : res;
  };

  const getLeadAssessorSubmittedFlag = (): boolean => {
    let res: boolean | null | undefined = true;
    let evaluators = status?.Evaluations as EvalutEvaluationsType[];
    let leadEvaluatorPerson: EvalutEvaluationsType | undefined =
      evaluators?.find((x) => x.Evaluator?.Id === leadEvaluator?.Evaluator?.Id);
    if (leadEvaluatorPerson !== undefined)
      res = leadEvaluatorPerson.SubmittedFlag;
    return res === null || res === undefined ? false : res;
  };

  const getManagerProgress = (id: string): number => {
    let res: number | null | undefined = 0;
    let evaluators = status?.Evaluations as EvalutEvaluationsType[];
    let leadEvaluatorPerson: EvalutEvaluationsType | undefined =
      evaluators?.find((x) => x.Evaluator?.Id === id);
    if (leadEvaluatorPerson !== undefined) res = leadEvaluatorPerson.Progress;
    return res === null || res === undefined ? 0 : res;
  };

  const getManagerSubmittedFlag = (id: string): boolean => {
    let res: boolean | null | undefined = true;
    let evaluators = status?.Evaluations as EvalutEvaluationsType[];
    let leadEvaluatorPerson: EvalutEvaluationsType | undefined =
      evaluators?.find((x) => x.Evaluator?.Id === id);
    if (leadEvaluatorPerson !== undefined)
      res = leadEvaluatorPerson.SubmittedFlag;
    return res === null || res === undefined ? false : res;
  };

  return (
    <div className="grid grid-cols-3 gap-3">
      <div className="lg:col-span-2 col-span-3 grid cols-1 gap-3">
        <Card>
          <div className="flex items-center justify-between">
            <h6 className="text-md font-bold leading-none text-gray-900 dark:text-white">
              Appraisee
            </h6>
          </div>
          <hr />
          <div className="flow-root">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              <li className="py-1 sm:py-2">
                <div className="flex items-center space-x-4">
                  <div className="shrink-0">
                    <Avatar
                      img={getImageUrl(
                        `/stream/Image/${appraisee?.Evaluator?.ProfilePhoto}`,
                      )}
                      rounded
                      bordered
                      size={'lg'}
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-[14px] font-medium text-gray-900 dark:text-white">
                      {appraisee?.Evaluator?.Caption}
                    </p>
                    <p className="truncate  text-[10px] text-gray-500 dark:text-gray-400">
                      {appraisee?.Evaluator?.Positions}
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    <div>
                      <div className="py-2 text-center">
                        <Badge
                          color=""
                          className="py-1 w-full text-center flex flex-col items-center justify-center"
                        >
                          <p className="text-[10px]">
                            Progress: {appraisee?.Progress}%
                          </p>
                          <Progress
                            className="w-[180px]"
                            size={'md'}
                            progress={appraisee?.Progress}
                            color={generateProggressColor(appraisee?.Progress)}
                          />
                        </Badge>
                        <div className="inline-block">
                          {appraisee?.SubmittedFlag === true ? (
                            <Badge color="success" icon={HiCheck}>
                              <span className="p-1 text-[12px]">Submitted</span>
                            </Badge>
                          ) : (
                            <Badge color="failure" icon={MdCancelScheduleSend}>
                              <span className="p-1 text-[12px]">
                                Unsubmitted
                              </span>
                            </Badge>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Card>
        {(appraisal?.Evaluations?.length > 0) ?
          <Card>
            <div className="flex items-center justify-between">
              <h6 className="text-md font-bold leading-none text-gray-900 dark:text-white">
                Managers
              </h6>
            </div>
            <hr />
            <div className="flow-root">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {appraisal?.Evaluations?.map((item: any) => (
                  <li className="py-1 sm:py-2 border-solid border-b border-black/10 last:border-b-0">
                    <div className="flex items-center space-x-4">
                      <div className="shrink-0">
                        <Avatar
                          img={getImageUrl(
                            `/stream/Image/${item?.Evaluator?.ProfilePhoto}`,
                          )}
                          rounded
                          bordered
                          size={'lg'}
                        />
                      </div>
                      <div className="min-w-0 flex-1">
                        <p className="truncate text-[14px] font-medium text-gray-900 dark:text-white">
                          {item?.Evaluator?.Caption}
                        </p>
                        <p className="truncate  text-[10px] text-gray-500 dark:text-gray-400">
                          {item?.Evaluator?.Positions}
                        </p>
                      </div>
                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                        <div>
                          <div className="py-2 text-center">
                            <Badge
                              color=""
                              className="py-1 w-full text-center flex flex-col items-center justify-center"
                            >
                              <p className="text-[10px]">
                                Progress:{' '}
                                {getManagerProgress(item?.Evaluator?.Id)}%
                              </p>
                              <Progress
                                className="w-[180px]"
                                size={'md'}
                                progress={appraisee?.Progress}
                                color={generateProggressColor(
                                  getManagerProgress(item?.Evaluator?.Id),
                                )}
                              />
                            </Badge>
                            <div className="inline-block">
                              {getManagerSubmittedFlag(item?.Evaluator?.Id) ===
                                true ? (
                                <Badge color="success" icon={HiCheck}>
                                  <span className="p-1 text-[12px]">
                                    Submitted
                                  </span>
                                </Badge>
                              ) : (
                                <Badge
                                  color="failure"
                                  icon={MdCancelScheduleSend}
                                >
                                  <span className="p-1 text-[12px]">
                                    Unsubmitted
                                  </span>
                                </Badge>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Card> : null
        }
        <Card>
          <div className="flex items-center justify-between">
            <h6 className="text-md font-bold leading-none text-gray-900 dark:text-white">
              Lead Evaluator
            </h6>
          </div>
          <hr />
          <div className="flow-root">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              <li className="py-1 sm:py-2">
                <div className="flex items-center space-x-4">
                  <div className="shrink-0">
                    <Avatar
                      img={getImageUrl(
                        `/stream/Image/${leadEvaluator?.Evaluator?.ProfilePhoto}`,
                      )}
                      rounded
                      bordered
                      size={'lg'}
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-[14px] font-medium text-gray-900 dark:text-white">
                      {leadEvaluator?.Evaluator?.Caption}
                    </p>
                    <p className="truncate  text-[10px] text-gray-500 dark:text-gray-400">
                      {leadEvaluator?.Evaluator?.Positions}
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    <div>
                      <div className="py-2 text-center">
                        <Badge
                          color=""
                          className="py-1 w-full text-center flex flex-col items-center justify-center"
                        >
                          <p className="text-[10px]">
                            Progress: {getLeadAssessorProgress()}%
                          </p>
                          <Progress
                            className="w-[180px]"
                            size={'md'}
                            progress={appraisee?.Progress}
                            color={generateProggressColor(
                              getLeadAssessorProgress(),
                            )}
                          />
                        </Badge>
                        <div className="inline-block">
                          {getLeadAssessorSubmittedFlag() === true ? (
                            <Badge color="success" icon={HiCheck}>
                              <span className="p-1 text-[12px]">Submitted</span>
                            </Badge>
                          ) : (
                            <Badge color="failure" icon={MdCancelScheduleSend}>
                              <span className="p-1 text-[12px]">
                                Unsubmitted
                              </span>
                            </Badge>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Card>
      </div>
      <div>
        <div className='lg:col-span-1 col-span-3'>
          <Card>
            <div className="flex flex-col justify-center">
              <div className="flex flex-row justify-start items-center mb-3">
                <AiOutlineFieldTime className="mr-1 w-5 h-5 fill-simwave-brand" />
                <p className="mr-1">Deadline:</p>
                {appraisal?.ExpiredFlag ?
                  <Badge color="failure" className="justify-center items-center">
                    <p className='text-[12pt]'>Expired</p>
                  </Badge>
                  : (appraisal?.Deadline) ?
                    <Badge color="success" className="justify-center items-center">
                      <p className='text-[12pt]'>{moment(appraisal?.Deadline).format('lll')}</p>
                    </Badge>
                    :
                    <Badge color="info" className="justify-center items-center">
                      <p className='text-[12pt]'>Not Available</p>
                    </Badge>
                }
              </div>
              <div className="flex flex-row justify-start items-center mb-3">
                <AiOutlineFolderOpen className="mr-1 w-5 h-5 fill-simwave-brand" />
                <p className="mr-1">State:</p>
                {appraisal?.OpenFlag ? (
                  <Badge color="success" className="justify-center items-center">
                    <p className='text-[12pt]'>Opened</p>
                  </Badge>
                ) : (
                  <Badge color="failure" className="justify-center items-center">
                    <p className='text-[12pt]'>Closed</p>
                  </Badge>
                )}
              </div>
              <div className="flex flex-row justify-start items-center mb-3">
                <AiOutlineFundProjectionScreen className="mr-1 w-5 h-5 fill-simwave-brand" />
                <p className="mr-1">Accomplishment State:</p>
                <div className="flex items-start gap-x-2">
                  {AccomplishmentStetes.map((item) => (
                    (appraisal?.AccomplishmentStateCode === item.value) ?
                      <Badge color="info" className="justify-center items-center">
                        <p className='text-[12pt]'>{item.label}</p>
                      </Badge>
                      :
                      null
                  ))}
                </div>
              </div>
              <div className="flex flex-grow flex-row justify-start items-center">
                <AiFillPieChart className="mr-1 w-5 h-5 fill-simwave-brand" />
                <p className="mr-1">Progress:</p>
                <Badge color={generateProggressColor(appraisal?.Progress)} className="justify-center items-center">
                  <p className='text-[12pt]'>{appraisal?.Progress}%</p>
                </Badge>
              </div>
            </div>
          </Card>
        </div>
        <div>
          {
            appraisal?.Conversations?.length > 0 ? <Card>
              <div className="lg:col-span-2 col-span-full">
                <h6 className="text-md font-bold leading-none text-gray-900 dark:text-white">
                  Appraisee Feedback
                </h6>
                <Divider className="mt-3" thickness={2} />
                {
                  appraisal?.Conversations.map((item: any) => {
                    return (
                      <div className="mb-2 mt-7 text-base">
                        <div className="flex justify-between items-center mb-2">
                          <div className="flex justify-between w-full">
                            <div className="inline-flex gap-2 items-center text-sm text-gray-900">
                              <div className="flex flex-col">
                                <p className='font-medium'>
                                  {item.WriterCode}
                                </p>
                              </div>
                            </div>
                            <p className="time-style">
                              {moment(item.CultureWrittenOn).fromNow()}
                            </p>
                          </div>
                        </div>
                        <p className="text-gray-500 text-sm text-justify">{item.Text}</p>

                      </div >
                    )
                  })
                }

              </div>
            </Card> : null
          }

        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
