import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IconType } from 'react-icons/lib';

export interface BreadcrumbState {
  value: {
    url: string;
    text: string;
    icon?: IconType;
    isCurrent?:boolean;
    }[ ]
}

const initialState: BreadcrumbState = {
  value: [],
}

export const BreadcrumbSlice = createSlice({
  name: 'breadcrumb-path',
  initialState,
  reducers: {
    setPath: (state, action: PayloadAction<BreadcrumbState>) => {
      state.value = action.payload.value;
    },
    clearPath:(state) =>{
        state.value = [];
    }
  },
})

export const { setPath,clearPath } = BreadcrumbSlice.actions

export default BreadcrumbSlice.reducer