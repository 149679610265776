export const generateProggressColor = (proggress: number) => {
    if (proggress === 100) return 'green'
    if (50 <= proggress && proggress < 100) return 'yellow'
    if (proggress < 50) return 'red'
    if (proggress === 0) return ''
}

export const generateBadgeColor = (proggress: number) => {
    if (proggress === 100) return '#00ff006c'
    if (50 <= proggress && proggress < 100) return '#99FF006c'
    if (0 < proggress && proggress < 50) return '#ff66006c'
    if (proggress === 0) return 'transparent'
}