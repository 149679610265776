import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from 'sweetalert2';

export default function MessageProcessor() {
  let randval = Math.floor(Math.random() * 100000);
  let transcieverId = 'websocket' + randval + '@client';
  let messageProcessorId = 'MP@Master';
  // eslint-disable-next-line
  let socket;
  const requestMap = new Map();
  this.socketAddress = 0;
  let messageSerial = 0;
  let isConnecetd = false;

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'bg-orange-500 text-white hover:bg-orange-700 focus:ring-orange-300 active:bg-orange-800 disabled:bg-orange-300 shadow-sm rounded-md p-2 ml-3',
    },
    buttonsStyling: false
  })
  this.onConnectedHandler = (msg) => { };

  const transmit = (msg) => {
    this.socket.send(JSON.stringify(msg));
  };
  const getMessageSerial = () => {
    return messageSerial++;
  };
  const onConnected = (msg) => {
    isConnecetd = true;
    this.onConnectedHandler(msg);
  };

  this.readyFlag = false;
  this.Ready = () => {
    this.readyFlag = true;
    this.OnReadyHandler();
  };
  this.OnReadyHandler = () => { };

  this.RequireAuthentication = () => { this.OnRequireAuthenticationHandler() };
  this.OnRequireAuthenticationHandler = () => { };

  this.initialize = (socketAddress) => {
    this.socketAddress = socketAddress;
    this.socket = new WebSocket(socketAddress);
    this.socket.onopen = (e) => {
      var msg = {
        Sender: transcieverId,
        Receiver: 'default@SUBSCRIBE',
        Protocol: '*',
        Ontology: '*',
        Performative: 'REQUEST',
        Language: 'ACL',
        ConversationId: 'subscribe',
      };
      transmit(msg);
    };
    this.onSocketMessage = (response) => {
      var msg = JSON.parse(response.data).Message;
      if (msg.ConversationId?.toLowerCase() === 'subscribe')
        onConnected(msg);

      if (isSuccessful(msg)) {
        if (msg.ConversationId === 'SYS_MSG_PROCESS') {
          processCallback(msg, msg.InReplyTo);
        }
        else if (msg.ConversationId === 'SYS_CMD_PROCESS') {
          if (msg.Performative === 'inform')
            processInform(msg.Content.Output, msg.InReplyTo);
          else
            processCallback(msg.Content.Output, msg.InReplyTo);
        }
      }
      else {
        if (msg.ConversationId !== 'SYS_MSG_PROCESS') {
          swalWithBootstrapButtons.fire({
            title: 'Error',
            text: msg.Content.Description,
            icon: 'error',
            confirmButtonText: 'i understand !',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {

            }
          })
        }

        processFailure(msg, msg.InReplyTo);
        console.log(JSON.stringify(msg));
      }
    };
    const processInform = (data, pairId) => {
      var messagePair = requestMap.get(pairId);
      if (messagePair) {
        if (typeof messagePair.inform != 'undefined')
          messagePair.inform(data, messagePair.state);
      } else {
        // alert('Please refactor this section for proper error handling- unexpected communication',);
      }
    };

    const processFailure = (data, pairId) => {
      var messagePair = requestMap.get(pairId);
      if (messagePair) {
        if (messagePair.failure)
          messagePair?.failure(data, data.State);
        requestMap.delete(pairId);
      } else {
        // alert('Please refactor this section for proper error handling- unexpected communication',); /* **** */
        console.log(data);
      }
    }
    const processCallback = (data, pairId) => {
      var messagePair = requestMap.get(pairId);
      if (messagePair) {
        messagePair.handler(data, messagePair.state);
        requestMap.delete(pairId);
      } else {
        // alert('Please refactor this section for proper error handling- unexpected communication',); /* **** */
        console.log(data);
      }
    };
    this.onSocketError = (data) => {
      console.log(data);
      this.initialize(this.socketAddress);
    };
    this.onSocketClose = (data) => {
      console.log(data);
      this.initialize(this.socketAddress);
    };
    this.socket.onmessage = (e) => {
      this.onSocketMessage(e);
    };
    this.socket.onerror = (e) => {
      this.onSocketError(e);
    };
    this.socket.onclose = (e) => {
      this.onSocketClose(e);
    };
  };
  const isSuccessful = (msg) => {
    if (msg.Performative) {
      var phrase = msg.Performative.toLowerCase();
      if (phrase === 'disconfirm') return false;
      if (phrase === 'failure') return false;
      if (phrase === 'refuse') return false;
      if (phrase === 'unknown') return false;
      return true;
    }
    return false;
  };
  this.requestMessage = (protocol, content, receiver, responseHandler, stateObject, failureHandler,) => {
    let serial = getMessageSerial();
    let message = {
      Performative: 'REQUEST',
      Language: 'ACL',
      Ontology: '*',
      Sender: transcieverId,
      Receiver: receiver,
      Protocol: protocol,
      Content: content,
      ConversationId: 'SYS_MSG_PROCESS',
      ReplyWith: serial,
    };
    requestMap.set(serial.toString(), {
      handler: responseHandler, state: stateObject, failure: failureHandler
    });
    transmit(message);
  };
  this.requestCommand = (commandName, content, responseHandler, stateObject, progressHandler, failureHandler) => {
    if (!isConnecetd) {
      //alert("is not connected yet");
      return;
    }
    let serial = getMessageSerial();
    let message = {
      Performative: 'REQUEST',
      Language: 'ACL',
      Ontology: '*',
      Sender: transcieverId,
      Receiver: messageProcessorId,
      Protocol: commandName,
      Content: content,
      ConversationId: 'SYS_CMD_PROCESS',
      ReplyWith: serial,
    };
    requestMap.set(serial.toString(), { handler: responseHandler, state: stateObject, inform: progressHandler, failure: failureHandler });
    transmit(message);
  };
}
