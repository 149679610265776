interface props {
    activeStep: number
    items: any[]
    onChange: () => void
}

const Stepper = ({ activeStep, items }: Partial<props>) => {
    return (
        <div className='flex items-center gap-x-1'>
            {items?.map((item, i) => <span className={`w-1 h-1 rounded-full ${activeStep === i ? 'bg-simwave-orange' : 'bg-white'}`} />)}
        </div>
    )
}

export default Stepper