import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from 'app/App';
import reportWebVitals from 'reportWebVitals';
import { store } from 'app/store'
import { transceiver } from 'global';
import ICAContext from 'core/ICAContext';

import './styles/index.css';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}
var root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

ICAContext.globalcontext.initialize();
transceiver.onConnectedHandler = () => {
  var token = ICAContext.globalcontext.getCookie("Token");
  if (token) {
    let ctn = {
      Token: token
    }
    transceiver.requestMessage("*", ctn, "SignIn@Master", (result: any) => { handleAuthResult(result) }, (state: any) => { }, (result: any) => { handleFailure(result) })
  }
  else
    transceiver.RequireAuthentication();
}

const handleAuthResult = (msg: any) => {
  transceiver.Ready();
}

const handleFailure = (msg: any) => {
  transceiver.RequireAuthentication();
}

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </Provider >
);

reportWebVitals();
