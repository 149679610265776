import { Tooltip } from 'flowbite-react';
import React from 'react';
import { MultiValueProps } from 'react-select';
import OptTooltipNode from 'components/select/OptTooltipNode';

const CustomMultiValue: React.FC<MultiValueProps<any>> = ({ data, innerProps, removeProps }) => {

    const handleRemoveItem = (e: any) => {
        if (e) {
            removeProps.onClick?.(e)
        }
    }
    return (
        <Tooltip content={<OptTooltipNode  {...data} />}>
            <div className="custom-multi-value z-10" {...innerProps}>
                <button onClick={handleRemoveItem} type='button' className='bg-slate-100 ml-1 px-3 py-1 text-xs font-semibold'>
                    {data.label.substring(0, 10)}...
                </button>
            </div>
        </Tooltip>
    );
};

export default CustomMultiValue;